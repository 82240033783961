import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import availableOptions from "../../constants/availableOptions";
import bloodCenterSiteQuery from "../../utils/queries/bloodCenterSiteQuery";
import validateEmail from "../../utils/validateEmail";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class Committee extends React.Component {
  state = {
    name: "",
    error: {},
    isSubmitting: false,
    centers: [],
    sites: [],
    code: "",
    contactNumber: "",
    email: "",
    isActive: availableOptions[0],
  };

  componentDidMount = async () => {
    await this.getCenters(true);

    this.setState({ ...this.props });

    if (this.props._id && this.props._id !== "") {
      this.setState({
        isActive: {
          label: this.props?.available + "",
          value: this.props?.available + "",
        },
        site: {
          label: this.props?.centerSite?.name,
          value: this.props?.centerSite?._id,
        },
        center: {
          label: this.props?.center?.name,
          value: this.props?.center?._id,
        },
      });
    }
  };

  getCenters = async (isFetchingCenter) => {
    try {
      this.setState({
        isFetchingCenter,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setSelectedCenter(data);

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });
    } catch (error) {
      this.setState({ isFetchingCenter: false });
    }
  };

  setSelectedCenter(centers) {
    const { center } = this.props;
    if (center) {
      const selectedCenter = centers.find((el) => el._id === center._id);

      if (selectedCenter._id) {
        this.setState(
          {
            center: {
              label: selectedCenter.name,
              value: selectedCenter._id,
            },
          },
          () => {
            this.getSites(true, selectedCenter._id);
          }
        );
      }
    }
  }

  getSites = async (isFetchingSite, center) => {
    try {
      this.setState({
        isFetchingSite,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        center,
      });

      this.setSelectedSite(data);

      this.setState({
        sites: data,
        isFetchingSite: false,
      });
    } catch (error) {
      this.setState({ isFetchingSite: false });
    }
  };

  setSelectedSite(sites) {
    const { centerSite } = this.props;
    if (centerSite) {
      const selectedSite = sites.find((el) => el._id === centerSite._id);

      if (selectedSite._id) {
        this.setState({
          site: {
            label: selectedSite.name,
            value: selectedSite._id,
          },
        });
      }
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });

    if (field === "center") {
      this.getSites(true, e.value);
    }
  }

  validateForm() {
    let { name, error, phoneNumber, email, center, site } = this.state;

    if (name === "") {
      error.name = language[this.props.defaultLanguage].committee_name_required;
    }

    if (phoneNumber === "") {
      error.phoneNumber =
        language[this.props.defaultLanguage].phone_number_required;
    }

    if (email === "") {
      error.email = language[this.props.defaultLanguage].email_required;
    } else if (!validateEmail(email)) {
      error.email = language[this.props.defaultLanguage].invalid_email;
    }

    if (!center) {
      error.center = language[this.props.defaultLanguage].center_required;
    }

    if (!site) {
      error.site = language[this.props.defaultLanguage].site_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      error_add_committee,
      error_update_committee,
      add_committee_success,
      update_committee_success,
    } = language[this.props.defaultLanguage];

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { name, _id, center, site, isActive, email, phoneNumber } =
          this.state,
        url = `${API_URL}/blooddonationcommittee`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        name,
        phoneNumber,
        email,
        center: center.value,
        centerSite: site.value,
        isActive: isActive.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT" ? update_committee_success : add_committee_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage(
            "error",
            method === "PUT" ? error_update_committee : error_add_committee
          );

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                leftIcon={icons.name}
                placeholder={
                  language[this.props.defaultLanguage]
                    .committee_name_placeholder
                }
                label={language[this.props.defaultLanguage].committee_name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].phone_number_placeholder
                }
                label={language[this.props.defaultLanguage].phone_number}
                required
                value={this.state.phoneNumber}
                onChange={(e) => this.onChangeText("phoneNumber", e)}
                error={this.state.error.phoneNumber}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].email_placeholder
                }
                label={language[this.props.defaultLanguage].email_address}
                required
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.centers}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center}
                required
                value={this.state.center}
                onChange={(e) => this.onChangeText("center", e)}
                error={this.state.error.center}
                isLoading={this.state.isFetchingCenter}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.sites}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].site}
                required
                value={this.state.site}
                onChange={(e) => this.onChangeText("site", e)}
                error={this.state.error.site}
                isLoading={this.state.isFetchingSite}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={availableOptions}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].is_active}
                required
                value={this.state.isActive}
                onChange={(e) => this.onChangeText("isActive", e)}
                error={this.state.error.isActive}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Committee);
