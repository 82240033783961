import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./style.css";

const ControlledTabs = (props) => {
  const [key, setKey] = useState(
    props.options.length > 0 ? props.options[0].title.toLowerCase() : undefined
  );

  useEffect(() => {
    if (props.activeTab) {
      setKey(props.activeTab);
    }
  }, [props.activeTab]); // Add dependency to avoid re-rendering

  return (
    <>
      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className={`tab gap-3 flex-nowrap ${props.className}`}
        style={props.styles}
      >
        {props.options.map((item, i) => {
          // Check if item.isButton is true
          if (item.isButton === true) {
            return (
              <Tab
                key={i}
                eventKey={`button-${i}`}
                title={
                  <button
                    className='btn btn-primary' // Adjust the button style
                    onClick={item.onClick || (() => {})} // Optional click handler
                  >
                    {item.title}
                  </button>
                }
              />
            );
          }

          // Render as a regular tab
          return (
            <Tab key={i} eventKey={item.title.toLowerCase()} title={item.title}>
              {item.data}
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
};

export default ControlledTabs;
