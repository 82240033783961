import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Footer } from "../../components/Landing/Footer";
import LandingPageLayout from "../../Layout/Landing/LandingPageLayout";
import { Button } from "../../components/Button";

class NoInternet extends React.Component {
  render() {
    return (
      <>
        <Navbar {...this.props} isFixed isMenuLight isTransparent />
        <div className="main-landing-container" id="landingContainer">
          <LandingPageLayout id="howWorks">
            <center>
              <h1 className="mb-3">No internet connection</h1>
              <Button
                text="Try again"
                onPress={() => window.location.reload()}
              />
            </center>
          </LandingPageLayout>
          <Footer />
        </div>
      </>
    );
  }
}

export default NoInternet;
