import React, { Component } from "react";
import { Input, Select } from "../../../components/Input";
import icons from "../../../constants/icons";
import { Button } from "../../../components/Button";
import { getStorage } from "../../../utils/storage";
import toastMessage from "../../../utils/toastMessage";
import api from "../../../utils/api";
import { fetchShipmentNumber } from "../../../utils/queries/request/shipping-queue";
import { generateShipmentNumber } from "../../../utils/random";
import { fetchRequestedProducts } from "../../../utils/queries/request-product";
import language from "../../../language";
import axios from "axios";
import ShippingQueue from "../../../components/Requests/shipping/ShippingQueue";

export default class ProvideBloodRequested extends Component {
  state = {
    shipmentNumber: "",
    productRequested: [],
    selectedProduct: "",
    requestedProductList: [],
    shipmentDate: "",
    isLoadingShipmentNumber: false,
    isSubmitting: false,
    isRefreshing: false,
    error: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.getShipmentNumber(true);
    this.getProductRequested(true);
    this.setState({ user });
    this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.refreshShipmentNumber !== this.props.refreshShipmentNumber &&
      this.props.refreshShipmentNumber
    ) {
      this.getShipmentNumber(true);
    }
  }

  getShipmentNumber = async (isLoadingShipmentNumber) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingShipmentNumber });

      const data = await fetchShipmentNumber(defaultLanguage);

      this.setState({
        shipmentNumber: generateShipmentNumber(data.data),
        isLoadingShipmentNumber: false,
        isSubmitting: false,
        isLoadingShipmentNumber: false,
      });
    } catch (error) {
      this.setState({
        isLoadingShipmentNumber: false,
        isSubmitting: false,
        isLoadingShipmentNumber: false,
      });

      toastMessage("error", error);
    }
  };

  getProductRequested = async (isLoadingProductRequested, request) => {
    try {
      this.setState({ isLoadingProductRequested });
      const { defaultLanguage } = this.props;

      const { data, count } = await fetchRequestedProducts(defaultLanguage, {
        request: this.props.requestId,
        page: 1,
        limit: 500,
      });

      let requestedProductList = [],
        products = [];
      data.forEach((el) => {
        let key =
          "(" +
          el.bloodGroup +
          ") " +
          el.productType.code +
          " " +
          el.productType.name;
        if (!products.includes(key)) {
          requestedProductList.push({
            ...el,
            label: key,
            value: el.bloodGroup + "_" + el.productType._id,
          });
          products.push(key);
        }
      });

      this.setState({
        isLoadingProductRequested: false,
        requestedProductList,
        totalPageCount: count,
      });
    } catch (error) {
      this.setState({ isLoadingProductRequested: false });
      toastMessage("error", error);
    }
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;
      this.setState({ isLoading });

      const { data } = await fetchRequestedProducts(defaultLanguage, {
        page: 1,
        limit: 500,
        id: "",
        q: "",
        request: this.props.request._id,
      });

      console.log(data);

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage("error", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isSearching: false,
    });

    if (field === "productRequested") {
      let requestedBloodGroup = [];
      for (let el of this.state.requestedProductList) {
        if (inputValue.productType._id === el.productType._id) {
          requestedBloodGroup.push({
            label: el.bloodGroup,
            value: el.bloodGroup,
          });
        }
      }

      this.setState({ requestedBloodGroup });
    }
  };

  handleShowModal(modal, title, item = {}) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      ...item,
    });
  }

  validateTransfer = () => {
    const { error, product, shipmentNumber, productRequested } = this.state;

    if (!productRequested) {
      error.productRequested = "Product requested is required";
    }

    if (!product) {
      error.product = "Product is required";
    }

    if (shipmentNumber === "") {
      error.shipmentNumber = "Shipment number is required";
    }

    this.setState({
      error,
    });
  };

  submitRequest = async () => {
    const user = await getStorage();
    const { requestedBloodRequest } = this.props;

    // console.log("shipment number:", this.state.shipmentNumber);
    // console.log("product:", this.state.product);
    // console.log("donation number:", this.state.donationNumber);
    // console.log("requestedProduct:", this.props.requestedProduct);
    // console.log("requestedBloodRequest:", requestedBloodRequest);
    // console.log("from:", requestedBloodRequest?.bloodBank._id);
    // console.log("to:", requestedBloodRequest?.hospital.bloodBank);
    // console.log("requestNo:", requestedBloodRequest.requestNo);
    // console.log("productType:", this.props.requestedProduct[0]?.productType);
    // console.log("bloodGroup:", this.props.requestedProduct[0]?.bloodGroup);

    try {
      const dataToSubmit = {
        shipmentNumber: this.state.shipmentNumber,
        product: this.state.product,
        to: requestedBloodRequest?.hospital.bloodBank,
        from: requestedBloodRequest?.bloodBank._id,
        donationNumber: this.state.donationNumber,
        requestNo: requestedBloodRequest.requestNo,
        bloodGroup: this.props.requestedProduct[0]?.bloodGroup,
        productType: this.props.requestedProduct[0]?.productType,
      };

      let method = "POST",
        requestBody = {
          ...dataToSubmit,
        };

      if (this.props.requestedProduct[0]?._id) {
        method = "PUT";
        requestBody.id = this.props.requestedProduct[0]?._id;
      }

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/shipping-queue`,
        data: requestBody,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      await axios(options);

      toastMessage("success", "Request submitted successfully");
    } catch (error) {
      console.error("Error in submitRequest:", error);
      toastMessage("error", error);

      this.setState({
        isSubmitting: false,
        isRefreshing: false,
      });
    }
  };

  render() {
    return (
      <div className="shipment-requested">
        <div>
          <p>
            Requested By :
            <span> {this.props.requestedBloodRequest?.bloodBank?.name}</span>
          </p>
          <p>
            Date :<span> {this.props.requestedBloodRequest?.createdAt}</span>
          </p>
          <p>
            Status :
            <span
              style={{
                color:
                  this.props.requestedProduct[0]?.status === "Pending"
                    ? "red"
                    : "green",
                fontSize: "0.7rem",
                backgroundColor: "rgba(0,0,0,0.1)",
                padding: "0.2rem 0.2rem",
                borderRadius: "5px",
                marginLeft: "0.5rem",
              }}
            >
              {this.props.requestedProduct[0]?.status}
            </span>
          </p>
        </div>
        {this.props?.requestedProduct[0]?.status === "pending" && (
          <div>
            <Input
              label={"Shipment Number"}
              value={this.state.shipmentNumber}
              onChange={(e) => this.onChangeText("shipmentNumber", e)}
              error={this.state.error.shipmentNumber}
              leftIcon={icons.shipment}
              disabled
            />

            <Select
              options={this.state.requestedProductList}
              placeholder="Select Product"
              label="Product"
              required
              value={this.state.selectedProduct}
              onChange={(value) => this.setState({ selectedProduct: value })}
            />

            <Input
              label="Donation Number"
              value={this.state.donationNumber}
              onChange={(e) => this.onChangeText("donationNumber", e)}
              error={this.state.error.donationNumber}
              leftIcon={icons.date}
            />

            <Input
              label="Product"
              value={this.state.product}
              onChange={(e) => this.onChangeText("product", e)}
              error={this.state.error.product}
              leftIcon={icons.date}
            />

            <Button
              className="submit-button"
              isSubmitting={this.state.isSubmitting}
              onPress={(event) => {
                event.preventDefault();
                this.submitRequest();
              }}
              text="Send"
            />
          </div>
        )}
        <ShippingQueue
          shipmentNumber={this.state.shipmentNumber}
          isRefreshing={this.state.isRefreshing}
          addedBy={this.state?.user?._id}
          status="pending"
          requestNo={this.props.requestNo}
        />
      </div>
    );
  }
}
