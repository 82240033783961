import React from "react";
import { connect } from "react-redux";
import { Input } from "../../Input";
import language from "../../../language";
import { ListItem } from "../../List";
import icons from "../../../constants/icons";
import { Button } from "../../Button";
import UserImg from "../../../assets/userPhotoPlaceholder.png";
import { Modal } from "../../Modal";
import PatientProfile from "../Profile";
import accessMenu from "../../../utils/accessMenu";
import toastMessage from "../../../utils/toastMessage";
import { fetchPatients } from "../../../utils/queries/patient";

class SearchPatient extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    patients: [],
  };

  handlePatientSearch = async (isSubmitting) => {
    await this.validateForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { identityNumber, firstName, lastName, contact } = this.state;

      this.setState({
        isSubmitting,
        isDoneSearching: false,
      });

      const { data } = await fetchPatients(this.props.defaultLanguage, {
        type: "dropdown",
        identityNumber:
          identityNumber && identityNumber !== ""
            ? identityNumber?.replace(/\s+/g, "")
            : undefined,
        firstName,
        lastName,
        contact,
        page: 1,
        limit: 10,
      });

      this.setState({
        isSubmitting: false,
        isDoneSearching: true,
      });

      if (data.length === 1) {
        this.setState({
          data: data[0],
        });
      } else if (data.length > 1) {
        this.setState({ patients: data });
        this.handleShowModal(
          "patientsModal",
          language[this.props.defaultLanguage].patients
        );
      } else {
        toastMessage("error", "Patient couldn't find");
      }
    } catch (error) {
      console.log(error);
      this.setState({ isSubmitting: false, isDoneSearching: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isDoneSearching: false,
    });
  };

  handleShowMoreSearchOptions() {
    this.setState({
      moreSearchOptions: !this.state.moreSearchOptions,
    });
  }

  handleNextAction(item) {
    if (this.props.navigationUrl) {
      window.location.href = `${this.props.navigationUrl}/${item._id}/${
        item.firstName + " " + item.lastName
      }`;
      return;
    }

    if (this.props.handleNext) {
      this.props.handleCloseModal();

      this.props.handleNext(item);

      return;
    }

    window.location.href = `/dashboard/donors/${item._id}/${
      item.firstName + " " + item.lastName
    }`;
  }

  handleShowModal(modal, title, item = {}) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      ...item,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleReset() {
    this.setState({
      identityNumber: "",
      name: "",
      contact: "",
      imageSrc: null,
    });
  }

  render() {
    const { canReadPatientLabResult } = accessMenu();
    const actionMenus = [];
    if (canReadPatientLabResult) {
      actionMenus.push({
        name: language[this.props.defaultLanguage].lab_result,
        icon: icons.lab,
        type: "labResult",
      });
    }

    return (
      <>
        <div className="card-body">
          {this.state.error.errorMessage && (
            <div className="alert alert-danger">
              <span>{this.state.error.errorMessage}</span>
            </div>
          )}
          <div className="row">
            <center className="col-md-12">
              {this?.state?.imageSrc && (
                <div className="donor-profile-image-container  mb-5">
                  <img
                    src={
                      this?.state?.imageSrc
                        ? `data:image/png;base64,${this?.state?.imageSrc}`
                        : UserImg
                    }
                  />
                </div>
              )}
            </center>
            <div className="col-md-6">
              <Input
                label={language[this.props.defaultLanguage].patient_id}
                value={this.state.patientId}
                onChange={(e) => this.onChangeText("patientId", e)}
                error={this.state.error.patientId}
                leftIcon={icons.user}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].id_number_placeholder
                }
                label={language[this.props.defaultLanguage].id_number}
                value={this.state.identityNumber}
                onChange={(e) => this.onChangeText("identityNumber", e)}
                error={this.state.error.identityNumber}
                leftIcon={icons.user}
              />
            </div>
            <div className="col-md-6">
              <Input
                label={language[this.props.defaultLanguage].contact}
                value={this.state.contact}
                onChange={(e) => this.onChangeText("contact", e)}
                error={this.state.error.contact}
                leftIcon={icons.contact}
              />
            </div>
            <div className="col-md-6">
              <Input
                label={language[this.props.defaultLanguage].email_address}
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
                leftIcon={icons.email}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
                leftIcon={icons.user}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {this.props.handleCloseModal && (
            <Button
              className="btn-default"
              text={language[this.props.defaultLanguage].cancel}
              onPress={this.props.handleCloseModal}
            />
          )}
          {this.state.identityNumber && (
            <Button
              className="btn-default"
              text={language[this.props.defaultLanguage].reset}
              onPress={this.handleReset.bind(this)}
            />
          )}
          <Button
            text={language[this.props.defaultLanguage].search}
            isSubmitting={this.state.isSubmitting}
            onPress={this.handlePatientSearch.bind(this)}
          />
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "resultModal")}
          show={this.state.resultModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <div>
            {this.state.resultType === "demography" && (
              <>
                <PatientProfile
                  patientId={this.state.patientId}
                  imageCol={12}
                  infoCol={12}
                />
              </>
            )}
          </div>
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "donorsModal")}
          show={this.state.donorsModal}
          title={this.state.modalTitle}
        >
          <div>
            {this.state.patients.map((el, i) => {
              const description = [el.identityNumber]
                .filter((value) => value !== undefined && value !== null)
                .join(" - ");
              return (
                <div key={i} style={{ margin: "1.5rem 0" }}>
                  <ListItem
                    image={`data:image/png;base64,${el.imageUrl}` || UserImg}
                    name={el.firstName + " " + el.lastName}
                    description={description}
                    onPress={() =>
                      (window.location.href = `/dashboard/patient/${el._id}/${
                        el.firstName + " " + el.lastName
                      }`)
                    }
                    rightIcon={icons.chevronRight}
                    imageWidth={60}
                    imageHeight={60}
                  />
                </div>
              );
            })}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SearchPatient);
