import toastMessage from "../../toastMessage";
import { getStorage } from "../../storage";
import language from "../../../language";
import { defineRoleCenterSite } from "../../handleAccessRoles";
import moment from "moment";
import api from "../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchInventoryData = async (
  lng = "english",
  {
    page,
    limit,
    donation,
    donor,
    id,
    type,
    center,
    centerSite,
    bloodGroup,
    q,
    isCount,
    status,
    donationNumber,
    sortField,
    startDate,
    endDate,
    receivedBy,
    startReceivedDate,
    endReceivedDate,
    code,
  }
) => {
  try {
    const user = await getStorage();

    const { centerRole, centerSiteRole } = await defineRoleCenterSite();

    let query = {
      page: page,
      limit: limit,
      donation,
      donor,
      id,
      center: centerRole || center,
      centerSite: centerSiteRole || centerSite,
      bloodGroup,
      q,
      count: isCount,
      status,
      donationNumber,
      sortField,
      startReceivedDate: startDate,
      endReceivedDate: endDate,
      receivedBy,
      code,
    };

    if (query.count) {
      delete query.page;
      delete query.limit;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/bloodInventory`,
      params: query,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    if (type === "dropdown") {
      return queryRes.data.map((el) => {
        return {
          ...el,
          label: `${el.category},  ${el.bloodGroup}, ${el.donation._id}, ${el.label}`,
          value: el._id,
        };
      });
    }

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteInventory = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/bloodInventory`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].delete_data_success);
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchInventories = async (
  lng = "english",
  {
    page,
    limit,
    donation,
    id,
    code,
    center,
    centerSite,
    status,
    startDate,
    endDate,
    isCount,
    isLabeled,
    receivedBy,
    reason,
    component,
    bloodGroup,
    productIds,
    productType,
    countStatusParams = {},
  }
) => {
  try {
    const user = await getStorage();

    const { centerRole, centerSiteRole, stock } = await defineRoleCenterSite();

    let filters = {
      page: page,
      limit: limit,
      donation,
      id,
      center: centerRole || center,
      centerSite: centerSiteRole || centerSite,
      status,
      startCreatedAt: startDate,
      endCreatedAt: endDate
        ? moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm")
        : undefined,
      count: isCount,
      stock,
      isLabeled,
      code,
      receivedBy,
      reason,
      component,
      bloodGroup,
      productIds,
      productType,
      startExpiredDate: startDate,
      endExpiredDate: endDate
        ? moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm")
        : undefined,
      ...countStatusParams,
    };

    if (filters.donation && filters.donation !== "") {
      delete filters.startCreatedAt;
      delete filters.endCreatedAt;
      delete filters.startExpiredDate;
      delete filters.endExpiredDate;
      delete filters.status;
    }

    if (filters.startExpiredDate || filters.endExpiredDate) {
      delete filters.startCreatedAt;
      delete filters.endCreatedAt;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/inventory`,
      params: filters,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    if (isCount) {
      return queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchInventoryByStatus = async (
  lng = "english",
  {
    page,
    limit,
    donation,
    id,
    type,
    center,
    centerSite,
    status,
    startDate,
    endDate,
    receivedBy,
  }
) => {
  try {
    const user = await getStorage();

    const { centerRole, centerSiteRole } = await defineRoleCenterSite();

    const options = {
      method: "GET",
      url: `${API_URL}/bloodInventory/analytics-status`,
      params: {
        page: page,
        limit: limit,
        donation,
        id,
        center: centerRole || center,
        centerSite: centerSiteRole || centerSite,
        status,
        startDate,
        endDate: moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm"),
        receivedBy,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    if (type === "dropdown") {
      return queryRes.data.map((el) => {
        return {
          ...el,
          label: `${el.category},  ${el.bloodGroup}, ${el.donation._id}, ${el.label}`,
          value: el._id,
        };
      });
    }

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchInventoryPerBloodGroup = async (lng = "english", params) => {
  try {
    const user = await getStorage();

    const { centerRole, centerSiteRole } = await defineRoleCenterSite();

    const options = {
      method: "GET",
      url: `${API_URL}/bloodInventory/analytics`,
      params: {
        ...params,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchCurrentInventoriesPerProductType = async (lng, params) => {
  try {
    const user = await getStorage();

    const { stock } = await defineRoleCenterSite();

    const options = {
      method: "GET",
      url: `${API_URL}/inventory-current-stock-status`,
      params: {
        ...params,
        stock: params.stock ? params.stock : stock ? stock : undefined,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchAndUpdateCurrentInventoriesStockStatus = async (
  lng,
  params
) => {
  try {
    const options = {
      method: "GET",
      url: `${API_URL}/inventory/get-current-inventory-per-product-type`,
      params,
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};
