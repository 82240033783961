import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { ROLE_COLLECTIONS } from "../../constants/ROLES";
import { defineRole } from "../handleAccessRoles";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (lng = "english", params) => {
  const { page, limit, type, donationNumber } = params;
  try {
    const user = await getStorage();

    const canAccess = donationNumber
      ? true
      : defineRole({
          roles: user.accessRole,
          menu: ROLE_COLLECTIONS,
          operation: "read",
        });
    if (!canAccess) return;

    delete params.type;

    if (params.startDate) {
      params.startCollectionDate = params.startDate;
      delete params.startDate;
    }

    if (params.endDate) {
      params.endCollectionDate = params.endDate;
      delete params.endDate;
    }

    if (params.donationNumber || params.donor) {
      delete params.startCollectionDate;
      delete params.endCollectionDate;
      delete params.center;
      delete params.centerSite;
    }

    if (params.q) {
      params.q = params.q.trim();
    }

    const options = {
      method: "GET",
      url: `${API_URL}/blooddonation`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "donor", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    console.log(error);
    toastMessage("error", error);
  }
};

export default fetchData;

export const deleteBloodDonation = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/blooddonation`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_donation);
  } catch (error) {
    toastMessage("error", error);
  }
};
