import React from "react";
import { connect } from "react-redux";
import { Input, Switch } from "../Input";
import language from "../../language";
import { ListItem } from "../List";
import donorQuery from "../../utils/queries/donorsQuery";
import icons from "../../constants/icons";
import { Button } from "../Button";
import UserImg from "../../assets/userPhotoPlaceholder.png";
import { Modal } from "../Modal";
import { BloodTesting } from "../BloodTesting";
import BloodCollection from "../BloodCollection/BloodCollection";
import PreDonationAssessment from "../BloodAssessment/Admin/PreDonationAssessment";
import DonorProfile from "./DonorProfile";
import { VitalSigns } from "../VitalSigns";
import Separator from "../Separator/Separator";
import { Appointments } from "../BookAppointment";
import { defineRole } from "../../utils/handleAccessRoles";
import accessMenu from "../../utils/accessMenu";
import toastMessage from "../../utils/toastMessage";
class SearchDonor extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    donors: [],
  };

  componentDidMount() {
    if (this.props.donorNumber) {
      this.setState({ donorNumber: this.props.donorNumber });
    }
  }

  validateForm() {
    let { error, donorNumber } = this.state;

    // if (donorNumber === "") {
    //   error.donorNumber =
    //     language[this.props.defaultLanguage].donor_number_required;
    // }

    this.setState({ error });
  }

  handleDonorSearch = async (isSubmitting) => {
    await this.validateForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { donorNumber, identityDocNumber, name, phoneNumber } = this.state;

      this.setState({
        isSubmitting,
        isDoneSearching: false,
      });

      const { data } = await donorQuery(this.props.defaultLanguage, {
        type: "dropdown",
        donorNumber:
          donorNumber && donorNumber !== ""
            ? donorNumber?.replace(/\s+/g, "")
            : undefined,
        identityDocNumber: identityDocNumber ? identityDocNumber : undefined,
        page: 1,
        limit: 10,
        q: name ? name : undefined,
        phoneNumber,
      });

      // if (data.length === 1) return this.handleNextAction(data[0]);
      let updatedData = {};

      if (data.length === 1) {
        // window.location.href = `/dashboard/donors/${data[0]._id}/${
        //   data[0].firstName + " " + data[0].lastName
        // }`;
        updatedData.name = data[0].firstName + " " + data[0].lastName;
        updatedData.identityDocNumber = data[0].identityDocNumber;
        updatedData.imageSrc = data[0].imageUrl;
        updatedData.donorNumber = data[0].donorNumber;
        updatedData.donorId = data[0]._id;
        updatedData.blocked = data[0].blockInfo?.blocked;
        updatedData.blockReason = data[0].blockInfo?.blockReason;
        updatedData.bloodGroup = data[0].bloodGroup;
        updatedData.phoneNumber = data[0].phoneNumber;
        updatedData.residentAddress = `${data[0].province || ""} ${
          data[0].district || ""
        } ${data[0].sector || ""} ${data[0].cell || ""} ${
          data[0].village || ""
        }`;
      } else if (data.length > 1) {
        this.setState({ donors: data });
        this.handleShowModal(
          "donorsModal",
          language[this.props.defaultLanguage].donors
        );
      } else {
        toastMessage("error", "Donor couldn't find");
      }
      this.setState({
        ...updatedData,
        isSubmitting: false,
        isDoneSearching: true,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isSubmitting: false, isDoneSearching: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];
    delete error["identityDocNumber"];

    this.setState({
      [field]: inputValue,
      isDoneSearching: false,
    });
  };

  handleShowMoreSearchOptions() {
    this.setState({
      moreSearchOptions: !this.state.moreSearchOptions,
    });
  }

  handleNextAction(item) {
    if (this.props.navigationUrl) {
      window.location.href = `${this.props.navigationUrl}/${item._id}/${
        item.firstName + " " + item.lastName
      }`;
      return;
    }

    if (this.props.handleNext) {
      this.props.handleCloseModal();

      this.props.handleNext(item);

      return;
    }

    window.location.href = `/dashboard/donors/${item._id}/${
      item.firstName + " " + item.lastName
    }`;
  }

  handleShowModal(modal, title, item = {}) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      ...item,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleReset() {
    this.setState({
      donorId: "",
      donorNumber: "",
      name: "",
      phoneNumber: "",
      identityDocNumber: "",
      donorId: "",
      imageSrc: null,
    });
  }

  render() {
    const {
      canReadCollection,
      canReadAppointment,
      canReadVital,
      canReadDonorDemography,
      canReadPreAssessment,
      canReadBloodTest,
    } = accessMenu();
    const actionMenus = [];
    if (canReadDonorDemography) {
      actionMenus.push({
        name: language[this.props.defaultLanguage].demography,
        icon: icons.user,
        type: "demography",
      });
    }

    if (canReadCollection) {
      actionMenus.push({
        name: language[this.props.defaultLanguage].collection,
        icon: icons.donation,
        type: "collection",
      });
    }

    if (canReadBloodTest) {
      actionMenus.push({
        name: language[this.props.defaultLanguage].blood_test,
        icon: icons.blood_test,
        type: "testing",
      });
    }

    if (canReadAppointment) {
      actionMenus.push({
        name: language[this.props.defaultLanguage].appointments,
        icon: icons.blood_test,
        type: "appointment",
      });
    }

    if (canReadPreAssessment) {
      actionMenus.push({
        name: language[this.props.defaultLanguage].pre_assessments,
        icon: icons.assessment,
        type: "questionnaire",
      });
    }

    if (canReadVital) {
      actionMenus.push({
        name: language[this.props.defaultLanguage].vital_signs,
        icon: icons.vital_sign,
        type: "vitalSign",
      });
    }

    return (
      <>
        <div className="card-body">
          {this.state.error.errorMessage && (
            <div className="alert alert-danger">
              <span>{this.state.error.errorMessage}</span>
            </div>
          )}
          <div className="row">
            <center className="col-md-12">
              {this?.state?.imageSrc && (
                <div className="donor-profile-image-container  mb-5">
                  <img
                    src={
                      this?.state?.imageSrc
                        ? `data:image/png;base64,${this?.state?.imageSrc}`
                        : UserImg
                    }
                  />
                </div>
              )}
            </center>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].donor_number_placeholder
                }
                label={language[this.props.defaultLanguage].donor_number}
                value={this.state.donorNumber}
                onChange={(e) => this.onChangeText("donorNumber", e)}
                error={this.state.error.donorNumber}
                leftIcon={icons.user}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .identification_document_number_placeholder
                }
                label={
                  language[this.props.defaultLanguage]
                    .identification_document_number
                }
                value={this.state.identityDocNumber}
                onChange={(e) => this.onChangeText("identityDocNumber", e)}
                error={this.state.error.identityDocNumber}
                leftIcon={icons.identification}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
                leftIcon={icons.user}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].phone_number_placeholder
                }
                label={language[this.props.defaultLanguage].phone_number}
                value={this.state.phoneNumber}
                onChange={(e) => this.onChangeText("phoneNumber", e)}
                error={this.state.error.phoneNumber}
                leftIcon={icons.phone}
                nameStyles="text-bold"
              />
            </div>

            {this.state.donorId && (
              <>
                <div className="col-md-12">
                  <Input
                    placeholder={language[this.props.defaultLanguage].select}
                    label={
                      language[this.props.defaultLanguage].resident_address
                    }
                    value={this.state.residentAddress}
                    onChange={(e) => this.onChangeText("residentAddress", e)}
                    error={this.state.error.residentAddress}
                    leftIcon={icons.location}
                    nameStyles="text-bold"
                  />
                </div>
                <hr />
                <Separator
                  title={language[this.props.defaultLanguage].actions}
                />
                <div className="row">
                  {actionMenus.map((el, i) => {
                    return (
                      <div
                        key={i}
                        style={{ marginBottom: "1rem" }}
                        className={this.props.actionMenuSize || "col-md-6"}
                      >
                        <ListItem
                          icon={el.icon}
                          name={el.name}
                          rightIcon={icons.chevronRight}
                          className="list-item-card"
                          onPress={() =>
                            this.handleShowModal("resultModal", el.name, {
                              resultType: el.type,
                            })
                          }
                        />
                      </div>
                    );
                  })}
                </div>

                {this.state?.blocked && (
                  <div className="row">
                    <hr />
                    <Separator
                      title={language[this.props.defaultLanguage].block_info}
                    />
                    <div>
                      <Switch
                        checked={this.state?.blocked}
                        label={language[this.props.defaultLanguage].blocked}
                        description={this.state.blockReason}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="modal-footer">
          {this.props.handleCloseModal && (
            <Button
              className="btn-default"
              text={language[this.props.defaultLanguage].cancel}
              onPress={this.props.handleCloseModal}
            />
          )}
          {this.state.donorNumber && (
            <Button
              className="btn-default"
              text={language[this.props.defaultLanguage].reset}
              onPress={this.handleReset.bind(this)}
            />
          )}
          <Button
            text={language[this.props.defaultLanguage].search}
            isSubmitting={this.state.isSubmitting}
            onPress={this.handleDonorSearch.bind(this)}
          />
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "resultModal")}
          show={this.state.resultModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <div>
            {this.state.resultType === "demography" && (
              <>
                <DonorProfile
                  donorId={this.state.donorId}
                  imageCol={12}
                  infoCol={12}
                />
                <div className="modal-footer">
                  <Button
                    text={language[this.props.defaultLanguage].view_my_profile}
                    onPress={() =>
                      (window.location.href = `/dashboard/donors/${this.state.donorId}/${this.state.name}`)
                    }
                  />
                </div>
              </>
            )}
            {this.state.resultType === "collection" && (
              <div className="card-body">
                <BloodCollection donorId={this.state.donorId} />
              </div>
            )}
            {this.state.resultType === "testing" && (
              <div className="card-body">
                <BloodTesting donorId={this.state.donorId} showLabResult />
              </div>
            )}
            {this.state.resultType === "questionnaire" && (
              <div className="card-body">
                <PreDonationAssessment donorId={this.state.donorId} />
              </div>
            )}
            {this.state.resultType === "vitalSign" && (
              <div className="card-body">
                <VitalSigns donorId={this.state.donorId} />
              </div>
            )}
            {this.state.resultType === "appointment" && (
              <div className="card-body">
                <Appointments donorId={this.state.donorId} />
              </div>
            )}
          </div>
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "donorsModal")}
          show={this.state.donorsModal}
          title={this.state.modalTitle}
        >
          <div>
            {this.state.donors.map((el, i) => {
              const description = [
                el.donorNumber,
                el.identityDocNumber,
                el.bloodGroup,
                el?.lastDonationSite?.name,
              ]
                .filter((value) => value !== undefined && value !== null)
                .join(" - ");
              return (
                <div key={i} style={{ margin: "1.5rem 0" }}>
                  <ListItem
                    image={`data:image/png;base64,${el.imageUrl}` || UserImg}
                    name={el.firstName + " " + el.lastName}
                    description={description}
                    onPress={() =>
                      (window.location.href = `/dashboard/donors/${el._id}/${
                        el.firstName + " " + el.lastName
                      }`)
                    }
                    rightIcon={icons.chevronRight}
                    imageWidth={60}
                    imageHeight={60}
                  />
                </div>
              );
            })}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SearchDonor);
