import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import language from "../../language";
import { ROLE_PATIENT } from "../../constants/ROLES";
import { defineRole } from "../handleAccessRoles";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchRequestedProducts = async (
  lng = "english",
  { page, limit, type, id, q, request }
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/product-request`,
      params: {
        page: page,
        limit: limit,
        id,
        q,
        request,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return queryRes.data.map((el) => {
        return {
          ...el,
          label: el.code + " - " + el.name,
          value: el._id,
        };
      });
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteRequestedProducts = async (lng = "english", data) => {
  try {
    const user = await getStorage();
    const options = {
      method: "DELETE",
      url: `${API_URL}/product-request`,
      data,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_data);
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchBloodRequest = async (
  lng = "english",
  { page, limit, id, q, requestNo }
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/bloodRequest`,
      params: {
        page: page,
        limit: limit,
        id,
        q,
        requestNo,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};
