import React from "react";
import { connect } from "react-redux";
import { Checkbox, Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import accountQuery from "../../utils/queries/accountQuery";
import { fetchRejectionStatus } from "../../utils/queries/rejectionStatus";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import moment from "moment";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewUsage extends React.Component {
  state = {
    patientId: "",
    error: {},
    isSubmitting: false,
    hospitals: [],
    donations: [],
    patientId: "",
    donation: "",
    usageDate: moment().format("YYYY-MM-DD"),
    isTransfused: false,
  };

  componentDidMount = async () => {
    if (this.props._id) {
      this.setState({
        isTransfused: this.props.isTransfused,
        reaction: this.props.reaction
          ? {
              label: this.props.reaction.code + " " + this.props.reaction.label,
              value: this.props.reaction._id,
            }
          : null,
        usageDate: moment(this.props.usageDate).format("YYYY-MM-DD"),
        doctor: this.props.doctor
          ? {
              label: this.props.doctor.pin,
              value: this.props.doctor._id,
            }
          : null,
      });
    }
  };

  getReactions = async (isFetchingReasons, code) => {
    try {
      this.setState({
        isFetchingReasons,
      });

      const { data } = await fetchRejectionStatus(this.props.defaultLanguage, {
        inputType: "dropdown",
        type: "TRANSFUSION_REACTION",
        q: code ? code : undefined,
      });

      this.setState({
        isFetchingReasons: false,
      });
      return data;
    } catch (error) {
      this.setState({ isFetchingReasons: false });
    }
  };

  getAccounts = async (isFetchingAccount, code) => {
    try {
      this.setState({
        isFetchingAccount,
      });

      const { data } = await accountQuery(this.props.defaultLanguage, {
        inputType: "dropdown",
        q: code ? code : undefined,
      });

      this.setState({
        isFetchingAccount: false,
      });
      return data;
    } catch (error) {
      this.setState({ isFetchingAccount: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { usageDate, error, doctor } = this.state;

    if (usageDate === "") {
      error.usageDate = language[this.props.defaultLanguage].date_required;
    }

    if (!doctor) {
      error.doctor = language[this.props.defaultLanguage].doctor_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { reaction, doctor, isTransfused } = this.state,
        url = `${API_URL}/bloodusage`,
        method = "POST",
        { _id } = this.props,
        user = await getStorage();

      let requestedBody = {
        reaction: reaction.value,
        id: this.props.id,
        doctor: doctor?.value,
        isTransfused,
        status: isTransfused ? "transfused" : "untransfused",
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "data_update_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);
        });
    }
  };

  onCheck(field) {
    this.setState({ [field]: !this.state[field] });
  }

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            {this.state?.donationInfo?.donation?.donationNumber && (
              <div className="col-md-12">
                <Input
                  leftIcon={icons.donation}
                  label={language[this.props.defaultLanguage].donation_number}
                  required
                  value={this.state.donationInfo.donation.donationNumber}
                  onChange={(e) => this.onChangeText("donation", e)}
                  disabled
                />
              </div>
            )}
          </div>
          <div className="row">
            <Checkbox
              label="Confirm Transfusion"
              checked={this.state.isTransfused}
              onCheck={() => this.onCheck("isTransfused")}
            />
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getReactions(true, e)}
                label={language[this.props.defaultLanguage].reaction}
                required
                value={this.state.reaction}
                onChange={(e) => this.onChangeText("reaction", e)}
                error={this.state.error.reaction}
              />
            </div>

            <div className="col-md-12">
              <Input
                label={language[this.props.defaultLanguage].usage_date}
                required
                value={this.state.usageDate}
                onChange={(e) => this.onChangeText("usageDate", e)}
                error={this.state.error.usageDate}
                type="date"
              />
            </div>
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getAccounts(true, e)}
                label={language[this.props.defaultLanguage].doctor}
                placeholder={language[this.props.defaultLanguage].select}
                required
                value={this.state.doctor}
                onChange={(e) => this.onChangeText("doctor", e)}
                error={this.state.error.doctor}
                isLoading={this.state.isFetchingAccount}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewUsage);
