import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
import InventoryByReason from "../../../components/inventory/ByReason";
import capitalizeText from "../../../utils/capitalizeText";
import { DashboardHeader } from "../../../components/Header";

class InventoriesByReasonScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <DashboardHeader
              title={capitalizeText(this.props?.routeParams?.reason || "")}
            />
            <InventoryByReason reason={this.props?.routeParams?.reason} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(InventoriesByReasonScreen));
