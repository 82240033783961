import React from "react";
import { connect } from "react-redux";
import bloodCenterQuery from "../../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import { Input, Select } from "../../Input";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import icons from "../../../constants/icons";
import language from "../../../language";
import { nationalities } from "../../../constants/documentIDNumberTypes";
import { Button } from "../../Button";

class DonorFilter extends React.Component {
  state = {
    error: {},
  };

  componentDidMount() {
    this.getCenters();
  }
  getCenters = async (search = {}) => {
    try {
      this.setState({
        isFetchingCenter: true,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        search,
        page: 1,
        limit: 50,
      });

      this.setSelectedCenter(data);

      let center = null;
      const { centerRole } = await defineRoleCenterSite();
      if (!this.props._id && centerRole) {
        data.forEach((el) => {
          if (centerRole.includes(el._id)) {
            center = el;
          }
        });

        this.setState({ center }, () =>
          this.getCenterSites({ center: center?._id || undefined })
        );
      }

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });

      return [];
    }
  };

  getCenterSites = async (search = {}) => {
    try {
      this.setState({
        isFetchingCenterSite: true,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        ...search,
      });

      this.setSelectedCenterSites(data);

      const { centerSiteRole } = await defineRoleCenterSite();
      let centerSite = null;

      if (!this.props._id && centerSiteRole) {
        data.forEach((el) => {
          if (centerSiteRole.includes(el._id)) {
            centerSite = el;
          }
        });

        this.setState({ centerSite });
      }

      this.setState({
        centerSites: data,
        isFetchingCenterSite: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenterSite: false });

      return [];
    }
  };

  setSelectedCenter(centers) {
    const { center } = this.props;
    if (center) {
      const selectedCenter = centers.find(
        (el) => el.value === (center._id || center)
      );

      if (selectedCenter?._id) {
        this.setState(
          {
            center: {
              label: selectedCenter.name,
              value: selectedCenter._id,
            },
          },
          () => {
            this.getCenterSites({ center: selectedCenter._id });
          }
        );
      }
    }
  }

  setSelectedCenterSites(centerSites) {
    const { centerSite } = this.props;
    if (centerSite) {
      const selectedData = centerSites.find(
        (el) => el._id === (centerSite?._id || centerSite)
      );

      if (selectedData?._id) {
        this.setState({
          centerSite: {
            label: selectedData.name,
            value: selectedData._id,
          },
        });
      }
    }
  }

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "center") {
      this.getCenterSites({ center: inputValue.value });
    }
  };

  onSubmit() {
    const { nationality, center, centerSite } = this.state;

    let params = {
      center: center?.value || undefined,
      centerSite: centerSite?.value || undefined,
      nationality: nationality?.value || undefined,
    };

    this.props.fetchData && this.props.fetchData(params);

    this.props.handleCloseModal && this.props.handleCloseModal({});
  }

  handleReset() {
    this.props.fetchData && this.props.fetchData({});
    this.props.handleCloseModal && this.props.handleCloseModal({});
  }

  render() {
    return (
      <>
        <div className="col-md-6">
          <Select
            loadOptions={(e) => this.getCenters({ name: e })}
            leftIcon={icons.center}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].center}
            required
            value={this.state.center}
            onChange={(e) => this.onChangeText("center", e)}
            error={this.state.error.center}
            isLoading={this.state.isFetchingCenter}
          />
        </div>
        <div className="col-md-6">
          <Select
            loadOptions={(e) => this.getCenterSites({ search: e })}
            leftIcon={icons.site}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].center_site}
            value={this.state.centerSite}
            onChange={(e) => this.onChangeText("centerSite", e)}
            error={this.state.error.centerSite}
            isLoading={this.state.isFetchingCenterSite}
          />
        </div>
        <div className="col-md-6">
          <Select
            options={nationalities(this.props.defaultLanguage)}
            leftIcon={icons.nationality}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].nationality}
            value={this.state.nationality}
            onChange={(e) => this.onChangeText("nationality", e)}
            error={this.state.error.centerSite}
          />
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].reset}
            onPress={this.handleReset.bind(this)}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].apply}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};
export default connect(mapStateToProps)(DonorFilter);
