import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { getStorage } from "../../../utils/storage";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import axios from "axios";
import { Table } from "../../Table";
import { CSVLink } from "react-csv";
import handleSearch from "../../../utils/handleSearch";
import toastMessage from "../../../utils/toastMessage";
import { CentersFilter, DateFilter } from "../../Filters";
import handleFilters from "../../../utils/handleFilters";
import icons from "../../../constants/icons";

let copyData = [];

class PerCenterSite extends React.Component {
  state = {
    isLoading: false,
    data: [],
    csvData: [],
    total: 0,
    totalPageCount: 0,
    page: 1,
    limit: 10,
  };

  componentDidMount = async () => {
    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.getData(true);
    }
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: "O+",
        key: "O+",
      },
      {
        title: "O-",
        key: "O-",
      },
      {
        title: "A+",
        key: "A+",
      },
      {
        title: "B+",
        key: "B+",
      },
      {
        title: "B-",
        key: "B-",
      },
      {
        title: "AB+",
        key: "AB+",
      },
      {
        title: "Unspecified",
        key: "unspecified",
      },
      {
        title: "Total",
        key: "total",
      },
    ];

    return headers;
  }

  getData = async (isLoading, search = {}) => {
    try {
      let { page, limit } = this.state;
      let total = 0;
      let {} = this.props;
      const user = await getStorage();

      this.setState({ isLoading });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const filters = handleFilters(search);

      const params = {
        ...filters,
        page,
        limit,
      };

      const options = {
        url:
          process.env.REACT_APP_BBMS_BASE_API +
          "/bloodDonation/bloodGroup/centerSite",
        method: "get",
        params,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios(options);

      // data.data.forEach((item) => {
      //   total += item.total;
      // });

      this.setState({
        data: data.data,
        isLoading: false,
        total,
        totalPageCount: data.count,
      });

      copyData = data.data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    console.log(this.state.totalPageCount);
    return (
      <>
        {/* <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                <CentersFilter handleApplyFilters={(isLoading, filters) =>
                  this.getData(isLoading, filters)
                }
                startDate={this.state.startDate}
                endDate={this.state.endDate} />
                <DateFilter  handleApplyFilters={(isLoading, filters) =>
                  this.getData(isLoading, filters)
                }
                startDate={this.state.startDate}
                endDate={this.state.endDate}/>
              </div> */}

        <Table
          page={this.state.page}
          limit={this.state.limit}
          data={this.state.data}
          isSearch
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          totalPageCount={this.state.totalPageCount}
          handlePagination={this.handlePagination.bind(this)}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
          ]}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
        />

        {/* <div className="alert alert-info">
          {language[this.props.defaultLanguage].total}:{" "}
          <strong>{this.state.total}</strong>{" "}
        </div> */}

        <CSVLink
          ref="csvDownload"
          filename={"Donation per center site blood Group"}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(PerCenterSite);
