import React from "react";
import { connect } from "react-redux";
import language from "../../language/index.js";
import { Table } from "../Table/index.js";
import { CSVLink } from "react-csv";
import requestQuery, {
  deleteRequest,
} from "../../utils/queries/requestQuery.js";
import exportPDF from "../../utils/exportPDF.js";
import { DeleteModal, Modal } from "../Modal/index.js";
import { getStorage } from "../../utils/storage.js";
import RequestInfo from "./nominative/RequestInfo.js";
import icons from "../../constants/icons.js";
import accessMenu from "../../utils/accessMenu/index.js";

let copyData = [];

class MyRequests extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  returnFilters = async (search) => {
    const user = await getStorage();
    const { page, limit } = this.state;
    const { status } = this.props;
    return {
      page,
      limit,
      status,
      ...search,
      stock: user.stock,
      hospital: user.hospital,
    };
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await requestQuery(
        defaultLanguage,
        await this.returnFilters(search)
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].request_number,
        key: "requestNo",
        type: "link",
        styles: {
          textTransform: "uppercase",
        },
        copyEnabled: true,
        onPress: (item) =>
          (window.location.href =
            item.type === "bulk"
              ? `/dashboard/request/bulk/${item.requestNo}`
              : `/dashboard/request/${item.requestNo}`),
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",

        // type: "link",
        onPress: (item) =>
          (window.location.href =
            item.type === "bulk"
              ? `/dashboard/request/bulk/${item.requestNo}`
              : `/dashboard/request/${item.requestNo}`),
      },
      {
        title: language[this.props.defaultLanguage].requested_by,
        key: "hospital.name",
        styles: {
          textTransform: "uppercase",
        },
      },

      {
        title: language[this.props.defaultLanguage].to,
        key: "bloodBank.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].type,
        key: "type",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const { data } = await requestQuery(
        defaultLanguage,
        await this.returnFilters()
      );

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await requestQuery(defaultLanguage, this.returnFilters());

    this.setState({
      isLoading: false,
    });

    exportPDF("Requests", headers, data);
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteRequest(
        this.props.defaultLanguage,
        this.state.selected_data._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      requestNo: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    const {
      export_,
      request,
      add_request,
      delete_request,
      edit_request,
      delete_,
      edit,
      view,
    } = language[this.props.defaultLanguage];

    const {
      canCreateBloodRequestAccess,
      canReadBloodRequestAccess,
      canDeleteBloodRequestAccess,
      canUpdateBloodRequestAccess,
    } = accessMenu();

    let actions = [
      {
        canAccess: canReadBloodRequestAccess,

        name: view,
        onPress: (item) =>
          (window.location.href =
            item.type === "bulk"
              ? `/dashboard/request/bulk/${item.requestNo}`
              : `/dashboard/request/${item.requestNo}`),
        icon: icons.eye_on,
      },
    ];

    if (
      this.props.status === "pending" ||
      (Array.isArray(this.props.status) &&
        this.props.status.includes("pending"))
    ) {
      actions.push(
        // {
        //   canAccess: canUpdateBloodRequestAccess,
        //   name: edit,
        //   onPress: (item) =>
        //     this.handleShowModal("showModal", edit_request, item),
        //   icon: icons.edit,
        // },
        {
          canAccess: canDeleteBloodRequestAccess,
          name: delete_,
          onPress: this.handleShowModal.bind(
            this,
            "showDeleteModal",
            delete_request
          ),
          icon: icons.delete,
        }
      );
    }

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          // showAdd={this.props.showAdd && canCreateBloodRequestAccess}
          // addButtonText={
          //   language[this.props.defaultLanguage].nominative_request
          // }
          // handleAddPressed={this.handleShowModal.bind(
          //   this,
          //   "showNominativeModal",
          //   request
          // )}
          // rowPress={() =>
          //   this.handleShowModal(
          //     "showNominativeModal",
          //     language[this.props.defaultLanguage].nominative_request
          //   )
          // }
          filters={
            [
              // {
              //   type: "button",
              //   title: language[this.props.defaultLanguage].bulk_request,
              //   button_type: "button",
              //   icon: icons.add,
              //   onPress: () =>
              //     this.handleShowModal(
              //       "showBulkRequest",
              //       language[this.props.defaultLanguage].bulk_request
              //     ),
              // },
              // {
              //   type: "export",
              //   title: export_,
              //   button_type: "dropdown",
              //   icon: "bx bxs-download",
              //   options: [
              //     {
              //       name: "PDF",
              //       onPress: this.downloadPDF.bind(this),
              //     },
              //     {
              //       name: "CSV",
              //       onPress: this.downloadExcel.bind(this),
              //     },
              //   ],
              // },
            ]
          }
          actions={actions}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showRequest")}
          show={this.state.showRequest}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <RequestInfo
            handleCloseModal={this.handleCloseModal.bind(this, "showRequest")}
            data={this.state.selected_data}
            getData={this.getData.bind(this, true)}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selected_data.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"BloodRequests" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MyRequests);
