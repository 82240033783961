import React from "react";
import { connect } from "react-redux";
import { fetchTransferDonationLogsData } from "../../../utils/queries/logs/transferDonationQuery";
import { getStorage } from "../../../utils/storage";
import { Table } from "../../Table";
import language from "../../../language";

class TransferLogs extends React.Component {
  state = {
    username: "",
    error: {},
    isSubmitting: false,
    user: {},
    data: [],
    limit: 10,
    page: 1,
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.getData(true);
  };

  getData = async (search = {}) => {
    try {
      const { page, limit } = this.state;
      this.setState({
        isLoading: true,
      });

      const data = await fetchTransferDonationLogsData(
        this.props.defaultLanguage,
        {
          ...search,
          page,
          limit,
        }
      );

      this.setState({
        isLoading: false,
        data,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].old_donation,
        key: "oldDonation.donationNumber",
      },
      {
        title: language[this.props.defaultLanguage].new_donation,
        key: "newDonation.donationNumber",
      },
      {
        title: language[this.props.defaultLanguage].old_donor,
        key: "oldDonor.donorNumber",
      },
      {
        title: language[this.props.defaultLanguage].new_donor,
        key: "newDonor.donorNumber",
      },
      {
        title: language[this.props.defaultLanguage].type,
        key: "type",
        styles: {
          textTransform: "lowercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(TransferLogs);
