import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import bloodDonationQuery, {
  deleteBloodDonation,
} from "../../utils/queries/bloodDonationQuery";
import { ConfirmationModal, DeleteModal, Modal } from "../Modal";
import NewCollection from "./NewCollection";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import { SearchDonor } from "../Donors";
import icons from "../../constants/icons";
import toastMessage from "../../utils/toastMessage";
import axios from "axios";
import ViewVitalSign from "../VitalSigns/ViewSingleSign";
import { ROLE_BLOOD_TEST, ROLE_COLLECTIONS } from "../../constants/ROLES";
import handleFilters from "../../utils/handleFilters";
import moment from "moment";
import CardCount from "../CardCount/CardCount";
import {
  BloodGroupFilter,
  CentersFilter,
  CenterSitesFilter,
  DateFilter,
} from "../Filters";
import exportPDF from "../../utils/exportPDF";
import { CSVLink } from "react-csv";

let copyData = [];

class BloodCollection extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    suspendReason: "",
    filters: {
      startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
      endDate: moment().endOf("d").format("YYYY-MM-DD"),
    },
    counts: {
      total: 0,
      tested: 0,
    },
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.fetchData(true, this.state.filters);
  };

  fetchData = async (isLoading, search) => {
    const user = await getStorage();

    const updatedFilters = {
      ...this.state.filters,
      ...search,
    };

    this.setState({ filters: updatedFilters });

    this.getData(isLoading, updatedFilters);
    this.getCounts(isLoading, updatedFilters, "total");

    this.getCounts(
      isLoading,
      { ...updatedFilters, ["bloodInfo.tested"]: true },
      "tested"
    );
    this.getCounts(
      isLoading,
      { ...updatedFilters, addedBy: user.id },
      "addedByMe"
    );
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });
  }

  returnFilters = async (search) => {
    const { page, limit } = this.state;
    const { centerId, centerSiteId, customFilters } = this.props;
    const user = await getStorage();
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();

    let params = {
      page,
      limit,
      donor: this.props.donorId,
      id: this.props.donationId,
      center: centerId || centerRole,
      centerSite: centerSiteId || centerSiteRole,
      ...handleFilters(search),
      ...search,
      ...(customFilters || {}),
    };

    if (this.props.donorId) {
      delete params.startDate;
      delete params.endDate;
    }

    return params;
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      let params = await this.returnFilters(search);

      const { data, count } = await bloodDonationQuery(defaultLanguage, params);
      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCounts = async (isLoading, search = {}, type) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      let params = await this.returnFilters(search);

      params.isCount = true;

      const { data } = await bloodDonationQuery(defaultLanguage, params);

      const { counts } = this.state;

      counts[type] = data;

      this.setState({
        counts,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true, this.state.filters);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donationNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item._id}/${
            item.donor._id
          }/${item.donor.firstName + " " + item.donor.lastName}`),
        copyEnabled: true,
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].donor,
        key: "donor.donorNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item._id}/${
            item.donor._id
          }/${item.donor.firstName + " " + item.donor.lastName}`),
        styles: {
          textTransform: "uppercase",
        },
        copyEnabled: true,
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodInfo.bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].volume,
        key: "bloodInfo.volume",
      },
      {
        title: language[this.props.defaultLanguage].vital_sign,
        key: "collectionDate",
        type: "link",
        isMoment: true,
        formatTime: "lll",
        onPress: (item) =>
          this.handleShowModal(
            "showVitalSignModal",
            item._id || item,
            item?.vitalSign
          ),
        copyEnabled: true,
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].equipment_code,
        key: "equipment.code",
      },
      {
        title: language[this.props.defaultLanguage].equipment_name,
        key: "equipment.name",
      },
      {
        title: language[this.props.defaultLanguage].collection_date,
        key: "collectionDate",
        isMoment: true,
        formatTime: "ll",
      },
      {
        title: language[this.props.defaultLanguage].start_time,
        key: "startTime",
      },
      {
        title: language[this.props.defaultLanguage].end_time,
        key: "endTime",
      },
      {
        title: language[this.props.defaultLanguage].reaction,
        key: "reaction.isReaction",
      },
      {
        title: language[this.props.defaultLanguage].reaction_category,
        key: "reaction.category",
      },
      {
        title: language[this.props.defaultLanguage].reaction_comment,
        key: "reaction.comment",
      },
      {
        title: language[this.props.defaultLanguage].tested,
        key: "bloodInfo.tested",
      },
      {
        title: language[this.props.defaultLanguage].is_active,
        key: "isActive",
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].phlebotomist,
        key: "phlebotomist.email",
      },
      {
        title: language[this.props.defaultLanguage].collection_type,
        key: "collectionType.collectionTypeNumber",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].collection_type,
        key: "collectionType.name",
      },
      {
        title: language[this.props.defaultLanguage].reception_date,
        key: "stock.updatedAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].blood_donation_recorder,
        key: "bloodRecorder.pin",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].recorded_by,
        key: "addedBy.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].contact,
        key: "addedBy.email",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onUpdateStatus = async (status, modal) => {
    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isUpdating: true,
      });

      let url = `${process.env.REACT_APP_BBMS_BASE_API}/blooddonation`,
        method = "PUT",
        user = await getStorage(),
        { selectedData, reason } = this.state;

      const options = {
        method,
        url,
        data: {
          status,
          id: selectedData._id,
          donor: selectedData?.donor?._id,
          vitalSign: selectedData?.vitalSign?._id,
          donationNumber: selectedData?.donation?._id,
          center: selectedData?.center?._id,
          centerSite: selectedData?.centerSite?._id,
          blockInfo: {
            blocked: true,
            blockReason: reason,
          },
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      console.log("I am here");
      axios(options)
        .then((data) => {
          this.setState({
            isUpdating: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].data_update_success
          );

          this.handleCloseModal(modal);
          this.getData();
        })
        .catch((error) => {
          this.setState({ isUpdating: false });
          toastMessage("error", error);
        });
    }
  };

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteBloodDonation(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData();
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.fetchData(true, {
      donationNumber: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  downloadExcel = async (search = {}) => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const headers = this.returnTableHeaders();

      let params = await this.returnFilters(search);

      const { data } = await bloodDonationQuery(defaultLanguage, params);

      const formateData = data.map((el) => {
        return {
          donationNumber: el.donationNumber,
          bloodGroup: el.bloodGroup,
          center: el.center.name,
          centerSite: el.centerSite.name,
          collectionDate: moment(el.collectionDate).format("lll"),
          donor: el.donor.donorNumber,
          createdAt: moment(el.createdAt).format("lll"),
        };
      });

      this.setState({
        isLoading: false,
        csvData: formateData,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async (search = {}) => {
    this.setState({
      isLoading: true,
    });

    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    let params = await this.returnFilters(search);

    const { data } = await bloodDonationQuery(defaultLanguage, params);

    this.setState({
      isLoading: false,
    });

    exportPDF("Blood Donation", headers, data);
  };

  render() {
    const { add_collection } = language[this.props.defaultLanguage];
    return (
      <>
        <div className="row">
          {!this.props.donorId && (
            <>
              <div style={{ marginBottom: 16 }}>
                <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                  <CentersFilter
                    handleApplyFilters={(isLoading, filters) =>
                      this.fetchData(isLoading, filters)
                    }
                  />

                  <CenterSitesFilter
                    handleApplyFilters={(isLoading, filters) =>
                      this.fetchData(isLoading, filters)
                    }
                    filters={this.state.filters}
                  />

                  <BloodGroupFilter
                    handleApplyFilters={(isLoading, filters) =>
                      this.fetchData(isLoading, filters)
                    }
                  />
                  <DateFilter
                    handleApplyFilters={(isLoading, filters) =>
                      this.fetchData(isLoading, filters)
                    }
                    startDate={this.state.filters.startDate}
                    endDate={this.state.filters.endDate}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <CardCount
                  count={this.state.counts.total}
                  title={language[this.props.defaultLanguage].total}
                  color="orange"
                  icon="group"
                  isLoading={this.state.isLoadingCounts}
                  // onPress={() =>
                  //   (window.location.href = `/dashboard/production/inventory/byStatus/${this.state.filters.startDate}/${this.state.filters.endDate}/pending`)
                  // }
                />
              </div>
              <div className="col-md-4">
                <CardCount
                  count={this.state.counts.addedByMe}
                  title={language[this.props.defaultLanguage].added_by_me}
                  color="purple"
                  icon="person"
                  isLoading={this.state.isLoadingCounts}
                  // onPress={() =>
                  //   (window.location.href = `/dashboard/production/inventory/byStatus/${this.state.filters.startDate}/${this.state.filters.endDate}/labelled`)
                  // }
                />
              </div>
              <div className="col-md-4">
                <CardCount
                  count={this.state.counts.tested}
                  title={language[this.props.defaultLanguage].tested}
                  color="#078ECE"
                  icon="check"
                  isLoading={this.state.isLoadingCounts}
                  // onPress={() =>
                  //   (window.location.href = `/dashboard/production/inventory/byStatus/${this.state.filters.startDate}/${this.state.filters.endDate}/labelled`)
                  // }
                />
              </div>
            </>
          )}
        </div>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          addSearchButton
          searching={this.state.searching}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_COLLECTIONS,
            operation: "create",
            pass:
              this.state.user.role === "donor" ||
              this.state.user.account_type === "donor",
          })}
          addButtonText={add_collection}
          handleAddPressed={() =>
            this.handleShowModal(
              this.props.donorId ? "showModal" : "searchDonorModal",
              add_collection
            )
          }
          fetchData={(isLoading, search) => this.fetchData(isLoading, search)}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.fetchData(true, this.state.filters),
            },
            // {
            //   type: "export",
            //   title: language[this.props.defaultLanguage].export_,
            //   button_type: "dropdown",
            //   icon: "bx bxs-download",
            //   options: [
            //     // {
            //     //   name: "PDF",
            //     //   onPress: () => this.downloadPDF(this.state.filters),
            //     // },
            //     // {
            //     //   name: "CSV",
            //     //   onPress: () => this.downloadExcel(this.state.filters),
            //     // },
            //   ],
            // },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].view,
              onPress: (item) =>
                (window.location.href = `/dashboard/donations/${item._id}/${
                  item.donor._id
                }/${item.donor.firstName + " " + item.donor.lastName}`),
              icon: icons.eye_on,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].update,
              onPress: (item) =>
                this.handleShowModal(
                  "showModal",
                  language[this.props.defaultLanguage].update,
                  item
                ),
              icon: icons.edit,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_BLOOD_TEST,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].blood_test,
              onPress: (item) =>
                (window.location.href = `/dashboard/donations/${item._id}/${item.donor._id}/${item.donor.firstName} ${item.donor.lastName}`),
              icon: icons.blood_test,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].suspend,
              onPress: this.handleShowModal.bind(
                this,
                "showSuspendModal",
                language[this.props.defaultLanguage].suspend
              ),
              icon: icons.close,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].block,
              onPress: this.handleShowModal.bind(
                this,
                "showBlockModal",
                language[this.props.defaultLanguage].block
              ),
              icon: icons.block,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].block_permanent
              ),
              icon: icons.delete,
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
          backdrop="static"
        >
          <NewCollection
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            donorId={this.props.donorId}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "searchDonorModal")}
          show={this.state.searchDonorModal}
          title={language[this.props.defaultLanguage].search_donor}
        >
          <SearchDonor
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "searchDonorModal"
            )}
            actionMenuSize={12}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showVitalSignModal")}
          show={this.state.showVitalSignModal}
          title={this.state.modalTitle}
        >
          <ViewVitalSign
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showVitalSignModal"
            )}
            signId={this.state.selectedData?._id || this.state.selectedData}
            examQuestionnaire={
              this.state?.selectedData?.examQuestionnaire || []
            }
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showSuspendModal")}
          show={this.state.showSuspendModal}
          title={language[this.props.defaultLanguage].suspend}
        >
          <ConfirmationModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSuspendModal"
            )}
            error={this.state.error}
            onPressNegative={this.handleCloseModal.bind(
              this,
              "showSuspendModal"
            )}
            onPressPositive={() =>
              this.onUpdateStatus("suspended", "showSuspendModal")
            }
            onChange={(e) => this.onChangeText("reason", e)}
            isSubmitting={this.state.isSubmitting}
            getData={this.getData.bind(this)}
            withReason
            reason={this.state.reason}
            reasonFieldName="reason"
            negativeButtonText={language[this.props.defaultLanguage].cancel}
            positiveButtonText={language[this.props.defaultLanguage].submit}
            isPositiveSubmitting={this.state.isUpdating}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showBlockModal")}
          show={this.state.showBlockModal}
          title={language[this.props.defaultLanguage].block}
        >
          <ConfirmationModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showBlockModal"
            )}
            error={this.state.error}
            onPressNegative={this.handleCloseModal.bind(
              this,
              "showSuspendModal"
            )}
            onPressPositive={() =>
              this.onUpdateStatus("blocked", "showBlockModal")
            }
            onChange={(e) => this.onChangeText("reason", e)}
            isSubmitting={this.state.isSubmitting}
            getData={this.getData.bind(this)}
            withReason
            reason={this.state.reason}
            reasonFieldName="reason"
            negativeButtonText={language[this.props.defaultLanguage].cancel}
            positiveButtonText={language[this.props.defaultLanguage].submit}
            isPositiveSubmitting={this.state.isUpdating}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>

        <CSVLink
          ref="csvDownload"
          filename={
            "Blood Donations" +
            this.state.filters.startDate +
            " - " +
            this.state.filters.endDate
          }
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodCollection);
