import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import language from "../../language";
import bloodDonationQuery from "../../utils/queries/bloodDonationQuery";
import icons from "../../constants/icons";
import { Button } from "../Button";
import BarcodeScannerInput from "../Barcode/BarcodeScannerInput";
import toastMessage from "../../utils/toastMessage";

class SearchDonation extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    moreSearchOptions: false,
    donation: "",
  };

  validateForm() {
    let { error, donation } = this.state;

    if (donation === "") {
      error.donation =
        language[this.props.defaultLanguage].donation_number_required;
    }

    this.setState({ error });
  }

  handleDonationSearch = async (isSubmitting) => {
    await this.validateForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { donation } = this.state;

      this.setState({
        isSubmitting,
        isDoneSearching: false,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        type: "dropdown",
        donationNumber: donation?.replace(/\s+/g, ""),
      });

      this.setState({
        isSubmitting: false,
        isDoneSearching: true,
      });

      if (data.length === 1) {
        return this.handleNextAction(data[0]);
      } else {
        toastMessage("error", "Donation couldn't find");

        return;
      }

      this.setState({
        data: data,
      });
    } catch (error) {
      this.setState({ isSubmitting: false, isDoneSearching: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isDoneSearching: false,
    });
  };

  handleNextAction(item) {
    if (this.props.navigationUrl) {
      window.location.href = `${this.props.navigationUrl}/${item._id}/${item.donor._id}/${item.donationNumber}`;
      return;
    }
    if (this.props.navigateToDonor) {
      window.location.href = `${this.props.navigateToDonor}/${item.donor._id}/${
        item.donor.firstName + " " + item.donor.lastName
      }`;
      return;
    }
    if (this.props.handleNext) {
      this.props.handleCloseModal();
      this.props.handleNext(item);
      return;
    }
    window.location.href = `/dashboard/testing/${item._id}/${item.donationNumber}`;
  }

  handleScan = (scannedBarcode) => {
    console.log("scannedBarcode", scannedBarcode);
    this.setState({ donation: scannedBarcode }, () => {
      this.handleDonationSearch(true);
    });
  };

  render() {
    return (
      <div>
        <div className="card-body">
          {this.state.error.errorMessage && (
            <div className="alert alert-danger">
              <span>{this.state.error.errorMessage}</span>
            </div>
          )}
          <div className="row">
            <form>
              <div className="col-md-12">
                <Input
                  placeholder={
                    language[this.props.defaultLanguage]
                      .donation_number_placeholder
                  }
                  label={language[this.props.defaultLanguage].donation_number}
                  value={this.state.donation}
                  onChange={(e) => this.onChangeText("donation", e)}
                  error={this.state.error.donation}
                  leftIcon={icons.user}
                />
                <Button
                  text={language[this.props.defaultLanguage].search}
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.handleDonationSearch.bind(this)}
                />
              </div>
            </form>
          </div>
        </div>
        <BarcodeScannerInput onScan={(txt) => this.handleScan(txt)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SearchDonation);
