export default [
  { label: "Afghan", value: "afghan" },
  { label: "Albanian", value: "albanian" },
  { label: "Algerian", value: "algerian" },
  { label: "American", value: "american" },
  { label: "Andorran", value: "andorran" },
  { label: "Angolan", value: "angolan" },
  { label: "Antiguan and Barbudan", value: "antiguan_and_barbudan" },
  { label: "Argentine", value: "argentine" },
  { label: "Armenian", value: "armenian" },
  { label: "Australian", value: "australian" },
  { label: "Austrian", value: "austrian" },
  { label: "Azerbaijani", value: "azerbaijani" },
  { label: "Bahamian", value: "bahamian" },
  { label: "Bahraini", value: "bahraini" },
  { label: "Bangladeshi", value: "bangladeshi" },
  { label: "Barbadian", value: "barbadian" },
  { label: "Belarusian", value: "belarusian" },
  { label: "Belgian", value: "belgian" },
  { label: "Belizean", value: "belizean" },
  { label: "Beninese", value: "beninese" },
  { label: "Bhutanese", value: "bhutanese" },
  { label: "Bolivian", value: "bolivian" },
  { label: "Bosnian", value: "bosnian" },
  { label: "Botswana", value: "botswana" },
  { label: "Brazilian", value: "brazilian" },
  { label: "British", value: "british" },
  { label: "Bruneian", value: "bruneian" },
  { label: "Bulgarian", value: "bulgarian" },
  { label: "Burkinabe", value: "burkinabe" },
  { label: "Burmese", value: "burmese" },
  { label: "Burundian", value: "burundian" },
  { label: "Cambodian", value: "cambodian" },
  { label: "Cameroonian", value: "cameroonian" },
  { label: "Canadian", value: "canadian" },
  { label: "Cape Verdean", value: "cape_verdean" },
  { label: "Central African", value: "central_african" },
  { label: "Chadian", value: "chadian" },
  { label: "Chilean", value: "chilean" },
  { label: "Chinese", value: "chinese" },
  { label: "Colombian", value: "colombian" },
  { label: "Comorian", value: "comorian" },
  {
    label: "Congolese (Congo-Brazzaville)",
    value: "congolese_congo_brazzaville",
  },
  { label: "Congolese (Congo-Kinshasa)", value: "congolese_congo_kinshasa" },
  { label: "Costa Rican", value: "costa_rican" },
  { label: "Croatian", value: "croatian" },
  { label: "Cuban", value: "cuban" },
  { label: "Cypriot", value: "cypriot" },
  { label: "Czech", value: "czech" },
  { label: "Danish", value: "danish" },
  { label: "Djiboutian", value: "djiboutian" },
  { label: "Dominican", value: "dominican" },
  { label: "Dominican (Dominican Republic)", value: "dominican_republic" },
  { label: "Dutch", value: "dutch" },
  { label: "East Timorese", value: "east_timorese" },
  { label: "Ecuadorian", value: "ecuadorian" },
  { label: "Egyptian", value: "egyptian" },
  { label: "Emirati", value: "emirati" },
  { label: "Equatorial Guinean", value: "equatorial_guinean" },
  { label: "Eritrean", value: "eritrean" },
  { label: "Estonian", value: "estonian" },
  { label: "Ethiopian", value: "ethiopian" },
  { label: "Fijian", value: "fijian" },
  { label: "Finnish", value: "finnish" },
  { label: "French", value: "french" },
  { label: "Gabonese", value: "gabonese" },
  { label: "Gambian", value: "gambian" },
  { label: "Georgian", value: "georgian" },
  { label: "German", value: "german" },
  { label: "Ghanaian", value: "ghanaian" },
  { label: "Greek", value: "greek" },
  { label: "Grenadian", value: "grenadian" },
  { label: "Guatemalan", value: "guatemalan" },
  { label: "Guinean", value: "guinean" },
  { label: "Guinea-Bissauan", value: "guinea_bissauan" },
  { label: "Guyanese", value: "guyanese" },
  { label: "Haitian", value: "haitian" },
  { label: "Honduran", value: "honduran" },
  { label: "Hungarian", value: "hungarian" },
  { label: "Icelandic", value: "icelandic" },
  { label: "Indian", value: "indian" },
  { label: "Indonesian", value: "indonesian" },
  { label: "Iranian", value: "iranian" },
  { label: "Iraqi", value: "iraqi" },
  { label: "Irish", value: "irish" },
  { label: "Israeli", value: "israeli" },
  { label: "Italian", value: "italian" },
  { label: "Ivorian", value: "ivorian" },
  { label: "Jamaican", value: "jamaican" },
  { label: "Japanese", value: "japanese" },
  { label: "Jordanian", value: "jordanian" },
  { label: "Kazakh", value: "kazakh" },
  { label: "Kenyan", value: "kenyan" },
  { label: "Kiribati", value: "kiribati" },
  { label: "Korean (North)", value: "north_korean" },
  { label: "Korean (South)", value: "south_korean" },
  { label: "Kosovar", value: "kosovar" },
  { label: "Kuwaiti", value: "kuwaiti" },
  { label: "Kyrgyz", value: "kyrgyz" },
  { label: "Lao", value: "lao" },
  { label: "Latvian", value: "latvian" },
  { label: "Lebanese", value: "lebanese" },
  { label: "Liberian", value: "liberian" },
  { label: "Libyan", value: "libyan" },
  { label: "Liechtenstein", value: "liechtenstein" },
  { label: "Lithuanian", value: "lithuanian" },
  { label: "Luxembourgish", value: "luxembourgish" },
  { label: "Malagasy", value: "malagasy" },
  { label: "Malawian", value: "malawian" },
  { label: "Malaysian", value: "malaysian" },
  { label: "Maldivian", value: "maldivian" },
  { label: "Malian", value: "malian" },
  { label: "Maltese", value: "maltese" },
  { label: "Marshallese", value: "marshallese" },
  { label: "Mauritanian", value: "mauritanian" },
  { label: "Mauritian", value: "mauritian" },
  { label: "Mexican", value: "mexican" },
  { label: "Micronesian", value: "micronesian" },
  { label: "Moldovan", value: "moldovan" },
  { label: "Monacan", value: "monacan" },
  { label: "Mongolian", value: "mongolian" },
  { label: "Montenegrin", value: "montenegrin" },
  { label: "Moroccan", value: "moroccan" },
  { label: "Mozambican", value: "mozambican" },
  { label: "Namibian", value: "namibian" },
  { label: "Nauruan", value: "nauruan" },
  { label: "Nepalese", value: "nepalese" },
  { label: "New Zealander", value: "new_zealander" },
  { label: "Nicaraguan", value: "nicaraguan" },
  { label: "Nigerien", value: "nigerien" },
  { label: "Nigerian", value: "nigerian" },
  { label: "North Macedonian", value: "north_macedonian" },
  { label: "Norwegian", value: "norwegian" },
  { label: "Omani", value: "omani" },
  { label: "Pakistani", value: "pakistani" },
  { label: "Palauan", value: "palauan" },
  { label: "Palestinian", value: "palestinian" },
  { label: "Panamanian", value: "panamanian" },
  { label: "Papua New Guinean", value: "papua_new_guinean" },
  { label: "Paraguayan", value: "paraguayan" },
  { label: "Peruvian", value: "peruvian" },
  { label: "Philippine", value: "philippine" },
  { label: "Polish", value: "polish" },
  { label: "Portuguese", value: "portuguese" },
  { label: "Qatari", value: "qatari" },
  { label: "Romanian", value: "romanian" },
  { label: "Russian", value: "russian" },
  { label: "Rwandan", value: "rwandan" },
  { label: "Saint Kitts and Nevis", value: "saint_kitts_and_nevis" },
  { label: "Saint Lucian", value: "saint_lucian" },
  { label: "Saint Vincentian", value: "saint_vincentian" },
  { label: "Samoan", value: "samoan" },
  { label: "San Marinese", value: "san_marinese" },
  { label: "Sao Tomean", value: "sao_tomean" },
  { label: "Saudi Arabian", value: "saudi_arabian" },
  { label: "Senegalese", value: "senegalese" },
  { label: "Serbian", value: "serbian" },
  { label: "Seychellois", value: "seychellois" },
  { label: "Sierra Leonean", value: "sierra_leonean" },
  { label: "Singaporean", value: "singaporean" },
  { label: "Slovak", value: "slovak" },
  { label: "Slovenian", value: "slovenian" },
  { label: "Solomon Islander", value: "solomon_islander" },
  { label: "Somali", value: "somali" },
  { label: "South African", value: "south_african" },
  { label: "South Sudanese", value: "south_sudanese" },
  { label: "Spanish", value: "spanish" },
  { label: "Sri Lankan", value: "sri_lankan" },
  { label: "Sudanese", value: "sudanese" },
  { label: "Surinamese", value: "surinamese" },
  { label: "Swazi", value: "swazi" },
  { label: "Swedish", value: "swedish" },
  { label: "Swiss", value: "swiss" },
  { label: "Syrian", value: "syrian" },
  { label: "Tajik", value: "tajik" },
  { label: "Tanzanian", value: "tanzanian" },
  { label: "Thai", value: "thai" },
  { label: "Togolese", value: "togolese" },
  { label: "Tongan", value: "tongan" },
  { label: "Trinidadian or Tobagonian", value: "trinidadian_or_tobagonian" },
  { label: "Tunisian", value: "tunisian" },
  { label: "Turkish", value: "turkish" },
  { label: "Turkmen", value: "turkmen" },
  { label: "Tuvaluan", value: "tuvaluan" },
  { label: "Ugandan", value: "ugandan" },
  { label: "Ukrainian", value: "ukrainian" },
  { label: "Uruguayan", value: "uruguayan" },
  { label: "Uzbek", value: "uzbek" },
  { label: "Vanuatuan", value: "vanuatuan" },
  { label: "Vatican", value: "vatican" },
  { label: "Venezuelan", value: "venezuelan" },
  { label: "Vietnamese", value: "vietnamese" },
  { label: "Yemeni", value: "yemeni" },
  { label: "Zambian", value: "zambian" },
  { label: "Zimbabwean", value: "zimbabwean" },
];
