import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage, setStorage } from "../../utils/storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class CompleteAccountRegistration extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    departments: [],
    jobPositions: [],
    pin: "",
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "department") {
      this.setState({ jobPosition: null });
      this.getJobPositions(true, inputValue.value);
    }

    if (field === "center") {
      this.getCenterSites(
        true,
        inputValue.map((el) => el.value)
      );
    }

    if (field === "hospital" && inputValue?.bloodBank) {
      this.setState({
        stock: {
          ...inputValue.bloodBank,
          label: inputValue.bloodBank.name,
          value: inputValue.bloodBank._id,
        },
      });
    }
  };

  validateForm() {
    let { error, pin } = this.state;

    if (pin === "") {
      error.pin = language[this.props.defaultLanguage].pin_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { pin, employeeId } = this.state,
        url = `${API_URL}/account`,
        method = "PUT",
        user = await getStorage();

      let requestedBody = {
        employeeId,
        pin: pin,
        passcode: pin,
        id: user.id,
      };

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then(async (data) => {
          this.setState({
            isSubmitting: false,
          });

          user.pin = pin;
          await setStorage(user);

          toastMessage(
            "success",
            language[this.props.defaultLanguage].update_account_success
          );
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].pin_placeholder
                }
                label={language[this.props.defaultLanguage].pin}
                required
                value={this.state.pin}
                onChange={(e) => this.onChangeText("pin", e)}
                error={this.state.error.pin}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].employee_id_placeholder
                }
                label={language[this.props.defaultLanguage].employee_id}
                value={this.state.employeeId}
                onChange={(e) => this.onChangeText("employeeId", e)}
                error={this.state.error.employeeId}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CompleteAccountRegistration);
