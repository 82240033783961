import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import screened from "../../assets/screened-colored.png";
import giveBlood from "../../assets/giveBlood.png";
import register from "../../assets/register.png";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class ResetPassword extends React.Component {
  state = {
    newPassword: "",
    confirmPassword: "",
    isSubmitting: false,
    error: {},
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e?.target?.value });
  };

  validateForm = () => {
    let { error, newPassword, confirmPassword } = this.state;

    if (newPassword === "") {
      error.newPassword =
        language[this.props.defaultLanguage].new_password_required;
    }

    if (confirmPassword === "") {
      error.confirmPassword =
        language[this.props.defaultLanguage].confirm_password_required;
    } else if (confirmPassword !== newPassword) {
      error.confirmPassword =
        language[this.props.defaultLanguage].different_password_required;
    }

    this.setState({ error });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    await this.validateForm();

    console.log(this.state.error);

    if (Object.keys(this.state.error).length === 0) {
      const user = await getStorage();

      this.setState({ isSubmitting: true });

      const { newPassword } = this.state;
      const { token, type } = this.props;

      const options = {
        method: "POST",
        url: `${API_URL}/${type}/resetpassword`,
        data: {
          password: newPassword,
          token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          this.onSuccess(data.data);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage("error", error);
        });
    }
  };

  onSuccess = async () => {
    window.location.href = "/login";
  };

  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides
                data={[
                  {
                    title: "Blood Donation Tracking",
                    description:
                      "Simplifying the donation process, providing a clear and concise overview for effective monitoring.",
                    image: giveBlood,
                  },
                  {
                    title: "Donation Analytics",
                    description:
                      "Comprehensive analytics on donation contribution and usage trends per blood centers and respective sites.",
                    image: register,
                  },
                  {
                    title: "Blood Testing & Eligibility",
                    description:
                      "Personalized feedback on blood testing results and eligibility. Streamline donation process with quality monitoring.",
                    image: screened,
                  },
                ]}
                viewSize={1}
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              <div className="title">
                <h3 className="text-primary">
                  {language[this.props.defaultLanguage].reset_password_title}
                </h3>
              </div>
              <form>
                <Input
                  label={language[this.props.defaultLanguage].new_password}
                  placeholder={
                    language[this.props.defaultLanguage]
                      .new_password_placeholder
                  }
                  type="password"
                  required
                  leftIcon="lock"
                  value={this.state.newPassword}
                  error={this.state.error.newPassword}
                  onChange={(e) => this.onChangeText("newPassword", e)}
                />
                <Input
                  label={language[this.props.defaultLanguage].confirm_password}
                  placeholder={
                    language[this.props.defaultLanguage]
                      .confirm_password_placeholder
                  }
                  type="password"
                  required
                  leftIcon="lock"
                  value={this.state.confirmPassword}
                  error={this.state.error.confirmPassword}
                  onChange={(e) => this.onChangeText("confirmPassword", e)}
                />
                <Button
                  text={language[this.props.defaultLanguage].reset_password}
                  className="w-100"
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.onSubmit.bind(this)}
                />
                <br />
                <div className="footer">
                  <span>
                    <Link to="/forgot-password">
                      {language[this.props.defaultLanguage].back_text}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ResetPassword);
