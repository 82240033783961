import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import language from "../../language";
import requestQuery from "../../utils/queries/requestQuery.js";
import icons from "../../constants/icons";
import { Button } from "../Button";
import toastMessage from "../../utils/toastMessage";
import ModalContainer from "../Modal/Modal";
import { fetchRequestedProducts } from "../../utils/queries/request-product.js";
import Loading from "../Loader/Loading.js";
import Table from "../Table/Table.js";
import { fetchShipmentNumber } from "../../utils/queries/request/shipping-queue.js";
import { generateShipmentNumber } from "../../utils/random.js";
import CardHeader from "../Header/CardHeader.js";
import Shipping from "./shipping/index.js";
import Empty from "../Empty/Empty.js";
import { getStorage } from "../../utils/storage.js";
import RespondRequest from "./requestForm/respondRequest.js";
class SearchRequest extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    requestNo: "",
    productRequested: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  validateForm() {
    let { error, requestNo } = this.state;

    if (requestNo === "") {
      error.requestNo =
        language[this.props.defaultLanguage].request_number_required;
    }

    this.setState({ error });
  }

  handleSearch = async () => {
    await this.validateForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { requestNo } = this.state;

      this.setState({
        isSubmitting: true,
      });

      const { data } = await requestQuery(this.props.defaultLanguage, {
        requestNo: requestNo?.replace(/\s+/g, ""),
      });

      let requestInfo = {};

      if (data.length === 1) {
        requestInfo = data[0];
        console.log("checking productType", requestInfo);
        if (requestInfo.type === "nominative") {
          window.location.href = `/dashboard/request/collect_sample/${requestInfo.requestNo}/${requestInfo._id}`;
        } else {
          this.handleShowModal("showRequestInfoModal", requestInfo.requestNo);
        }
        this.getShipmentNumber(true);
      } else {
        toastMessage("error", "Request info couldn't find");
      }
      this.setState({
        requestInfo,
        isSubmitting: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isSubmitting: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue?.toUpperCase(),
      isDoneSearching: false,
    });
  };

  handleShowModal(modal, title, item = {}) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      ...item,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getShipmentNumber = async (isLoadingShipmentNumber) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingShipmentNumber });

      const data = await fetchShipmentNumber(defaultLanguage);

      this.setState({
        shipmentNumber: generateShipmentNumber(data.data),
        isLoadingShipmentNumber: false,
        isSubmitting: false,
        isLoadingShipmentNumber: false,
      });
    } catch (error) {
      this.setState({
        isLoadingShipmentNumber: false,
        isSubmitting: false,
        isLoadingShipmentNumber: false,
      });

      toastMessage("error", error);
    }
  };

  render() {
    console.log(this.state.productRequested);
    return (
      <>
        <div className="card-body">
          {this.state.error.errorMessage && (
            <div className="alert alert-danger">
              <span>{this.state.error.errorMessage}</span>
            </div>
          )}
          <Input
            placeholder={
              language[this.props.defaultLanguage].request_number_placeholder
            }
            label={language[this.props.defaultLanguage].request_number}
            value={this.state.requestNo}
            onChange={(e) => this.onChangeText("requestNo", e)}
            error={this.state.error.requestNo}
            leftIcon={icons.request}
          />

          {this.props.handleCloseModal && (
            <Button
              className="btn-default"
              text={language[this.props.defaultLanguage].cancel}
              onPress={this.props.handleCloseModal}
            />
          )}
          <Button
            text={language[this.props.defaultLanguage].search}
            isSubmitting={this.state.isSubmitting}
            onPress={this.handleSearch.bind(this)}
          />
        </div>

        <ModalContainer
          title={this.state.modalTitle}
          show={this.state.showRequestInfoModal}
          handleClose={() => this.handleCloseModal("showRequestInfoModal")}
          size="lg"
        >
          <div>
            {this.state.isLoadingProductRequested ? (
              <center>
                <Loading />
              </center>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  {/* <Table
                    data={this.state.productRequested}
                    headers={[
                      {
                        title:
                          language[this.props.defaultLanguage].product_type,
                        key: "productType.name",
                        styles: {
                          textTransform: "uppercase",
                        },
                      },
                      {
                        title: language[this.props.defaultLanguage].blood_group,
                        key: "bloodGroup",
                      },
                      {
                        title:
                          language[this.props.defaultLanguage]
                            .number_of_packages,
                        key: "numberOfPackages",
                      },
                    ]}
                  /> */}
                  <RespondRequest
                    requestInfo={this.state.requestInfo}
                    requestId={this.state?.requestInfo?._id}
                    requestNo={this.state?.requestInfo?.requestNo}
                    source={this.state?.requestInfo?.bloodBank?._id}
                    bloodBank={this.state?.requestInfo?.hospital?.bloodBank}
                  />
                </div>
                {/* {this.state?.requestInfo?.bloodBank?._id ===
                  this.state.user.stock && (
                  <div className="col-md-12">
                    <CardHeader
                      title={language[this.props.defaultLanguage].preparation}
                    />
                    <div>
                      <Shipping
                        requestNo={this.state?.requestInfo?.requestNo}
                        source={this.state?.requestInfo?.bloodBank?._id}
                        bloodBank={this.state?.requestInfo?.hospital?.bloodBank}
                      />
                    </div>
                  </div>
                )} */}
              </div>
            )}
          </div>
        </ModalContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SearchRequest);
