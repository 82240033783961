import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import AgeGroup from "../../../components/Reports/donors/AgeGroup";

class DonorReportsScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <div style={{ marginBottom: 16 }}>
              <div style={{ flex: 1 }}>
                <DashboardHeader
                  title={
                    language[this.props.defaultLanguage].donor +
                    " " +
                    language[this.props.defaultLanguage].reports
                  }
                />
              </div>
            </div>

            <Tabs
              options={[
                {
                  title: "Donors Age Group",
                  data: <AgeGroup />,
                },
                {
                  title: "New Donors Age Group",
                  data: <AgeGroup category="newDonor" />,
                },
                {
                  title: "Repeat Donors Age Group",
                  data: <AgeGroup category="repeatDonor" />,
                },
                {
                  title: "Donors with No Document",
                  data: <AgeGroup identityDocNumberType="noDocument" />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(DonorReportsScreen));
