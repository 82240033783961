import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
import language from "../../../language";
import InventoriesByStatus from "../../../components/inventory/ByStatus";
import capitalizeText from "../../../utils/capitalizeText";
import { Breadcrumb } from "../../../components/Breadcrumb";

class InventoriesByStatusScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/component%20production"
              data={[
                {
                  name: language[this.props.defaultLanguage]
                    .component_production,
                  url: "/dashboard/component production",
                },
                {
                  name: capitalizeText(this.props?.routeParams?.status),
                  active: true,
                },
              ]}
            />
            <InventoriesByStatus {...this.props?.routeParams} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(InventoriesByStatusScreen));
