import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import accountQuery from "../../utils/queries/accountQuery";
import hospitalQuery from "../../utils/queries/hospitalQuery";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;
class NewDepartment extends React.Component {
  state = {
    name: "",
    error: {},
    isSubmitting: false,
    accounts: [],
  };

  componentDidMount = async () => {
    await this.getAccounts(true);

    if (this.props._id) {
      this.setState({
        ...this.props,
        hospital: this.props.hospital
          ? {
              label: this.props.hospital.name,
              value: this.props.hospital._id,
            }
          : null,
        manager: this.props.manager
          ? {
              label: this.props.manager.pin,
              value: this.props.manager._id,
            }
          : null,
      });
    }
  };

  getAccounts = async (isFetchingAccount) => {
    try {
      this.setState({
        isFetchingAccount,
      });

      const data = await accountQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 10,
      });

      this.setState({
        isFetchingAccount: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingAccount: false });
    }
  };

  // getHospitals = async (isFetchingHospitals, search = {}) => {
  //   try {
  //     this.setState({
  //       isFetchingHospitals,
  //     });

  //     const data = await hospitalQuery(this.props.defaultLanguage, {
  //       type: "dropdown",
  //       page: 0,
  //       limit: 50,
  //       ...search,
  //     });

  //     this.setState({
  //       isFetchingHospitals: false,
  //       page: 1,
  //       limit: 50,
  //     });

  //     return data;
  //   } catch (error) {
  //     this.setState({ isFetchingHospitals: false });
  //   }
  // };
  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });
  }

  validateForm() {
    let { name, error, hospital } = this.state;

    // if (hospital === "") {
    //   error.hospital = language[this.props.defaultLanguage].name_required;
    // }

    if (name === "") {
      error.name =
        language[this.props.defaultLanguage].department_name_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      error_add_department,
      error_update_department,
      add_department_success,
      update_department_success,
    } = language[this.props.defaultLanguage];

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { name, _id, account, hospital } = this.state,
        url = `${API_URL}/department`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        name,
        // hospital: hospital.value,
        manager: account?.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? update_department_success
              : add_department_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage(
            "error",
            method === "PUT" ? error_update_department : error_add_department
          );

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .department_name_placeholder
                }
                label={language[this.props.defaultLanguage].department_name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            {/* <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getHospitals(true, { q: e })}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].entity_or_hospital}
                value={this.state.hospital}
                onChange={(e) => this.onChangeText("hospital", e)}
                error={this.state.error.hospital}
                isLoading={this.state.isFetchingHospitals}
              />
            </div> */}
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getAccounts(true, { q: e })}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].manager}
                value={this.state.account}
                onChange={(e) => this.onChangeText("account", e)}
                error={this.state.error.account}
                isLoading={this.state.isFetchingAccount}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewDepartment);
