import React from "react";
import { connect } from "react-redux";
import { fetchInventories } from "../../../utils/queries/production/inventoryQuery";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import moment from "moment";
import { fetchAllComponents } from "../../../utils/queries/production/components";
import CardCount from "../../CardCount/CardCount";
import { generateColor } from "../../../utils/color";
import { BloodGroupFilter, CentersFilter, DateFilter } from "../../Filters";
import handleFilters from "../../../utils/handleFilters";
import { Loading } from "../../Loader";
import { Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";

let copyComponents = [];

class InventoriesByStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      page: 1,
      limit: 10,
      search_text: "",
      selectedData: {},
      delete_value: "",
      error: {},
      csvData: [],
      user: {},
      filters: {
        startDate:
          props.startDate || moment().startOf("D").format("YYYY-MM-DD "),
        endDate: props.endDate || moment().endOf("D").format("YYYY-MM-DD"),
      },
      counts: {},
      components: [],
    };
  }

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.fetchData(true, this.state.filters);
  };

  getComponents = async (search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading: true });

      const { data } = await fetchAllComponents(defaultLanguage, {
        page: 1,
        limit: 100,
        id: search.component,
      });

      this.setState({
        components: data,
        isLoading: false,
      });

      copyComponents = data.slice(0);

      const fetchPromises = data.map((el) => {
        return this.getCounts(
          true,
          {
            component: el._id,
            isCount: true,
            ...search,
          },
          el.name
        );
      });

      // Wait for all promises to resolve
      const results = await Promise.all(fetchPromises);

      let { counts } = this.state;

      if (results.length > 0) {
        counts = results[0];
      }

      this.setState({ counts });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchData(isLoading, search = {}) {
    // this.getData(isLoading, search);
    this.getComponents(search);
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });
  }

  returnFilters = async (search) => {
    const { page, limit } = this.state;
    const { status } = this.props;

    return {
      page,
      limit,
      donor: this.props.donorId,
      donation: this.props.donationId,
      id: this.props.inventoryId,
      ...handleFilters(search),
      ...search,
      status,
    };
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const filters = await this.returnFilters(search);

      if (search?.donationNumber) {
        const donation = await bloodDonationQuery(defaultLanguage, {
          ...search,
        });

        if (donation?.length > 0) {
          filters.donation = donation[0]?._id || undefined;
        } else {
          return;
        }
      }

      const { data, count } = await fetchInventories(defaultLanguage, filters);

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCounts = async (isLoading, search = {}, type) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const filters = await this.returnFilters(search);

      if (search?.donationNumber) {
        const donation = await bloodDonationQuery(defaultLanguage, {
          ...search,
          isCount: undefined,
        });

        if (donation?.length > 0) {
          filters.donation = donation[0]?._id || undefined;
        } else {
          return;
        }
      }

      const { data } = await fetchInventories(defaultLanguage, filters);
      let { counts } = this.state;

      counts[type] = data;

      this.setState({
        // counts,
        isLoading: false,
      });

      return counts;
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true, this.state.filters);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleFilterComponent(e) {
    let components = copyComponents.filter((el) => el._id === e.value);

    this.setState({ component: e, components, isLoading: true }, async () => {
      const counts = await this.getCounts(
        true,
        {
          component: e.value,
          isCount: true,
        },
        e.label
      );

      this.setState({ counts });
    });
  }

  handleReset() {
    this.fetchData(true, this.state.filters);
    this.setState({
      component: null,
      components: copyComponents,
      isLoading: true,
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <CentersFilter
                handleApplyFilters={(isLoading, filters) =>
                  this.fetchData(isLoading, filters)
                }
              />

              <BloodGroupFilter
                handleApplyFilters={(isLoading, filters) =>
                  this.fetchData(isLoading, filters)
                }
              />
              <DateFilter
                handleApplyFilters={(isLoading, filters) =>
                  this.fetchData(isLoading, filters)
                }
                startDate={this.state.filters.startDate}
                endDate={this.state.filters.endDate}
              />
            </div>
          </div>

          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            <>
              <div className="d-flex gap-3" style={{ alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <Select
                    options={copyComponents.map((el) => {
                      return {
                        label: el.name,
                        value: el._id,
                      };
                    })}
                    onChange={(e) => this.handleFilterComponent(e)}
                    value={this.state.component}
                  />
                </div>

                <div>
                  <Button
                    text={language[this.props.defaultLanguage].reset_filters}
                    onPress={() => {
                      this.handleReset();
                    }}
                  />
                </div>
              </div>
              {this.state.components.map((el, i) => {
                return (
                  <div className="col-md-3 mb-3" key={i}>
                    <CardCount
                      count={this.state.counts[el.name]}
                      title={el.name}
                      color={generateColor(i)}
                      icon="schedule"
                      isLoading={this.state.isLoadingCounts}
                      onPress={() =>
                        (window.location.href = `/dashboard/production/inventory/byStatus/${this.state.filters.startDate}/${this.state.filters.endDate}/${this.props.status}/${el.name}/${el._id}`)
                      }
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(InventoriesByStatus);
