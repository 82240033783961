import React from "react";
import { connect } from "react-redux";
import { Checkbox, Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import availableOptions from "../../constants/availableOptions";
import donorsQuery from "../../utils/queries/donorsQuery";
import { fetchEquipments } from "../../utils/queries/equipmentQuery";
import { fetchPreAssessmentInformation } from "../../utils/queries/preAssessmentQuery";
import { fetchMeasureData } from "../../utils/queries/measureQuery";
import moment from "moment";
import { Separator } from "../Separator";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const questionFormat = {
  value: "",
  measure: null,
  error: {},
};

class NewSign extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    available: availableOptions[0],
    donors: [],
    examQuestionnaire: [],
    equipments: [],
    isEligible: false,
    reasonIneligible: "",
    ineligible: false,
    appointments: [],
    measures: [],
  };

  componentDidMount = async () => {
    this.getMeasures(true);

    if (!this.props.donorId) {
      this.getDonors(true);
    }

    this.getEquipments(true);

    if (this.props._id && this.props._id !== "") {
      const examQuestionnaire = this.props.examQuestionnaire.map((el) => {
        let updatedEl = { ...el };

        if (updatedEl.measure) {
          updatedEl.measure = updatedEl.measure._id;
        }

        if (updatedEl.equipment) {
          updatedEl.equipment = {
            label: updatedEl.equipment.name,
            value: updatedEl.equipment._id,
          };
        }

        updatedEl.error = {};

        return updatedEl;
      });

      const commonParamsProps = {
        donor: {
          label: this.props?.donor?.donorNumber,
          value: this.props?.donor?._id,
        },
        measure: {
          label: this.props?.measure?.code,
          value: this.props?.measure?._id,
        },
        isEligible: this.props.eligible.isEligible,
        reasonIneligible: this.props.eligible.reason,
        examQuestionnaire,
        ineligible: !this.props.eligible.isEligible,
      };

      if (this.props.preDonationAssessment) {
        commonParamsProps.appointment = {
          label: moment(this.props.preDonationAssessment.requestedDate).format(
            "lll"
          ),
          value: this.props.preDonationAssessment._id,
        };
      }

      if (this.props.eligible.isEligible) {
        commonParamsProps.isEligible = true;
        commonParamsProps.ineligible = false;
      } else {
        commonParamsProps.isEligible = false;
        commonParamsProps.ineligible = true;
      }

      this.setState({ ...commonParamsProps });
    }
  };

  getMeasures = async (isFetchingMeasures, search) => {
    try {
      this.setState({
        isFetchingMeasures,
      });

      const data = await fetchMeasureData(this.props.defaultLanguage, {
        type: "dropdown",
        code: search,
      });

      this.setState({
        measures: data,
        isFetchingMeasures: false,
      });
    } catch (error) {
      this.setState({ isFetchingMeasures: false });
    }
  };

  getDonors = async (isFetchingDonor) => {
    try {
      const { centerId, siteId } = this.props;

      this.setState({
        isFetchingDonor,
      });

      const data = await donorsQuery(this.props.defaultLanguage, {
        type: "dropdown",
        center: centerId,
        centerSite: siteId,
      });

      this.setDonor(data);

      this.setState({
        donors: data,
        isFetchingDonor: false,
      });
    } catch (error) {
      this.setState({ isFetchingDonor: false });
    }
  };

  setDonor(donors) {
    const { donor } = this.props;
    if (donor) {
      const selectedData = donors.find((el) => el._id === donor._id);

      if (selectedData._id) {
        this.setState({
          center: {
            label: selectedData.firstName + " " + selectedData.lastName,
            value: selectedData._id,
          },
        });
      }
    }
  }

  getEquipments = async (isFetchingEquipment) => {
    try {
      this.setState({
        isFetchingEquipment,
      });

      const data = await fetchEquipments(this.props.defaultLanguage, {
        type: "dropdown",
        department: "donation",
      });

      this.setEquipment(data);

      this.setState({
        equipments: data,
        isFetchingEquipment: false,
      });
    } catch (error) {
      this.setState({ isFetchingEquipment: false });
    }
  };

  setEquipment(equipments) {
    const { equipment } = this.props;
    if (equipment) {
      const selectedData = equipments.find((el) => el._id === equipment._id);

      if (selectedData._id) {
        this.setState({
          equipment: {
            label: selectedData.firstName + " " + selectedData.lastName,
            value: selectedData._id,
          },
        });
      }
    }
  }

  getAppointments = async (donor, appointmentNumber) => {
    try {
      if (!donor) return;
      this.setState({
        isFetchingAppointment: true,
      });

      const data = await fetchPreAssessmentInformation(
        this.props.defaultLanguage,
        {
          type: "dropdown",
          donor,
          status: "approved",
          page: 1,
          limit: 50,
          appointmentNumber,
          isEligible: true,
        }
      );

      console.log(data);

      this.setState({
        appointments: data,
        isFetchingAppointment: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingAppointment: false });

      return [];
    }
  };

  onChangeText(field, e, index) {
    let { error, examQuestionnaire } = this.state;

    let value = e && e?.target ? e?.target?.value : e;

    delete error[field];
    delete error.donor;
    delete error.errorMessage;

    let updatedExamQuestionnaire = [...examQuestionnaire];

    if (index !== undefined) {
      let updatedElement = { ...updatedExamQuestionnaire[index] };
      updatedElement[field] = value;
      updatedExamQuestionnaire[index] = updatedElement;

      this.setState({
        error,
        examQuestionnaire: updatedExamQuestionnaire || [],
      });
    } else {
      this.setState({
        [field]: value,
        error,
      });
    }

    if (field === "donor") {
      this.getAppointments(value.value);
    }

    if (field === "appointment") {
      this.setState({
        vitalSignNumber: e.appointmentNumber,
      });
    }
  }

  handleCheck(field) {
    let { error } = this.state;

    delete error[field];
    delete error["reasonIneligible"];

    this.setState({
      [field]: !this.state[field],
    });

    if (field === "ineligible") {
      this.setState({ isEligible: false });
    }

    if (field === "isEligible") {
      this.setState({ ineligible: false });
    }

    this.setState({ error });
  }

  validateForm() {
    let {
      donor,
      error,
      examQuestionnaire,
      appointment,
      isEligible,
      reasonIneligible,
    } = this.state;
    let { donorId } = this.props;

    if (!donor && !donorId) {
      error.donor = language[this.props.defaultLanguage].donor_required;
      toastMessage(
        "error",
        language[this.props.defaultLanguage].donor_required
      );
    }

    if (examQuestionnaire.length === 0) {
      error["errorMessage"] =
        language[this.props.defaultLanguage].all_fields +
        " " +
        language[this.props.defaultLanguage].are_mandatory;
    }

    examQuestionnaire.map((el) => {
      if (!el.error) {
        el.error = {};
      }
      if (!el.equipment) {
        el.error["equipment"] =
          language[this.props.defaultLanguage].equipment_required;
      }

      if (!el.measure) {
        el.error["measure"] =
          language[this.props.defaultLanguage].measure_required;
      }

      if (el.value === "") {
        el.error["value"] = language[this.props.defaultLanguage].value_required;
      }
    });

    if (!appointment) {
      error["appointment"] =
        language[this.props.defaultLanguage].appointment_required;
    }

    if (!isEligible) {
      error.reasonIneligible =
        language[this.props.defaultLanguage].reason_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          donor,
          examQuestionnaire,
          available,
          isEligible,
          reasonIneligible,
          appointment,
          vitalSignNumber,
        } = this.state,
        url = `${API_URL}/vitalSign`,
        method = "POST",
        user = await getStorage(),
        { donorId, _id } = this.props;

      const modifiedArray = examQuestionnaire.map((el) => {
        if (el?.equipment?.value) {
          el.equipment = el.equipment.value;
        }

        el.measure = el.measure;

        delete el.error;

        return el;
      });

      let requestBody = {
        donor: donorId || donor._id,
        available: available.value,
        isEligible,
        eligible: {
          isEligible,
          reason: reasonIneligible,
        },
        examQuestionnaire: modifiedArray,
        preDonationAssessment: appointment.value,
        vitalSignNumber,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? language[this.props.defaultLanguage].update_vital_sign_success
              : language[this.props.defaultLanguage].add_vital_sign_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  handleRemoveSign(index) {
    let { examQuestionnaire } = this.state;

    examQuestionnaire.splice(index, 1);

    this.setState({ examQuestionnaire });
  }

  handleAddSign() {
    let { examQuestionnaire } = this.state;

    examQuestionnaire.push(questionFormat);

    this.setState({ examQuestionnaire });
  }

  onChangeCheck(field, value) {
    let { examQuestionnaire } = this.state;

    // Spread to make a deep copy of the examQuestionnaire array
    let updatedExamQuestionnaire = [...examQuestionnaire];

    if (updatedExamQuestionnaire.length === 0) {
      console.log("I am here 0");
      updatedExamQuestionnaire.push({
        [field]: value,
      });
    } else {
      console.log(updatedExamQuestionnaire);
      const findIndex = updatedExamQuestionnaire.findIndex(
        (el) => el.measure === value
      );
      if (findIndex !== -1) {
        updatedExamQuestionnaire.splice(findIndex, 1);
      } else {
        updatedExamQuestionnaire.push({
          [field]: value,
        });
      }
    }

    // Update the state with the filtered array
    this.setState({
      examQuestionnaire: updatedExamQuestionnaire,
    });
  }

  renderVMeasures() {
    return this.state.measures.map((el, i) => {
      const m = this.state.examQuestionnaire?.find(
        (exEl) => exEl?.measure === el._id
      );
      return (
        <div key={i}>
          <Checkbox
            label={el.name}
            value={el.measure}
            checked={m}
            onCheck={(e) => this.onChangeCheck("measure", el._id, i)}
          />

          {m &&
            this.state.examQuestionnaire.map((exEl, exIndex) => {
              if (exEl?.measure === el._id) {
                return (
                  <>
                    <div className="row" key={exIndex}>
                      <div className="col-md-6">
                        <Input
                          leftIcon={icons.value}
                          placeholder={
                            language[this.props.defaultLanguage]
                              .value_placeholder
                          }
                          label={language[this.props.defaultLanguage].value}
                          required
                          value={exEl.value}
                          onChange={(e) =>
                            this.onChangeText("value", e, exIndex)
                          }
                          error={el?.error?.value}
                        />
                      </div>
                      <div className="col-md-6">
                        <Select
                          options={this.state.equipments}
                          leftIcon={icons.equipment}
                          placeholder={
                            language[this.props.defaultLanguage].select
                          }
                          label={language[this.props.defaultLanguage].equipment}
                          required
                          value={exEl.equipment}
                          onChange={(e) =>
                            this.onChangeText("equipment", e, exIndex)
                          }
                          error={exEl?.error?.equipment}
                          isLoading={this.state.isFetchingEquipment}
                        />
                      </div>
                    </div>
                    <hr />
                  </>
                );
              }
            })}
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            {!this.props.donorId && (
              <div className="col-md-12">
                <Select
                  options={this.state.donors}
                  leftIcon={icons.donor}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].donor}
                  required
                  value={this.state.donor}
                  onChange={(e) => this.onChangeText("donor", e)}
                  error={this.state.error.donor}
                  isLoading={this.state.isFetchingDonor}
                />
              </div>
            )}
            <div className="col-md-12">
              <Select
                leftIcon={icons.appointment}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].appointment}
                required
                value={this.state.appointment}
                onChange={(e) => this.onChangeText("appointment", e)}
                error={this.state.error.appointment}
                isLoading={this.state.isFetchingAppointment}
                loadOptions={(value) =>
                  this.getAppointments(this.props.donorId, value)
                }
              />
            </div>
            {this.state.vitalSignNumber &&
              this.state.vitalSignNumber !== "" && (
                <div className="col-md-12">
                  <Input
                    leftIcon={icons.appointment}
                    placeholder={
                      language[this.props.defaultLanguage]
                        .vital_sign_number_required
                    }
                    label={
                      language[this.props.defaultLanguage].vital_sign_number
                    }
                    required
                    value={this.state.vitalSignNumber}
                    onChange={(e) => this.onChangeText("vitalSignNumber", e)}
                    error={this.state.error.vitalSignNumber}
                    disabled
                  />
                </div>
              )}
            {this.renderVMeasures()}
            <Separator title={language[this.props.defaultLanguage].decision} />
            <Checkbox
              label={language[this.props.defaultLanguage].eligible}
              onCheck={() => this.handleCheck("isEligible")}
              checked={this.state["isEligible"]}
            />
            <Checkbox
              label={language[this.props.defaultLanguage].ineligible}
              onCheck={() => this.handleCheck("ineligible")}
              checked={this.state["ineligible"]}
            />
          </div>
          {this.state.ineligible && (
            <div className="col-md-12">
              <Input
                placeholder={language[this.props.defaultLanguage].reason}
                required
                value={this.state.reasonIneligible}
                onChange={(e) => this.onChangeText("reasonIneligible", e)}
                error={this.state.error.reasonIneligible}
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewSign);
