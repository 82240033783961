import React, { Component } from "react";
import { Select } from "../../../Input";
import language from "../../../../language";
import { connect } from "react-redux";
import { aboGroups } from "../../../../constants/bloodGroups";
import { Button } from "../../../Button";
import api from "../../../../utils/api";
import toastMessage from "../../../../utils/toastMessage";
import { Loading } from "../../../Loader";
import { defineRole } from "../../../../utils/handleAccessRoles";
import { ROLE_SAMPLE_COLLECTED_RESULT } from "../../../../constants/ROLES";
import { getStorage } from "../../../../utils/storage";

class ABO extends Component {
  state = {
    error: {},
    requiredFields: [],
    data: {},
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.sampleCollected !== this.props.sampleCollected) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      this.setState({ isLoading });

      const { sampleCollected } = this.props;

      let bloodGroup, rhesus, liss, indirectAntiglobulin;

      if (sampleCollected?.bloodGroup) {
        bloodGroup = {
          label: sampleCollected?.bloodGroup,
          value: sampleCollected?.bloodGroup,
        };
      }

      if (sampleCollected?.rhesus) {
        rhesus = {
          label: sampleCollected?.rhesus,
          value: sampleCollected?.rhesus,
        };
      }

      if (sampleCollected?.liss) {
        liss = {
          label: sampleCollected?.liss,
          value: sampleCollected?.liss,
        };
      }

      if (sampleCollected?.indirectAntiglobulin) {
        indirectAntiglobulin = {
          label: sampleCollected?.indirectAntiglobulin,
          value: sampleCollected?.indirectAntiglobulin,
        };
      }

      this.setState({
        isLoading: false,
        indirectAntiglobulin,
        liss,
        rhesus,
        bloodGroup,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      toastMessage("error", error);
    }
  };

  updateBloodGroupField = (field, value, modifier = "") => {
    const { bloodGroup, data } = this.state;

    // Ensure bloodGroup is defined
    if (!bloodGroup) return;

    const updatedBloodGroup = {
      label: bloodGroup.label.replace(/[\+\-]/g, "") + modifier,
      value: bloodGroup.value.replace(/[\+\-]/g, "") + modifier,
    };
    data[field] = value?.value;

    this.setState({ [field]: value, bloodGroup: updatedBloodGroup, data });
  };
  onChangeText = (field, e) => {
    let { error } = this.state;

    let inputValue = e && e?.target ? e.target.value : e;

    if (field === "bloodGroup") {
      this.setState({
        liss: null,
        rhesus: null,
        indirectAntiglobulin: null,
      });
    }

    // Remove field error
    delete error[field];
    this.setState({ [field]: inputValue, error });

    // Determine the modifier based on the field and value
    if (field === "rhesus") {
      const modifier = e?.value === "positive" ? "+" : "";
      this.updateBloodGroupField(field, e, modifier);
    } else if (field === "liss") {
      const modifier = e?.value === "positive" ? "+" : "";
      this.updateBloodGroupField(field, e, modifier);
    } else if (field === "indirectAntiglobulin") {
      const modifier = e?.value === "Du+" ? "+" : "-";
      this.updateBloodGroupField(field, e, modifier);
    }

    this.makeRequiredField(field, e?.value);
  };

  makeRequiredField = (field, value) => {
    if (!value) return;
    if (field === "rhesus" && value === "negative") {
      this.updateRequiredFields("liss");
    }
    if (field === "liss" && value === "negative") {
      this.updateRequiredFields("indirectAntiglobulin");
    }
  };

  updateRequiredFields = (field) => {
    let { requiredFields } = this.state;
    if (!requiredFields.includes(field)) {
      requiredFields.push(field);
    } else {
      requiredFields.splice(requiredFields.indexOf(field), 1);
    }

    this.setState({ requiredFields });
  };

  validateForm = () => {
    const { error, rhesus, bloodGroup, requiredFields } = this.state;

    if (!bloodGroup) {
      error.bloodGroup =
        language[this.props.defaultLanguage].blood_group_required;
    }

    requiredFields?.map((el) => {
      if (!this.state[el]) {
        error[el] = language[this.props.defaultLanguage][`${el}_required`];
      }
    });

    this.setState({ error });
  };

  onSubmit = async () => {
    try {
      await this.validateForm();

      const { error, rhesus, bloodGroup, indirectAntiglobulin, liss } =
        this.state;
      const { requestId, patientId, sampleCollected } = this.props;

      if (Object.keys(error).length > 0) return;

      this.setState({ isSubmitting: true });

      let method = "POST",
        requestBody = {
          request: requestId,
          patient: patientId,
          rhesus: rhesus?.value,
          bloodGroup: bloodGroup?.value,
          indirectAntiglobulin: indirectAntiglobulin?.value,
          liss: liss?.value,
        };

      if (sampleCollected._id) {
        method = "PUT";
        requestBody.id = sampleCollected._id;
      }

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/sample-collected-result`,
        data: requestBody,
      };

      console.log(options);

      await api(options);
      this.setState({
        isSubmitting: false,
      });
      toastMessage(
        "success",
        language[this.props.defaultLanguage][
          method === "PUT" ? "update_data_success" : "add_data_success"
        ]
      );

      this.props.getSampleCollectionData(true);
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };
  render() {
    const optionsSelect = [
      {
        label: "Positive",
        value: "positive",
      },
      {
        label: "Negative",
        value: "negative",
      },
    ];

    const canCreateSampleCollection = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_SAMPLE_COLLECTED_RESULT,
      operation: "read,create,update",
    });

    return (
      <div>
        {this.state.isLoading ? (
          <center>
            <Loading />
          </center>
        ) : (
          <div className="card-body row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <Select
                    options={aboGroups}
                    label={language[this.props.defaultLanguage].blood_group}
                    placeholder={language[this.props.defaultLanguage].select}
                    required
                    value={this.state.bloodGroup}
                    error={this.state.error.bloodGroup}
                    onChange={(e) => this.onChangeText("bloodGroup", e)}
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    options={optionsSelect}
                    label="Rhesus (RHD RD)"
                    placeholder={language[this.props.defaultLanguage].select}
                    required
                    value={this.state.rhesus}
                    error={this.state.error.rhesus}
                    onChange={(e) => this.onChangeText("rhesus", e)}
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    options={optionsSelect}
                    label="Liss"
                    placeholder={language[this.props.defaultLanguage].select}
                    required={this.state.requiredFields.includes("liss")}
                    value={this.state.liss}
                    error={this.state.error.liss}
                    onChange={(e) => this.onChangeText("liss", e)}
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    options={[
                      {
                        label: "Du+",
                        value: "Du+",
                      },
                      {
                        label: "Negative",
                        value: "negative",
                      },
                    ]}
                    label="Indirect Antiglobulin Test - (IAT (Du+/d-)"
                    placeholder={language[this.props.defaultLanguage].select}
                    value={this.state.indirectAntiglobulin}
                    error={this.state.error.indirectAntiglobulin}
                    onChange={(e) =>
                      this.onChangeText("indirectAntiglobulin", e)
                    }
                    required={this.state.requiredFields.includes(
                      "indirectAntiglobulin"
                    )}
                  />
                </div>
              </div>
              {canCreateSampleCollection && (
                <div className="d-flex">
                  <Button
                    text={language[this.props.defaultLanguage].submit}
                    isSubmitting={this.state.isSubmitting}
                    onPress={this.onSubmit.bind(this)}
                  />
                </div>
              )}
            </div>
            {/* <div className="col-md-4">
              <div style={{ padding: "1.5rem" }}>
                <h1 style={{ fontSize: 60, color: "var(--primary-color)" }}>
                  {this.state?.bloodGroup?.value}
                </h1>
              </div>
            </div> */}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ABO);
