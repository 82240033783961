import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class Block extends React.Component {
  state = {
    reason: "",
    error: {},
    isSubmitting: false,
  };

  componentDidMount = async () => {
    if (this.props?.donor?.blockInfo) return;

    this.setState({ reason: this.props?.donor?.blockInfo?.blockReason });
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { error, reason } = this.state;

    if (!reason) {
      error.reason = language[this.props.defaultLanguage].reason_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { _id, reason } = this.state;
      let { donor } = this.props;
      let url = `${API_URL}/donor`;
      let user = await getStorage();
      let { blockInfo } = donor;

      blockInfo.blockReason = reason;
      blockInfo.blocked = true;

      console.log("blockInfo", {
        id: donor._id,
        blockInfo,
      });

      const options = {
        method: "PUT",
        url,
        data: {
          id: donor._id,
          blockInfo,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].update_data_success
          );

          if (this.props.handleCloseModal && this.props.getData) {
            this.props.getData(true);
            this.props.handleCloseModal();

            return;
          }
        })
        .catch((error) => {
          toastMessage(
            "error",
            language[this.props.defaultLanguage].error_update_data
          );

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].reason_placeholder
                }
                label={language[this.props.defaultLanguage].reason}
                required
                value={this.state.reason}
                onChange={(e) => this.onChangeText("reason", e)}
                error={this.state.error.reason}
                textarea
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Block);
