import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import { Empty } from "../../Empty";
import moment from "moment";
import TESTForm from "../TESTForm";
import bloodGroups from "../../../constants/bloodGroups";
import { fetchBloodTestQuestionnaire } from "../../../utils/queries/bloodTest/questionnaireQuery";
import accessMenu from "../../../utils/accessMenu";
import { handleValidateMatching } from "../utils";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const ABOList = [
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },
  {
    label: "AB",
    value: "AB",
  },
  {
    label: "O",
    value: "O",
  },
];

class BloodGrouping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      currentTime: moment().format("lll"),
      donation: {},
      questionnaire: [],
      forms: {},
      error: {},
      disabled: true,
      showForm: true,
      confirmationModal: false,
      activateDonation: true,
      code: "",
      canConfirm: false,
      mismatchInfo: {},
      previousTestResults: props.previousTestResults,
      misMatchFields: [],
    };
  }

  componentDidMount = async () => {
    const {
      canCreateBloodTestBloodGrouping,
      canConfirmBloodTestBloodGrouping,
      canUpdateBloodTestBloodGrouping,
    } = accessMenu();

    this.setState({
      code: "BT00" + (this.state.previousTestResults.length + 1),
    });

    await this.getQuestionnaires(true);

    this.setState({ canConfirm: canConfirmBloodTestBloodGrouping });

    if (
      canCreateBloodTestBloodGrouping ||
      canConfirmBloodTestBloodGrouping ||
      canUpdateBloodTestBloodGrouping
    ) {
      this.setState({
        disabled: false,
      });
    } else {
      return this.setState({
        showForm: false,
        disabled: true,
      });
    }

    if (this.props._id) {
      this.setState({
        ...this.props,
        bloodGroup: this.props.bloodGroup
          ? {
              label: this.props.bloodGroup,
              value: this.props.bloodGroup,
            }
          : null,
      });
      if (this.props.questionnaire.length > 0) {
        const modifiedQuestions = this.props.questionnaire.map((el) => {
          const q = this.props.questionnaire.find(
            (qEl) => qEl.questionText === el.questionText
          );
          if (q) {
            el["answer"] = q.answer;

            // if (el.questionText === "Rhesus (RHD R D)") {
            //   this.formateBloodGroup(
            //     this.props.bloodGroup,
            //     q?.answer?.value || q.answer
            //   );
            // }
          }

          return el;
        });
        this.setState({ questionnaire: modifiedQuestions });
      }
    }
  };

  getQuestionnaires = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data } = await fetchBloodTestQuestionnaire(defaultLanguage, {
        page: 1,
        limit: 100,
        type: "bloodGrouping",
      });

      this.setState({
        questionnaire: data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeQuestionnaire = async ({ value, index }) => {
    let { questionnaire, error } = this.state;

    let question = questionnaire[index];

    let answerValue = value?.target ? value?.target?.value : value;

    questionnaire[index]["answer"] = answerValue;

    delete questionnaire[index]["error"];

    this.setState({
      questionnaire,
    });

    const answerValueValue = answerValue?.value || answerValue;

    handleValidateMatching({
      that: this,
      field: questionnaire[index]?.questionText,
      value: answerValueValue,
      questionnaireIndex: index,
    });

    if (
      question?.settings &&
      question?.settings?.[answerValueValue] &&
      Object.keys(question?.settings?.[answerValueValue])?.includes(
        "activateDonation"
      )
    ) {
      this.setState({
        activateDonation:
          question?.settings?.[answerValueValue]?.activateDonation,
      });
    }

    if (question.questionText === "Rhesus (RHD R D)") {
      if (value?.value?.toUpperCase() === "POSITIVE") {
        this.formateBloodGroup(
          this.state.aboBloodGroup?.value,
          value?.value || value
        );
      } else {
        let g = this.state.aboBloodGroup?.value?.replace(/\+/g, "");
        this.formateBloodGroup(g, value?.value || value);
      }

      if (
        this.state?.bloodGroup?.value?.includes("+") &&
        value?.value?.toUpperCase() === "NEGATIVE"
      ) {
        questionnaire[index]["error"] =
          "Invalid Rhesus for blood group " + this.state?.bloodGroup?.value;
      }

      if (
        this.state?.bloodGroup?.value?.includes("-") &&
        value?.value?.toUpperCase() === "POSITIVE"
      ) {
        questionnaire[index]["error"] =
          "Invalid Rhesus for blood group " + this.state?.bloodGroup?.value;
      }

      questionnaire.forEach((el, i) => {
        if (
          value?.value?.toUpperCase() === "NEGATIVE" &&
          el?.questionText.toUpperCase() === "LISS"
        ) {
          el.required = true;
        } else {
          el.required = false;
          delete el.error;

          this.formateBloodGroup(
            this.state.aboBloodGroup?.value,
            value?.value || value
          );
        }

        if (
          (value?.value?.toUpperCase() === "POSITIVE" &&
            el?.questionText.toUpperCase() === "LISS") ||
          el?.questionText === "Indirect Antiglobulin Test - IAT (Du+/d-)"
        ) {
          el.answer = null;
        }
      });
    }

    if (question.questionText.toUpperCase() === "LISS") {
      questionnaire.forEach((el) => {
        if (
          value?.value?.toUpperCase() === "NEGATIVE" &&
          el?.questionText === "Indirect Antiglobulin Test - IAT (Du+/d-)"
        ) {
          el.required = true;
        } else {
          el.required = false;
          delete el.error;
        }

        if (value?.value?.toUpperCase() === "POSITIVE") {
          const bloodGroup = this.state.aboBloodGroup?.value.replace(/\+/g, "");
          this.formateBloodGroup(bloodGroup, value?.value || value, "+");
        } else {
          if (this.state.aboBloodGroup?.value.includes("+")) {
            const bloodGroup = this.state.aboBloodGroup?.value.replace(
              /\+/g,
              ""
            );

            this.formateBloodGroup(bloodGroup, value?.value || value);
          } else if (this.state.aboBloodGroup?.value.includes("+")) {
            const bloodGroup = this.state.aboBloodGroup?.value.replace(
              /\+/g,
              ""
            );

            this.formateBloodGroup(bloodGroup, value?.value || value);
          }
        }
      });
    }

    if (question.questionText === "Indirect Antiglobulin Test - IAT (Du+/d-)") {
      if (value?.value?.trim() === "Du+") {
        let g = this.state.aboBloodGroup?.value.replace(/\-/g, "") + "+";
        this.formateBloodGroup(g, value?.value || value, "+");
      } else {
        let g = this.state.aboBloodGroup?.value.replace(/\+/g, "");
        this.formateBloodGroup(g, value?.value || value, "-");
      }
    }

    this.setState({ questionnaire }, () => {
      handleValidateMatching({
        that: this,
        field: "bloodGroup",
        value: this.state.aboBloodGroup,
        questionnaireIndex: index,
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState?.bloodGroup?.value !== this.state.bloodGroup?.value ||
      prevState?.aboBloodGroup?.value !== this.state.aboBloodGroup?.value
    ) {
      const value = this.state?.bloodGroup?.value;
      let questionnaire = this.state.questionnaire;

      // handleValidateMatching({ that: this, field: "bloodGroup", value });

      for (let el of questionnaire) {
        if (el.questionText === "Rhesus (RHD R D)") {
          if (
            value?.value?.includes("-") &&
            el?.answer?.value?.toUpperCase() === "POSITIVE"
          ) {
            el["error"] = "Invalid Rhesus for blood group " + value;
          } else {
            delete el.error;
          }

          if (
            value?.value?.includes("+") &&
            el?.answer?.value?.toUpperCase() === "NEGATIVE"
          ) {
            el["error"] = "Invalid Rhesus for blood group " + value?.value;
          } else {
            delete el.error;
          }
        }
      }

      this.setState({ questionnaire });
    }
  }

  formateBloodGroup(bloodGroup, rhesus, signFrom) {
    let { aboBloodGroup } = this.state;

    if (typeof bloodGroup !== "string") return;

    if (bloodGroup) {
      let splitB =
        bloodGroup?.includes("+") || bloodGroup?.includes("-")
          ? bloodGroup?.split("")
          : [bloodGroup];
      if (splitB.length > 0) {
        const selectedABO = ABOList.find((el) => el.value === splitB[0]);

        aboBloodGroup = selectedABO;
      }
    }

    if (aboBloodGroup) {
      let sign = "";

      if (rhesus) {
        sign = rhesus?.toUpperCase() === "POSITIVE" ? "+" : "";
      }

      if (signFrom) {
        sign = signFrom;
      }
      aboBloodGroup = {
        label: aboBloodGroup.label + "" + sign,
        value: aboBloodGroup.label + "" + sign,
      };
    }

    this.setState({
      aboBloodGroup,
      bloodGroup: aboBloodGroup,
    });
  }

  onChangeText(field, e) {
    let { error, questionnaire } = this.state;

    delete error[field];

    const value = e && e?.target ? e?.target?.value : e;

    this.setState({
      [field]: value,
      error,
    });

    if (field === "aboBloodGroup") {
      questionnaire.forEach((el) => {
        el.answer = null;
      });

      if (field === "aboBloodGroup") {
        questionnaire.forEach((el, i) => {
          if (el.questionText === "Rhesus (RHD R D)" && el.answer) {
            this.formateBloodGroup(value.value, el.answer?.value || el.answer);
          }
        });
      }
    }

    this.setState(
      {
        questionnaire,
      },
      () => {
        handleValidateMatching({
          that: this,
          field: "bloodGroup",
          value: this.state.aboBloodGroup,
        });
      }
    );
  }

  validateForm() {
    let { questionnaire, error, code, bloodGroup } = this.state;

    questionnaire.forEach((el) => {
      if (el.required && (!el.answer || el.answer === "")) {
        el.error = `${el.questionText} ${
          language[this.props.defaultLanguage].is_required
        }`;
      }
    });

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    if (!bloodGroup) {
      error.bloodGroup =
        language[this.props.defaultLanguage].blood_group_required;
    }

    console.log(error);

    this.setState({ questionnaire, error });
  }

  onSubmit = async (status) => {
    const { questionnaire, bloodGroup, error, code } = this.state;

    const { testId } = this.props;

    await this.validateForm();

    let questionnaireError = questionnaire
      .map((el) => {
        if (el.error) {
          return el.error;
        }
        return null;
      })
      .filter((error) => error !== undefined && error !== null);

    if (Object.keys(error).length > 0) return;

    if (questionnaireError.length > 0) return;

    this.setState({
      isSubmitting: true,
    });

    await this.handleUpdateQuestionnaire();

    let url = `${API_URL}/bloodTestHistory`,
      method = "POST",
      user = await getStorage();
    let { _id } = this.props;

    let requestedBody = {
      testBy: user.id,
      bloodTest: testId,
      questionnaireType: "bloodGrouping",
      questionnaire,
      code,
      bloodGroup: bloodGroup?.value,
    };

    if (_id && _id !== "") {
      method = "PUT";
      requestedBody.id = _id;

      delete requestedBody.testBy;
    }

    if (status) {
      requestedBody.status = status;
    }

    const options = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };

    axios(options)
      .then(async (data) => {
        this.setState({
          isSubmitting: false,
        });

        if (this.props.canConfirm) {
          await this.handleValidation();
        }

        toastMessage(
          "success",
          language[this.props.defaultLanguage][
            method === "PUT"
              ? "update_bloodtest_success"
              : "add_bloodtest_success"
          ]
        );

        this.props.getData && this.props.getData();
        this.props.handleCloseModal && this.props.handleCloseModal();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        toastMessage("error", error);
      });
  };

  handleOpenModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleValidation = async () => {
    try {
      const { questionnaire, code, error, bloodGroup } = this.state;

      await this.validateForm();

      if (Object.keys(error).length > 0) return;

      this.setState({ isValidating: true });

      const { testId } = this.props;

      const user = await getStorage();

      let requestedBody = {
        bloodTestId: testId,
        type: "bloodGrouping",
        questionnaire,
        code,
        bloodGroup: bloodGroup?.value,
      };

      const options = {
        method: "POST",
        url: `${API_URL}/bloodTest/comfirmTest`,
        data: requestedBody,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      axios(options).then((data) => {
        this.setState({
          isValidating: false,
        });

        toastMessage(
          "success",
          language[this.props.defaultLanguage].confirm_blood_test
        );
        this.props.getData && this.props.getData();
        this.props.handleCloseModal && this.props.handleCloseModal();
      });
    } catch (error) {
      this.setState({
        isValidating: false,
      });

      toastMessage(
        "error",
        language[this.props.defaultLanguage].confirm_blood_test_not_same
      );
    }
  };

  renderChangePrevEntry({ field, questionnaireIndex, type, options }) {
    if (this.state.previousTestResults.length > 0) {
      return this.state.previousTestResults?.map((el, i) => {
        return this.renderChangePrevEntryInput({
          label: el.code,
          field,
          questionnaireIndex,
          type,
          options,
          testIndex: i,
        });
      });
    }
  }

  renderChangePrevEntryInput({
    type,
    options,
    field,
    testIndex,
    questionnaireIndex,
    label,
  }) {
    let value;

    if (questionnaireIndex !== undefined && testIndex !== undefined) {
      value =
        this.props?.previousTestResults[testIndex].questionnaire[
          questionnaireIndex
        ].answer;
    } else if (testIndex !== undefined) {
      value = this.props?.previousTestResults[testIndex][field];
    }

    if (type === "dropdown") {
      return (
        <Select
          key={testIndex}
          options={options}
          label={label + "-ENTRY-" + field}
          value={
            typeof value === "string"
              ? options.map((el) => el.value === value && el)
              : value
          }
          onChange={(e) =>
            this.handleChangePrevEntry({
              field,
              index: testIndex,
              value: e && e?.target ? e?.target?.value : e,
              questionnaireIndex,
            })
          }
          disabled
        />
      );
    }

    return (
      <Input
        label={label + "-ENTRY-" + field}
        value={value}
        onChange={(e) =>
          this.handleChangePrevEntry({
            field,
            index: testIndex,
            value: e && e?.target ? e?.target?.value : e,
            questionnaireIndex,
          })
        }
      />
    );
  }

  handleChangePrevEntry({ index, field, questionnaireIndex, value }) {
    // if (field === "Rhesus (RHD R D)") {
    //   this.formateBloodGroup(
    //     this.state.aboBloodGroup?.value,
    //     value?.value || value
    //   );
    // }

    if (questionnaireIndex !== undefined) {
      this.state.previousTestResults[index]["questionnaire"][
        questionnaireIndex
      ]["answer"] = value;

      if (
        this.state.previousTestResults[index]["questionnaire"][
          questionnaireIndex
        ]["answer"] === value
      ) {
        delete this.state.questionnaire[questionnaireIndex]["error"];
      }

      handleValidateMatching({
        that: this,
        field,
        value: value?.value || value,
        questionnaireIndex,
      });
    } else {
      this.state.previousTestResults[index][field] = value;

      handleValidateMatching({ that: this, field, value: this.state[field] });
    }

    this.setState({ previousTestResults: this.state.previousTestResults });
  }

  handleUpdateQuestionnaire = async () => {
    for (let el of this.state.previousTestResults) {
      try {
        let url = `${API_URL}/bloodTestHistory`,
          user = await getStorage();

        let requestedBody = {
          bloodGroup: el.bloodGroup?.value || el.bloodGroup,
          questionnaire: el.questionnaire,
          id: el._id,
          bloodTest: el.bloodTest._id,
        };

        delete requestedBody._id;

        return await axios({
          url,
          method: "PUT",
          data: requestedBody,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    if (!this.state.showForm)
      return (
        <Empty
          title={language[this.props.defaultLanguage].no_access}
          icon="help"
          description={
            language[this.props.defaultLanguage].no_access_description
          }
        />
      );

    // console.log(this.state.error);

    return (
      <div>
        <div className="card-body">
          {this.state.misMatchFields.length > 0 && (
            <div className="alert alert-danger">
              <div
                style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
              >
                {language[this.props.defaultLanguage].mismatch}:
                {this.state.misMatchFields.join(",")}
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <div className="mb-2">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        label={language[this.props.defaultLanguage].code}
                        placeholder={
                          language[this.props.defaultLanguage].code_placeholder
                        }
                        value={this.state?.code}
                        error={this.state.error.code}
                        onChange={(e) => this.onChangeText("code", e)}
                      />
                      <Select
                        options={ABOList}
                        label={language[this.props.defaultLanguage].blood_group}
                        value={this.state?.aboBloodGroup}
                        error={this.state.error.aboBloodGroup}
                        onChange={(e) => this.onChangeText("aboBloodGroup", e)}
                      />
                      {/* {this.state.aboBloodGroup && (
                        <Select
                          options={bloodGroups}
                          label={
                            language[this.props.defaultLanguage].blood_group
                          }
                          value={this.state?.bloodGroup}
                          error={this.state.error.bloodGroup}
                          onChange={(e) => this.onChangeText("bloodGroup", e)}
                          disabled
                        />
                      )} */}
                      {this.state.misMatchFields.includes("bloodGroup") && (
                        <>
                          {this.renderChangePrevEntry({
                            field: "bloodGroup",
                            type: "dropdown",
                            options: bloodGroups,
                          })}
                        </>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div
                        className=""
                        style={{
                          height: 200,
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <h1 style={{ fontSize: 60 }} className="text-primary">
                          {this.state?.bloodGroup?.value}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <div className="card-body">
                <div className="row">
                  {this.state.questionnaire.map((form, index) => {
                    const options = form?.options?.map((el) => {
                      return {
                        label: el,
                        value: el,
                      };
                    });
                    return (
                      <div className="col-md-4" key={index}>
                        <TESTForm
                          label={form.questionText}
                          error={form.error}
                          {...form}
                          options={options}
                          onChange={(e) =>
                            this.onChangeQuestionnaire({
                              index,
                              form,
                              value: e,
                            })
                          }
                          disabled={this.state.disabled}
                        />
                        {this.state.misMatchFields.includes(
                          form.questionText
                        ) &&
                          this.renderChangePrevEntry({
                            field: form.questionText,
                            questionnaireIndex: index,
                            type:
                              form.answerType === "multipleChoice"
                                ? "dropdown"
                                : "",
                            options: options,
                          })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <Button
                text={language[this.props.defaultLanguage].cancel}
                onPress={() => this.props.handleCloseModal()}
                className="btn-default"
              />
              <Button
                text={language[this.props.defaultLanguage].submit}
                onPress={() => this.onSubmit()}
                isSubmitting={this.state.isSubmitting}
                disabled={this.state.questionnaire.length === 0}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodGrouping);
