import React from "react";
import { connect } from "react-redux";
import { Clubs } from "../../../components/Clubs";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import language from "../../../language";

class ClubsScreen extends React.Component {
  render() {
    return (
      <div>
        <div className='card-dashboard-content'>
          <div className='card-body'>
            <DashboardHeader
              title={language[this.props.defaultLanguage].clubs}
            />
            <Clubs clubId={this.props.routeParams.clubId} />,
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ClubsScreen));
