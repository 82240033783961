import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import { fetchPatients } from "../../../utils/queries/patient";
import CheckboxQuestionnaire from "../../Input/CheckboxQuestionnaire";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  generateRandomNumber,
  generateRandomString,
} from "../../../utils/random";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const statusOptions = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Dispatched",
    value: "dispatched",
  },
  {
    label: "Delivered",
    value: "delivered",
  },
];

class NominativeRequest extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    donors: [],
    distributors: [],
    hospitals: [],
    numberOfPackages: "",
    isActive: availableOptions[0],
    description: "",
    approvals: [],
    packages: [],
    centers: [],
    centerSites: [],
    status: statusOptions[0],
    isBooking: false,
    isOrdinary: false,
    isUrgent: false,
    isPreviousTransfused: false,
    knownLabTestsSelected: [],
    bookingDate: new Date(),
    transfusionParams: [],
    transfusionParamsNote: "",
    lastTransfused: false,
    collectedBy: "",
    sampleCollectedTime: new Date(),
    hct: false,
    hgb: false,
    pltCount: false,
    pt: false,
    inr: false,
    ptt: false,
    fibronogen: false,
  };

  componentDidMount = async () => {
    this.setState({
      requestNo:
        generateRandomString(4).toUpperCase() +
        "-" +
        generateRandomNumber(0, 1000000),
    });
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  onCheck(field, value) {
    this.setState(
      {
        [field]: value,
      },
      () => {
        console.log(`${field} updated to:`, this.state[field]);
      }
    );
  }

  getPatients = async (isFetchingPatient, search) => {
    try {
      this.setState({
        isFetchingPatient,
      });

      const { hospitalRole } = defineRoleCenterSite();

      const data = await fetchPatients(this.props.defaultLanguage, {
        type: "dropdown",
        hospital: hospitalRole,
        q: search ? search : undefined,
      });

      this.setState({
        patients: data,
        isFetchingPatient: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingPatient: false });
    }
  };
  onChangeDate(field, date) {
    this.setState({
      [field]: date,
    });
  }

  validateForm() {
    let { error, requestNo, patient, hospital } = this.state;

    if (!patient) {
      error.patient = language[this.props.defaultLanguage].patient_required;
    }

    if (requestNo === "") {
      error.requestNo =
        language[this.props.defaultLanguage].request_number_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          _id,
          description,
          status,
          requestNo,
          isUrgent,
          clinicalInformation,
          patient,
          historyPregnancy,
          testRequired,
          requestType,
        } = this.state,
        url = `${API_URL}/bloodRequest`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        type: "nominative",
        requestNo,
        hospital: user.hospital,
        description: description,
        bloodBank: user?.stock,
        account: user.id,
        clinicalInformation,
        patient: patient?.value,
        historyPregnancy:
          historyPregnancy === undefined ? false : historyPregnancy,
        isUrgent,
        bookingDate: this.state.bookingDate,
        isOrdinary: this.state.isOrdinary,
        requestType,
        testRequired: testRequired,
        previouslyTransfused: this.state.lastTransfused ? this.state.Value : "",
        status: "pending",
      };

      if (status?.value) {
        requestedBody.status = status.value;
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_bloodrequest_success"
                : "add_bloodrequest_success"
            ]
          );

          this.props.handleCloseModal();
          window.location.href = `/dashboard/request/${requestNo}`;
        })
        .catch((error) => {
          toastMessage("error", error);
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };
  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .request_number_placeholder
                }
                label={language[this.props.defaultLanguage].request_number}
                required
                value={this.state.requestNo}
                onChange={(e) => this.onChangeText("requestNo", e)}
                error={this.state.error.requestNo}
              />
            </div>
          </div>

          <div className="col-md-12">
            <Select
              loadOptions={(e) => this.getPatients(true, e)}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].patient}
              required
              value={this.state.patient}
              onChange={(e) => this.onChangeText("patient", e)}
              error={this.state.error.patient}
              isLoading={this.state.isFetchingPatient}
            />
          </div>
          <div className="row">
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].request_type}
              answers={[
                {
                  field: "requestType",
                  label: language[this.props.defaultLanguage].urgent,
                  value: "isUrgent",
                  onPress: () => this.onCheck("requestType", "isUrgent"),
                },
                {
                  field: "requestType",
                  label: language[this.props.defaultLanguage].ordinary,
                  value: "isOrdinary",
                  onPress: () => this.onCheck("requestType", "isOrdinary"),
                },
                {
                  field: "requestType",
                  label: language[this.props.defaultLanguage].booking,
                  value: "isBooking",
                  onPress: () => this.onCheck("requestType", "isBooking"),
                },

                {
                  field: "requestType",
                  label: language[this.props.defaultLanguage].emergency_release,
                  value: "isEmergencyRelease",
                  onPress: () =>
                    this.onCheck("requestType", "isEmergencyRelease"),
                },
              ]}
              checked={this.state.requestType}
              error={this.state.error.requestType}
            />

            {this.state.requestType === "isBooking" && (
              <div className="col-md-12">
                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  Select Booking Date and Time{" "}
                  <DateTimePicker
                    onChange={(e) => this.onChangeDate("bookingDate", e)}
                    value={this.state.bookingDate}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .clinical_information_placeholder
                }
                label={
                  language[this.props.defaultLanguage].clinical_information
                }
                required
                value={this.state.clinicalInformation}
                onChange={(e) => this.onChangeText("clinicalInformation", e)}
                error={this.state.error.clinicalInformation}
                textarea
              />
            </div>
            <div className="col-md-12">
              <CheckboxQuestionnaire
                questionText={
                  // language[this.props.defaultLanguage].history_of_transfusion
                  "Has the patient received a blood transfusion in the past?"
                }
                // Has the patient received a blood transfusion in the past?
                answers={[
                  {
                    field: "lastTransfused",
                    value: language[this.props.defaultLanguage].yes,
                    onPress: () => this.onCheck("lastTransfused", true),
                  },
                  {
                    field: "lastTransfused",
                    value: language[this.props.defaultLanguage].no,
                    onPress: () => this.onCheck("lastTransfused", false),
                  },
                ]}
                checked={this.state.lastTransfused === true ? "Yes" : "No"}
                error={this.state.error.lastTransfused}
              />

              {this.state.lastTransfused && (
                <div className="col-md-12">
                  <div
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    Select Date and Time
                    <DatePicker
                      placeholderText="Select Date eg: 12/12/2024"
                      selected={this.state.Value}
                      onChange={(e) => this.onChangeDate("Value", e)}
                      dateFormat={"dd/MM/yyyy"}
                    />
                  </div>
                </div>
              )}
              <CheckboxQuestionnaire
                questionText={
                  language[this.props.defaultLanguage].history_of_pregnancy
                }
                answers={[
                  {
                    field: "historyPregnancy",
                    value: language[this.props.defaultLanguage].yes,
                    onPress: () => this.onCheck("historyPregnancy", true),
                  },
                  {
                    field: "historyPregnancy",
                    value: language[this.props.defaultLanguage].no,
                    onPress: () => this.onCheck("historyPregnancy", false),
                  },
                ]}
                checked={this.state.historyPregnancy === true ? "Yes" : "No"}
                error={this.state.error.historyPregnancy}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NominativeRequest);
