import React from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Table } from "../../../Table";
import { DeleteModal, Modal } from "../../../Modal";
import { getStorage } from "../../../../utils/storage";
import toastMessage from "../../../../utils/toastMessage";
import {
  deleteRequestedProducts,
  fetchRequestedProducts,
} from "../../../../utils/queries/request-product";

class Products extends React.Component {
  state = {
    isLoading: false,
    data: [],
    selected_data: {},
    error: {},
    csvData: [],
    delete_password: "",
    user: {},
    page: 1,
    limit: 500,
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.getData(true);
  };
  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading) => {
    try {
      this.setState({ isLoading });
      const { request, defaultLanguage } = this.props;

      const { data, count } = await fetchRequestedProducts(defaultLanguage, {
        request,
        page: this.state.page,
        limit: this.state.limit,
      });

      this.setState({ isLoading: false, data, totalPageCount: count });
    } catch (error) {
      this.setState({ isLoading: false });
      toastMessage("error", error);
    }
  };

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].product,
        key: "productType.name",
        styles: {
          textTransform: "uppercase",
        },
      },
    ].filter(Boolean);

    if (this.props.type === "bulk") {
      headers.push({
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
      });
      headers.push(
        {
          title: language[this.props.defaultLanguage].number_of_packages,
          key: "numberOfPackages",
        },
        // {
        //   title: language[this.props.defaultLanguage].stock_to_be_requested,
        //   key: "toBeRequested",
        // },
        {
          title: language[this.props.defaultLanguage].current_stock,
          key: "currentStock",
        },

        {
          title: language[this.props.defaultLanguage].delivered_stock,
          key: "deliveredStock",
        }
      );
    } else {
      headers.push({
        title: language[this.props.defaultLanguage].sample_collected_by,
        key: "sampleCollectedBy",
      });

      headers.push({
        title: language[this.props.defaultLanguage].sample_collected_date,
        key: "sampleCollectedDate",
        isMoment: true,
        formatTime: "lll",
      });
    }

    if (this.props.stock === this.state.user.stock) {
      headers.push({
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      });
    }

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      let { defaultLanguage } = this.props;
      let { _id } = this.state.selected_data;

      await deleteRequestedProducts(defaultLanguage, { id: _id });

      toastMessage(
        "success",
        language[this.props.defaultLanguage].delete_data_success
      );

      this.setState({ isDeleting: false });

      this.handleCloseModal("showDeleteModal");
      this.getData(true);
    } catch (error) {
      this.setState({
        isDeleting: false,
      });

      toastMessage("error", error);
    }
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          page={this.state.page}
          limit={this.state.limit}
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          actions={[
            this.props.status === "pending" && {
              canAccess: true,
              name: language[this.props.defaultLanguage].delete_,
              onPress: (item, index) =>
                this.handleShowModal(
                  "showDeleteModal",
                  language[this.props.defaultLanguage].delete_,
                  {
                    ...item,
                  }
                ),
            },
          ].filter(Boolean)}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state?.selected_data?._id}
            getData={() => this.getData(true)}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Products);
