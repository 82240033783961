import toastMessage from "../../toastMessage";
import api from "../../api";
import { getStorage } from "../../storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchTransferDonationLogsData = async (
  lng = "english",
  params
) => {
  try {
    const user = await getStorage();
    const { page, limit } = params;
    const options = {
      method: "GET",
      url: `${API_URL}/bloodDonationSwipeTransferLogs`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};
