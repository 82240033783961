import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import PatientInfo from "../../../components/Patient/patients/PatientInfo";
import { Tabs } from "../../../components/Tabs";
import { Breadcrumb } from "../../../components/Breadcrumb";

class PatientInfoScreen extends React.Component {
  render() {
    let patientId = this.props?.routeParams?.patientId || this.props.patientId,
      idNumber = this.props?.routeParams?.idNumber || this.props.idNumber;
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <DashboardHeader
              title={language[this.props.defaultLanguage].patient_information}
            />

            <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: language[this.props.defaultLanguage].patients,
                  url: "/dashboard/patient",
                },
                {
                  name: idNumber,
                  active: true,
                },
              ]}
            />

            <PatientInfo {...this.props.routeParams} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(PatientInfoScreen));
