import React from "react";
import { connect } from "react-redux";
import { Input } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewSwipe extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    newDonor: "",
    user: {},
    actionTypes: [],
    type: "SWIPE",
    oldDonationNumber: "",
    newDonationNumber: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;
    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  handleCheck(type, value) {
    this.setState({ [type]: value });
  }

  validateForm() {
    let { newDonationNumber, error, oldDonationNumber, type } = this.state;

    if (newDonationNumber === "") {
      error.newDonationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (oldDonationNumber === "") {
      error.oldDonationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (type === "") {
      error.type = language[this.props.defaultLanguage].type_required;
    }
    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { oldDonationNumber, newDonationNumber, type } = this.state,
        url = `${API_URL}/blooddonation/transfer-donation`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        donationNumber: oldDonationNumber,
        newDonationNumber,
        type,
      };

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };
      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].transfer_donation_success
          );

          this.onReset();
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          toastMessage("error", error);
        });
    }
  };

  onReset() {
    this.setState({
      oldDonationNumber: "",
      newDonationNumber: "",
    });
  }

  render() {
    return (
      <>
        <>
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].enter_donation_number
                }
                label={language[this.props.defaultLanguage].donation_number}
                required
                value={this.state.oldDonationNumber}
                onChange={(e) => this.onChangeText("oldDonationNumber", e)}
                error={this.state.error.donationNumber}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .donation_number_placeholder
                }
                label={language[this.props.defaultLanguage].swipe_to}
                required
                value={this.state.newDonationNumber}
                onChange={(e) => this.onChangeText("newDonationNumber", e)}
                error={this.state.error.newDonationNumber}
              />
            </div>
            <div className="d-flex gap-3">
              <>
                <Button
                  text={language[this.props.defaultLanguage].reset}
                  onPress={this.onReset.bind(this)}
                  className="btn-default"
                />
                <Button
                  text={language[this.props.defaultLanguage].submit}
                  onPress={this.onSubmit.bind(this)}
                  isSubmitting={this.state.isSubmitting}
                  type="submit"
                />
              </>
            </div>
          </div>
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewSwipe);
