import React, { Component } from "react";
import language from "../../../../language";
import { connect } from "react-redux";
import { Button } from "../../../Button";
import { defineRole } from "../../../../utils/handleAccessRoles";
import { ROLE_SAMPLE_COLLECTED_RESULT } from "../../../../constants/ROLES";
import { getStorage } from "../../../../utils/storage";
import api from "../../../../utils/api";
import toastMessage from "../../../../utils/toastMessage";
import PrintableLabel from "../../../Production/ComponentLabeling/printableLabel";
import { Modal } from "../../../Modal";

class ApproverABO extends Component {
  state = {
    error: {},
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleOpenModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  onServeProduct = async () => {
    try {
      const { requestId, patientId, inventoryId } = this.props;
      this.setState({ isSubmitting: true });
      let method = "PUT",
        requestBody = {
          inventory: inventoryId,
          request: requestId,
          patient: patientId,
        };

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory/patient`,
        data: requestBody,
      };
      await api(options);
      this.setState({
        isSubmitting: false,
      });
      this.props.getProducts();
      this.handleOpenModal("showLabelModal");
    } catch (error) {
      console.log(error);
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };

  render() {
    const canMismatchSampleCollection = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_SAMPLE_COLLECTED_RESULT,
      operation: "mismatchBloodGroup",
    });

    return (
      <div>
        <div className="card-body">
          <h3>Mismatching blood group</h3>
          <br />
          <p>
            {language[this.props.defaultLanguage].sample_collected_bloodgroup}:{" "}
            <b>{this.props.sampleBloodGroup}</b>
          </p>
          <p>
            {language[this.props.defaultLanguage].product_blood_group}:{" "}
            <b>{this.props.inventoryBloodGroup}</b>
          </p>
          {/* <Select
              loadOptions={(e) => this.getAccounts(true, { q: e })}
              label={language[this.props.defaultLanguage].account}
              placeholder={language[this.props.defaultLanguage].select}
              required
              value={this.state.account}
              error={this.state.error.account}
              onChange={(e) => this.onChangeText("account", e)}
            /> */}
        </div>

        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          {canMismatchSampleCollection && (
            <Button
              text={language[this.props.defaultLanguage].confirm}
              isSubmitting={this.state.isSubmitting}
              onPress={() => this.onServeProduct()}
            />
          )}
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showLabelModal")}
          show={this.state.showLabelModal}
          showHeaderBottomBorder={false}
        >
          <PrintableLabel
            handleCloseModal={() =>
              this.props.handleCloseModal("showLabelModal")
            }
            productId={this.props?.inventoryId}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ApproverABO);
