import React, { Component } from "react";
import { Table } from "../Table/index.js";
import { DeleteModal, Modal } from "../Modal/index.js";
import AddProductType from "./AddProductType.js";
import {
  deleteProductType,
  fetchProductTypes,
} from "../../utils/queries/productsType/index.js";
import { getStorage } from "../../utils/storage.js";
import { ROLE_PRODUCT_TYPES } from "../../constants/ROLES.js";
import icons from "../../constants/icons.js";
import { defineRole } from "../../utils/handleAccessRoles.js";
import language from "../../language";
import { connect } from "react-redux";

class ProductTypes extends Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    showAddTypeModal: false, // Changed from showModal to match the use case
    modalTitle: "",
  };

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    await this.getData(true);
  };

  closeModal(modal) {
    this.setState({ [modal]: false });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchProductTypes(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  returnTableHeaders() {
    let headers = [
      { title: language[this.props.defaultLanguage].code, key: "code" },
      { title: language[this.props.defaultLanguage].name, key: "name" },
      {
        title: language[this.props.defaultLanguage].description,
        key: "description",
      },
      {
        title: language[this.props.defaultLanguage].pre_transfusion_parameters,
        key: "preTransfusionParameters",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].allowedTestRequired,
        key: "allowedTestRequired",

        styles: {
          textTransform: "uppercase",
        },
      },
      { title: language[this.props.defaultLanguage].actions, key: "action" },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteProductType(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  onChangeText = (field, e) => {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isLoading={this.state.isLoading}
          showAdd
          addButtonText={"Add"}
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handlePagination={this.handlePagination}
          handleSelect={this.handleSelect}
          headers={this.returnTableHeaders()}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showAddTypeModal",
            language[this.props.defaultLanguage].add
          )}
          // action={[
          //   { title: "Edit", icon: icons.edit, action: this.handleEdit },
          //   { title: "Delete", icon: icons.delete, action: this.handleDelete },
          // ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_PRODUCT_TYPES,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].edit,
              onPress: (item) =>
                this.handleShowModal(
                  "showAddTypeModal",
                  language[this.props.defaultLanguage].edit,
                  item
                ),
              icon: icons.edit,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_PRODUCT_TYPES,
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete
              ),
              icon: icons.delete,
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAddTypeModal")}
          show={this.state.showAddTypeModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <AddProductType
            handleCloseModal={this.handleCloseModal.bind(this)}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ProductTypes);
