import React from "react";
import { connect } from "react-redux";
import { Checkbox, Input, Select, Switch } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import types from "../../../constants/bloodTest/types";
import CheckboxQuestionnaire from "../../Input/CheckboxQuestionnaire";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const requiredOptions = [
  {
    label: true + "",
    value: true,
  },
  {
    label: false + "",
    value: false,
  },
];

const answerTypes = [
  {
    label: "multipleChoice",
    value: "multipleChoice",
  },
  {
    label: "Textinput",
    value: "Textinput",
  },
];

class NewQuestionnaire extends React.Component {
  state = {
    questionText: "",
    options: [],
    error: {},
    isSubmitting: false,
    questionText: "",
    answerType: answerTypes[0],
    printingEnabled: false,
  };

  componentDidMount = async () => {
    if (this.props._id) {
      this.setState({
        ...this.props,
        type: this.props.type
          ? {
              label: this.props.type,
              value: this.props.type,
            }
          : null,
        answerType: this.props.answerType
          ? {
              label: this.props.answerType,
              value: this.props.answerType,
            }
          : answerTypes[0],
        options: this.props.options?.map((el) => {
          return {
            label: el,
            value: el,
          };
        }),
        required: this.props.required
          ? {
              label: this.props.required + "",
              value: this.props.required + "",
            }
          : null,
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { error, questionText, answerType } = this.state;

    if (!questionText || questionText === "") {
      error.questionText =
        language[this.props.defaultLanguage].question_text_required;
    }

    if (!answerType || answerType === "") {
      error.answerType =
        language[this.props.defaultLanguage].answer_type_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          questionText,
          options,
          answerType,
          required,
          type,
          settings,
          printingEnabled,
        } = this.state,
        url = `${API_URL}/blood-test-questionnaire`,
        { _id } = this.props,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        questionText,
        options: options?.map((el) => el.value),
        answerType: answerType.value,
        required: required?.value || false,
        type: type.value,
        settings,
        printingEnabled,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const apiOptions = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(apiOptions)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  onChangeOptionAbbrev(option, field, value) {
    let settings = this.state.settings || {};
    if (!settings[option]) {
      settings[option] = {};
    }
    settings[option][field] = value?.target?.value;

    this.setState({
      settings,
    });
  }

  onChangeOptionCheckbox(option, field, value) {
    let settings = this.state.settings || {};
    if (!settings[option]) {
      settings[option] = {};
    }
    settings[option][field] = value;

    this.setState({
      settings,
    });
  }

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-12">
                <Select
                  options={types}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].type}
                  required
                  value={this.state.type}
                  onChange={(e) => this.onChangeText("type", e)}
                  error={this.state.error.type}
                />
              </div>
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].question_text_placeholder
                }
                label={language[this.props.defaultLanguage].question_text}
                required
                value={this.state.questionText}
                onChange={(e) => this.onChangeText("questionText", e)}
                error={this.state.error.questionText}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.options}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].options}
                required
                value={this.state.options}
                onChange={(e) => this.onChangeText("options", e)}
                error={this.state.error.options}
                isCreatable
                isMulti
              />
            </div>
            {this.state.options?.map((option, i) => {
              return (
                <div className="card" style={{ marginTop: "1rem" }} key={i}>
                  <div className="card-heading">
                    <p className="text-bold">
                      {option.label}{" "}
                      {language[this.props.defaultLanguage].settings}
                    </p>
                  </div>
                  <div className="card-body">
                    <div className="col-md-12">
                      <Input
                        placeholder={
                          language[this.props.defaultLanguage]
                            .abbreviation_placeholder
                        }
                        label={
                          language[this.props.defaultLanguage].abbreviation
                        }
                        value={
                          this.state?.settings?.[option.value]?.["abbreviation"]
                        }
                        required
                        onChange={(e) =>
                          this.onChangeOptionAbbrev(
                            option.value,
                            "abbreviation",
                            e
                          )
                        }
                      />
                    </div>
                    <CheckboxQuestionnaire
                      questionText={
                        language[this.props.defaultLanguage].activate_donation
                      }
                      answers={[
                        {
                          label: "Yes",
                          value: true,
                          onPress: () =>
                            this.onChangeOptionCheckbox(
                              option.value,
                              "activateDonation",
                              true
                            ),
                        },
                        {
                          label: "No",
                          value: false,
                          onPress: () =>
                            this.onChangeOptionCheckbox(
                              option.value,
                              "activateDonation",
                              false
                            ),
                        },
                      ]}
                      checked={
                        this.state?.settings?.[option.value]?.activateDonation
                      }
                    />
                    <div className="col-md-12">
                      <Input
                        placeholder={
                          language[this.props.defaultLanguage].color_placeholder
                        }
                        label={language[this.props.defaultLanguage].color}
                        value={this.state?.settings?.[option.value]?.["color"]}
                        onChange={(e) =>
                          this.onChangeOptionAbbrev(option.value, "color", e)
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="col-md-12">
              <Select
                options={requiredOptions}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].required}
                required
                value={this.state.required}
                onChange={(e) => this.onChangeText("required", e)}
                error={this.state.error.required}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={answerTypes}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].answer_type}
                required
                value={this.state.answerType}
                onChange={(e) => this.onChangeText("answerType", e)}
                error={this.state.error.answerType}
              />
            </div>
            <hr />
            <div className="col-md-12">
              <Checkbox
                label={language[this.props.defaultLanguage].hide_by_default}
                onCheck={() => {
                  if (!this.state.settings) {
                    this.state.settings = {};
                  }
                  this.state.settings["hide"] = !this.state.settings["hide"];
                  this.setState({
                    settings: this.state.settings,
                  });
                }}
                checked={this.state?.settings?.hide}
              />
            </div>
            <hr />
            <div className="col-md-12">
              <Switch
                label={language[this.props.defaultLanguage].printing_enabled}
                checked={this.state.printingEnabled}
                description={
                  language[this.props.defaultLanguage]
                    .printing_enabled_blood_receipt_description
                }
                onCheck={() =>
                  this.setState({
                    printingEnabled: !this.state.printingEnabled,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewQuestionnaire);
