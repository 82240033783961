import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";
import { ROLE_ACCOUNT } from "../../../constants/ROLES";
import accessRoleQuery from "../../../utils/queries/accessRoleQuery";
import { generateRandomNumber } from "../../../utils/random";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const supportedReports = [
  {
    label: "Blood Test Report",
    value: "bloodTest",
  },
  {
    label: "Donors Report",
    value: "donors",
  },
];

class NewConfiguration extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    showAccessRoles: false,
    name: "",
    user: {},
    type: supportedReports[0],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    if (this.props._id) {
      this.setState({
        ...this.props,
        type: this.props.type
          ? {
              label: this.props.type,
              value: this.props.type,
            }
          : undefined,
      });
    }
  };

  getRoles = async () => {
    try {
      this.setState({
        isFetchingAccessRole: true,
      });

      const data = await accessRoleQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        isFetchingAccessRole: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingAccessRole: false });

      return [];
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { type, period, error, accessRole } = this.state;

    if (type === "") {
      error.type = language[this.props.defaultLanguage].type_required;
    }

    if (!period) {
      error.period = language[this.props.defaultLanguage].period_required;
    }

    if (!accessRole) {
      error.accessRole =
        language[this.props.defaultLanguage].access_role_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { config = {} } = this.props;

      let { period, type, accessRole } = this.state,
        url = `${API_URL}/configuration`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {};

      let configurationNumber =
        new Date().getTime() + "-" + generateRandomNumber(1, 1000);

      let bodyFormate = {
        configurationNumber,
        period: period.value,
        type: type.value,
        roles: accessRole.map((el) => el.value),
      };

      if (
        config.configuration &&
        config.configuration?.reportTestResult?.length > 0
      ) {
        if (this.props.configurationNumber) {
          config?.configuration?.reportTestResult.map((el, index) => {
            if (el.configurationNumber === this.props.configurationNumber) {
              config.configuration.reportTestResult[index] = {
                period: period.value,
                type,
                roles: accessRole.map((el) => el.value),
              };
            }
          });
        } else {
          config?.configuration?.reportTestResult.push(bodyFormate);
        }
      } else if (
        config.configuration &&
        config.configuration?.reportTestResult?.length === 0
      ) {
        config.configuration?.reportTestResult.push(bodyFormate);
      } else if (config.configuration) {
        config.configuration.reportTestResult = [bodyFormate];
      } else {
        method = "POST";
        config = {
          configuration: {
            configurationNumber,
            reportTestResult: [bodyFormate],
          },
        };
      }

      if (config._id) {
        method = "PUT";
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: config,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].success_data_added
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    console.log(this.props);
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                options={supportedReports}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].type}
                required
                value={this.state.type}
                onChange={(e) => this.onChangeText("type", e)}
                error={this.state.error.type}
              />
              {/* <Input
                placeholder={
                  language[this.props.defaultLanguage].type_placeholder
                }
                label={language[this.props.defaultLanguage].type}
                required
                value={this.state.type}
                onChange={(e) => this.onChangeText("type", e)}
                error={this.state.error.type}
              /> */}
            </div>
            <div className="col-md-12">
              <Select
                options={[
                  {
                    label: "Daily",
                    value: "daily",
                  },
                  {
                    label: "Weekly",
                    value: "weekly",
                  },
                  {
                    label: "Monthly",
                    value: "monthly",
                  },
                ]}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].period}
                required
                value={this.state.period}
                onChange={(e) => this.onChangeText("period", e)}
                error={this.state.error.period}
              />
            </div>
            <div className="col-md-12">
              <Select
                loadOptions={this.getRoles}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].access_roles}
                required
                value={this.state.accessRole}
                onChange={(e) => this.onChangeText("accessRole", e)}
                error={this.state.error.accessRole}
                isMulti
                isLoading={this.state.isFetchingAccessRole}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          {defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_ACCOUNT,
            operation: "create,update",
          }) && (
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewConfiguration);
