import React from "react";
import { connect } from "react-redux";
import { Inventories } from "../../../components/inventory";
import { withRouter } from "../../../utils/withRouter";
import language from "../../../language";
import { Tabs } from "../../../components/Tabs";
import { getStorage } from "../../../utils/storage";
import accessMenu from "../../../utils/accessMenu";
import Controls from "../../../components/inventory/controls";
import DashboardInventories from "../../../components/inventory/dashboardInventory/index";
import CloserToExpiryDate from "../../../components/inventory/CloserToExpiryDate";

class InventoriesScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };
  render() {
    let { canReadProductionInventory } = accessMenu();
    let tabOptions = [];

    if (canReadProductionInventory) {
      tabOptions = [
        {
          title: language[this.props.defaultLanguage].dashboard,
          data: <DashboardInventories />,
        },
        {
          title: language[this.props.defaultLanguage].inventory,
          data: (
            <Inventories
              {...this.props.routeParams}
              isMainInventory={this.props.isMainInventory}
              status="labelled"
            />
          ),
        },
        {
          title: language[this.props.defaultLanguage].closer_to_expiry_date,
          data: <CloserToExpiryDate {...this.props.routeParams} />,
        },
        {
          title: language[this.props.defaultLanguage].expired_products,
          data: (
            <Inventories
              {...this.props.routeParams}
              isMainInventory={this.props.isMainInventory}
              status="expired"
            />
          ),
        },
      ];
    }

    if (canReadProductionInventory) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].inventory_controls,
        data: <Controls {...this.props.routeParams} />,
      });
    }

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(InventoriesScreen));
