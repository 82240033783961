import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Modal } from "../../Modal";
import { getStorage } from "../../../utils/storage";
import { Input, Select } from "../../Input";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import toastMessage from "../../../utils/toastMessage";
import { Button } from "../../Button";
import { fetchAllComponents } from "../../../utils/queries/production/components";
import PrintableLabel from "../ComponentLabeling/printableLabel";
import axios from "axios";
import { fetchComponentSeparationData } from "../../../utils/queries/production/componentSeparationQuery";
import { fetchComponentTransformationData } from "../../../utils/queries/production/componentTransformationQuery";
import { setConcetrationData } from "../../../utils/queries/production/setConcentrationQuery";
import { Loading } from "../../Loader";
import moment from "moment";

class ComponentLabeling extends React.Component {
  state = {
    isLoading: false,
    donation: {},
    error: {},
    user: {},
    components: [],
    donationNumber: "",
    productInfo: {},
    isFetchingProduct: true,
    concentrationValue: "",
    products: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };

  onChangeText(field, e) {
    let { error, donation } = this.state;

    delete error[field];

    const value = e && e?.target ? e?.target?.value : e;

    this.setState({
      [field]: value,
      error,
    });

    // if (donation?._id && field === "component") {
    //   this.getProductInfo(donation._id, value.value);
    // }
  }

  handleShowModal(modal, modalTitle, selectedData) {
    this.setState({ [modal]: true, modalTitle, selectedData });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  validateForm() {
    let { donationNumber, component, error, concentrationValue } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (!component) {
      error.component = language[this.props.defaultLanguage].component_required;
    } else if (
      (component?.code === "12000" ||
        component?.code === "13000" ||
        component?.name?.includes("PLATELETS")) &&
      (!concentrationValue || concentrationValue === "")
    ) {
      error.concentrationValue =
        language[this.props.defaultLanguage].concentration_value_required;
    }

    this.setState({ error });
  }

  getDonation = async () => {
    try {
      await this.validateForm();

      if (Object.keys(this.state.error).length > 0) return;

      let { error, donationNumber } = this.state;

      if (donationNumber === "") {
        error.donationNumber =
          language[this.props.defaultLanguage].donation_number_required;

        this.setState({ error });

        return;
      }

      this.setState({
        isSubmitting: true,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: this.state.donationNumber?.replace(/\s+/g, ""),
      });

      let donation = {};

      if (data.length > 0) {
        donation = data[0];

        this.setState(
          {
            donation,
          },
          () => {
            this.getProductInfo(donation, this.state.component.code);
          }
        );
      } else {
        this.setState({ isSubmitting: false });
        toastMessage(
          "error",
          language[this.props.defaultLanguage].donation_not_found
        );
      }
    } catch (error) {
      this.setState({ searchDonation: false });
      toastMessage("error", "Donation not found!");
    }
  };

  getComponents = async (isFetchingComponents, code) => {
    try {
      this.setState({
        isFetchingComponents,
      });

      const data = await fetchAllComponents(this.props.defaultLanguage, {
        inputType: "dropdown",
        code: code ? code : undefined,
      });

      this.setState({
        isFetchingComponents: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingComponents: false });
    }
  };

  getProductInfo = async (donation, componentCode) => {
    try {
      this.setState({
        isFetchingComponents: true,
      });
      const separationInfo = await fetchComponentSeparationData(
        this.props.defaultLanguage,
        {
          inputType: "dropdown",
          code: componentCode,
          donation: donation._id,
        }
      );

      const transformationInfo = await fetchComponentTransformationData(
        this.props.defaultLanguage,
        {
          inputType: "dropdown",
          code: componentCode,
          donation: donation._id,
        }
      );

      const combined = [
        ...separationInfo
          .filter((item) => item.status !== "rejected")
          .map((item) => ({ ...item, category: "separation" })),
        ...transformationInfo
          .filter((item) => item.status !== "rejected")
          .map((item) => ({ ...item, category: "transformation" })),
      ];

      if (separationInfo.length === 0 && transformationInfo.length === 0) {
        toastMessage(
          "error",
          language[this.props.defaultLanguage].no_product_available_for_labeling
        );

        this.setState({
          isFetchingComponents: false,
          isSubmitting: false,
          searchDonation: false,
        });
        return;
      }

      if (combined.length > 1) {
        this.setState({
          isFetchingComponents: false,
          products: combined,
          isSubmitting: false,
        });

        this.handleShowModal(
          "showProductModal",
          language[this.props.defaultLanguage].select_product
        );
        return;
      }

      // let productInfo = {};

      // if (separationInfo.length > 0) {
      //   productInfo = separationInfo[0];
      // }

      // if (transformationInfo.length > 0) {
      //   productInfo = transformationInfo[0];
      // }

      let { error } = this.state;

      // if (!combined[0]?._id) {
      //   error.component =
      //     language[
      //       this.props.defaultLanguage
      //     ].no_product_available_for_labeling;
      // }

      this.setState(
        {
          productInfo: combined[0],
          isFetchingComponents: false,
          error,
          isSubmitting: false,
        },
        () => {
          this.onGenerateLabel(donation);
        }
      );
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isFetchingComponents: false,
      });
    }
  };

  onGenerateLabel = async (donation) => {
    this.setState({
      isSubmitting: true,
    });
    const user = await getStorage();

    let { error, component, productInfo } = this.state;

    if (!productInfo?._id) {
      error.component =
        language[this.props.defaultLanguage].no_product_available_for_labeling;

      this.setState({ error });

      return;
    }

    if (Object.keys(error).length > 0) {
      this.setState({
        isSubmitting: false,
      });
      return;
    }

    // this.setState({
    //   isSubmitting: true,
    // });

    let requestedBody = {
      donation: donation?._id,
      addedBy: user.id,
      component: productInfo.component._id,
      name: productInfo.name,
      code: productInfo.code,
      volume: productInfo.volume,
      productId: productInfo._id,
      center: donation?.center?._id,
      centerSite: donation?.centerSite?._id,
      // status: "pending",
      bloodGroup: donation.bloodGroup,
      phlebotomyDate:
        moment(donation.collectionDate).format("YYYY-MM-DD") +
        " " +
        donation.endTime,
      concentrationValue: this.state.concentrationValue,
    };

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };
    axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
        });

        if (data?.data?.status === "transformed") {
          return toastMessage("error", "Product no longer available");
        }

        // if (data?.data?.isLabeled) {
        //   return toastMessage("error", "Product already labelled");
        // }

        toastMessage(
          "success",
          `Label for ${component.label} was generated successfully`
        );

        this.handleShowModal("showModal", component.code, data.data);

        this.setState({
          donation: {},
          donationNumber: "",
          concentrationValue: "",
          isSubmitting: false,
          showProductModal: false,
        });
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });

        toastMessage("error", error);

        return;
      });
  };

  handleSelectProduct(productInfo) {
    this.onGenerateLabel(this.state.donation);
    this.setState({ productInfo });
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <form>
              <div className="col-md-6">
                <div className="col-md-12">
                  <Select
                    loadOptions={(search) => this.getComponents(true, search)}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].component}
                    required
                    value={this.state.component}
                    onChange={(e) => this.onChangeText("component", e)}
                    error={this.state.error.component}
                    isLoading={this.state.isFetchingComponents}
                  />
                  <Input
                    placeholder={
                      language[this.props.defaultLanguage].search + "..."
                    }
                    label={language[this.props.defaultLanguage].donation_number}
                    required
                    value={this.state.donationNumber}
                    error={this.state.error.donationNumber}
                    onChange={(e) => this.onChangeText("donationNumber", e)}
                  />

                  {/* {<div>{this.state.component?.code}</div>} */}

                  {(this.state.component?.code === "12000" ||
                    this.state.component?.code === "13000" ||
                    this.state.component?.name?.includes("PLATELETS")) && (
                    <Input
                      placeholder={"Type Concentration"}
                      label={"Concentration"}
                      required
                      value={this.state.concentrationValue}
                      error={this.state.error.concentrationValue}
                      onChange={(e) =>
                        this.onChangeText("concentrationValue", e)
                      }
                    />
                  )}

                  {this.state.isFetchingProduct && (
                    <Button
                      text={language[this.props.defaultLanguage].generate_label}
                      onPress={(e) => {
                        e.preventDefault();
                        this.getDonation();
                      }}
                      isSubmitting={this.state.isSubmitting}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showProductModal")}
          show={this.state.showProductModal}
          showHeaderBottomBorder={false}
        >
          {this.state.isSubmitting ? (
            <Loading />
          ) : (
            <div className="card-body">
              {this.state.products.map((el, i) => {
                return (
                  <div
                    className="d-flex gap-3"
                    key={i}
                    style={{
                      minHeight: 80,
                      alignItems: "center",
                      borderBottom:
                        i === 0 ? "1px solid var(--border-color)" : "none",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <p className="text-bold">{el.name}</p>
                      <p className="text-primary badge badge-default">
                        {el.category}
                      </p>
                    </div>
                    <div>
                      <Button
                        text={language[this.props.defaultLanguage].select}
                        onPress={() => this.handleSelectProduct(el, i)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          showHeaderBottomBorder={false}
        >
          <PrintableLabel
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            productId={this.state?.selectedData?._id}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ComponentLabeling);
