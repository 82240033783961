import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import { Table } from "../../Table/index.js";
import { DeleteModal, Modal } from "../../Modal/index.js";
import { defineRole } from "../../../utils/handleAccessRoles.js";
import icons from "../../../constants/icons.js";
import { ROLE_PRODUCTION } from "../../../constants/ROLES.js";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage.js";
import {
  fetchInventoryData,
  deleteInventory,
} from "../../../utils/queries/production/inventoryQuery.js";
import toastMessage from "../../../utils/toastMessage.js";
import NewReception from "./NewReception.js";
import moment from "moment";
import handleFilters from "../../../utils/handleFilters.js";
import CentersFilter from "../../Filters/CentersFilter.js";
import CardCount from "../../CardCount/CardCount.js";
import { BloodGroupFilter, DateFilter } from "../../Filters/index.js";

let copyData = [];

class ProductionReception extends React.Component {
  state = {
    user: {},
    modalTitle: "",
    selectedData: {},
    error: "",
    delete_password: "",
    delete_value: "",
    page: 1,
    limit: 10,
    data: [],
    filters: {
      startDate: moment().startOf("D").format("YYYY-MM-DD "),
      endDate: moment().endOf("D").format("YYYY-MM-DD"),
    },
    counts: {
      incoming: 0,
      received: 0,
      rejected: 0,
    },
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({
      user,
    });

    this.fetchData(true, this.state.filters);
  };

  componentDidUpdate(prevProps) {
    if (this.props.refreshList !== prevProps.refreshList) {
      this.fetchData(true, this.state.filters);
    }
  }

  fetchData = async (isLoading, search = {}) => {
    const user = await getStorage();

    let updateFilters = {
      ...this.state.filters,
      ...search,
    };
    this.setState({ filters: updateFilters });
    this.getData(isLoading, updateFilters);
    this.getCount(
      isLoading,
      {
        ...updateFilters,
      },
      "incoming"
    );
    this.getCount(
      isLoading,
      {
        ...updateFilters,
        receivedBy: user.id,
      },
      "received"
    );
    this.getCount(
      isLoading,
      {
        ...updateFilters,
        receivedBy: user.id,
      },
      "rejected"
    );
    this.getCount(
      isLoading,
      {
        ...updateFilters,
        receivedBy: user.id,
      },
      "blocked"
    );
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchInventoryData(defaultLanguage, {
        page,
        limit,
        ...handleFilters(search),
        sortField: "updatedAt",
      });

      this.setState({
        data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCount = async (isLoading, search = {}, status) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      let query = {
        page,
        limit,
        ...handleFilters(search),
        sortField: "updatedAt",
        isCount: true,
        status,
      };

      const { count } = await fetchInventoryData(defaultLanguage, query);

      let { counts } = this.state;

      counts[status] = count;

      this.setState({
        counts,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].equipment,
        key: "equipment.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item.donation._id}/${item.donation.donor}`),
      },
      {
        title: language[this.props.defaultLanguage].label,
        key: "label",
        styles: {
          textTransform: "uppercase",
        },
      },
      // {
      //   title: language[this.props.defaultLanguage].reason,
      //   key: "reason",
      //   styles: {
      //     textTransform: "uppercase",
      //   },
      // },
      {
        title: language[this.props.defaultLanguage].weight,
        key: "weight",
      },
      {
        title: language[this.props.defaultLanguage].transportation_temperature,
        key: "transportationTemperature",
      },
      {
        title: language[this.props.defaultLanguage].phlebotomy_date,
        key: "donation.collectionDate",
        isMoment: true,
        formatTime: "ll",
      },
      {
        title: language[this.props.defaultLanguage].reception_date,
        key: "receivedDate",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].reason,
        key: "reason",
      },
      {
        title: language[this.props.defaultLanguage].received_by,
        key: "receivedBy.name",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    const donation = await bloodDonationQuery(this.props.defaultLanguage, {
      donationNumber: search || search !== "" ? search : undefined,
    });

    if (donation && donation.length === 0) {
      this.setState({
        searching: false,
      });
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].no_data
      );
    }
    await this.getData(true, {
      donation: donation[0]._id,
    });

    this.setState({
      searching: false,
    });
  };

  onDelete = async () => {
    try {
      if (this.state.selectedData.isLabelled)
        return toastMessage("error", "Unable to delete labelled product");
      this.setState({ isDeleting: true });

      await deleteInventory(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  render() {
    return (
      <>
        <div className="row">
          <div style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <CentersFilter
                handleApplyFilters={(isLoading, filters) =>
                  this.fetchData(isLoading, filters)
                }
              />

              <BloodGroupFilter
                handleApplyFilters={(isLoading, filters) =>
                  this.fetchData(isLoading, filters)
                }
              />
              <DateFilter
                handleApplyFilters={(isLoading, filters) =>
                  this.fetchData(isLoading, filters)
                }
                startDate={moment().startOf("D").format("YYYY-MM-DD")}
                endDate={moment().endOf("D").format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.counts.incoming}
              title={language[this.props.defaultLanguage].incoming}
              color="orange"
              icon="schedule"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                this.fetchData(true, {
                  ...this.state.filters,
                  status: "incoming",
                })
              }
            />
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.counts.received}
              title={language[this.props.defaultLanguage].received}
              color="green"
              icon="check"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                this.fetchData(true, {
                  ...this.state.filters,
                  status: "received",
                })
              }
            />
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.counts.rejected}
              title={language[this.props.defaultLanguage].rejected}
              color="red"
              icon="check"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                this.fetchData(true, {
                  ...this.state.filters,
                  status: "rejected",
                })
              }
            />
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.counts.blocked}
              title={language[this.props.defaultLanguage].blocked}
              color="purple"
              icon="check"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                this.fetchData(true, {
                  ...this.state.filters,
                  status: "blocked",
                })
              }
            />
          </div>
        </div>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          addButtonText={language[this.props.defaultLanguage].create}
          handleAddPressed={() => this.handleShowModal("showPrint")}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          fetchData={(isLoading, filters) => this.getData(isLoading, filters)}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.fetchData(true, this.state.filters),
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_PRODUCTION,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].view,
              icon: icons.eye_on,
              onPress: (item) =>
                this.handleShowModal("showModal", item.code, item),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_PRODUCTION,
                operation: "delete",
              }),
              icon: icons.delete,
              name: language[this.props.defaultLanguage].delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete_account
              ),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
        >
          {}
          <NewReception
            {...this.state.selectedData}
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={() => this.fetchData(true, this.state.filters)}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={() => this.fetchData(true, this.state.filters)}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ProductionReception);
