import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import requestQuery from "../../../utils/queries/requestQuery.js";
import CardHeader from "../../Header/CardHeader.js";
import { Separator } from "../../Separator";
import { Tabs } from "../../Tabs";
import moment from "moment";
import Products from "./products";
import capitalizeText from "../../../utils/capitalizeText.js";
import ABO from "./ABO/index.js";
import { fetchSampleCollectedResult } from "../../../utils/queries/sample-collected-result.js";
import toastMessage from "../../../utils/toastMessage.js";

class RequestInfo extends React.Component {
  state = {
    error: {},
    distributors: [],
    data: [],
    csvData: [],
    sampleCollected: {},
  };

  componentDidMount = async () => {
    this.getRequestInfo(true);
  };

  componentDidUpdate(nextProps) {
    if (this.props.isRefreshing !== nextProps.isRefreshing) {
      this.getRequestInfo(true);
    }
  }

  returnFilters() {
    const { requestId } = this.props;
    return {
      id: requestId,
    };
  }

  getRequestInfo = async (isLoading) => {
    try {
      const { defaultLanguage, requestNo } = this.props;

      this.setState({ isLoading });

      const { data } = await requestQuery(defaultLanguage, {
        requestNo,
      });

      this.setState(
        {
          data,
          isLoading: false,
        },
        () => {
          this.getSampleCollectionData(true, data[0]?._id);
        }
      );
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  renderInfoPlainText(key, value) {
    return (
      <div className="d-flex">
        <span style={{ marginRight: "0.3rem" }}>{key}: </span>{" "}
        <span>
          <b>{value}</b>
        </span>
      </div>
    );
  }

  getSampleCollectionData = async (isLoading, requestId) => {
    try {
      this.setState({ isLoading });

      const { defaultLanguage } = this.props;

      const { data } = await fetchSampleCollectedResult(defaultLanguage, {
        page: 1,
        limit: 50,
        request: requestId,
      });

      this.setState({
        isLoading: false,
        sampleCollected: data.length > 0 ? data[0] : {},
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      toastMessage("error", error);
    }
  };
  render() {
    return (
      <>
        <div style={{ marginBottom: "1rem" }} />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1, marginBottom: "1rem" }}>
            {this.state.data.length > 0 && (
              <>
                <div className={`badge badge-${this.state.data[0].status}`}>
                  {language[this.props.defaultLanguage].status}:{" "}
                  {capitalizeText(this.state.data[0].status)}
                </div>
                {this.state.data[0].shipmentNumber && (
                  <p className="text-primary">
                    {language[this.props.defaultLanguage].shipmentNumber}:{" "}
                    {this.state.data[0].shipmentNumber}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        {this.state.data.length > 0 && (
          <div className="card mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    {this.state.data[0].patient && (
                      <div className="col-md-3">
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].patient,
                          this.state.data[0].patient.firstName +
                            " " +
                            this.state.data[0].patient.lastName
                        )}
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].patient_id,
                          this.state.data[0].patient.patientId
                        )}
                      </div>
                    )}

                    {this.state.data[0].patient && (
                      <div className="col-md-3">
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].gender,
                          this.state.data[0].patient.gender
                        )}
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].date_of_birth,
                          this.state.data[0].patient.dob
                        )}
                      </div>
                    )}

                    {this.state.data[0].patient && (
                      <div className="col-md-3">
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].service,
                          this.state.data[0].patient?.service?.name
                        )}
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].room_number,
                          this.state.data[0].patient?.roomNumber
                        )}
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].bed_number,
                          this.state.data[0].patient?.bedNumber
                        )}
                      </div>
                    )}

                    <Separator
                      title="Collected Information"
                      titleClassName="text-primary"
                    />

                    {
                      <div className="col-md-3">
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].request_number,
                          this.state.data[0].requestNo
                        )}
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].urgent,
                          this.state.data[0].isUrgent ? "YES" : "NO"
                        )}
                      </div>
                    }

                    {
                      <div className="col-md-3">
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].type,
                          this.state.data[0].type
                        )}
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].request_type,
                          this.state.data[0].requestType
                        )}
                      </div>
                    }

                    {this.state.data[0].account && (
                      <div className="col-md-3">
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].requested_by,
                          this.state.data[0].account?.name
                        )}
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].pin,
                          this.state.data[0].account?.pin
                        )}
                        {this.renderInfoPlainText(
                          language[this.props.defaultLanguage].date,
                          moment(this.state.data[0].createdAt).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  {this.state.sampleCollected?.bloodGroup && (
                    <h1 style={{ color: "var(--primary-color)", fontSize: 60 }}>
                      {this.state.sampleCollected?.bloodGroup}
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.data.length > 0 && (
          <div className="card">
            <Tabs
              options={[
                {
                  title: "ABO-RH",
                  data: (
                    <ABO
                      requestId={this.state.data[0]._id}
                      patientId={this.state.data?.[0].patient?._id}
                      sampleCollected={this.state.sampleCollected}
                      getSampleCollectionData={this.getSampleCollectionData.bind(
                        this
                      )}
                    />
                  ),
                },
                {
                  title: language[this.props.defaultLanguage].products,
                  data: (
                    <Products
                      requestId={this.state.data[0]._id}
                      patientId={this.state.data?.[0].patient?._id}
                      sampleCollected={this.state.sampleCollected}
                    />
                  ),
                },
              ]}
            />
          </div>
        )}

        {/* <CardHeader title={language[this.props.defaultLanguage].packages} />
        <div style={{ marginBottom: "1rem" }} />
        {this.state.data.length > 0 && (
          <Products
            request={this.state.data[0]._id}
            patient={this.state.data[0]?.patient?._id}
            requestType={this.state.data[0].requestType}
          />
        )} */}

        {/* <Modal
          show={this.state.addProduct}
          handleClose={() => this.handleCloseModal("addProduct")}
        >
          {this.state.data.length > 0 && (
            // <BulkRequest
            //   {...this.state.data[0]}
            //   handleCloseModal={() => this.handleCloseModal("addProduct")}
            // />
            <RequestProduct
              patient={this.state.data[0]?.patient?._id}
              request={this.state.data[0]}
              handleCloseModal={() => this.handleCloseModal("addProduct")}
            />
          )}
        </Modal>
        <Modal
          show={this.state.viewRequestedProducts}
          handleClose={() => this.handleCloseModal("viewRequestedProducts")}
        >
          <NominativeRequestedProduct
            request={this.state.data[0]}
            // patient={this.state.data[0]?.patient?._id}
            // requestType={this.state.data[0].requestType}
          />
        </Modal> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RequestInfo);
