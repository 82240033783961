import React from "react";
import RequestInfo from "../../../components/Requests/nominative/RequestInfo";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";

class RequestInformationScreen extends React.Component {
  state = {
    isRefreshing: false,
  };

  handleRefresh() {
    this.setState({ isRefreshing: !this.state.isRefreshing });
  }
  render() {
    return (
      <div className="card-dashboard-content">
        <div className="card-body">
          <RequestInfo
            requestNo={this.props.routeParams.requestNo}
            isRefreshing={this.state.isRefreshing}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(RequestInformationScreen));
