import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input/index.js";
import language from "../../language/index.js";
import { Button } from "../Button/index.js";
import api from "../../utils/api/index.js";
import { fetchRejectionStatus } from "../../utils/queries/rejectionStatus.js";
import toastMessage from "../../utils/toastMessage.js";
import { getStorage } from "../../utils/storage.js";
import moment from "moment";

class ReturnRequest extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    product: "",
    donationNumber: "",
    requestNo: "",
    shipmentNumber: "",
  };

  validateForm() {
    let { error, donationNumber, product, reason } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (product === "") {
      error.product = language[this.props.defaultLanguage].product_required;
    }

    if (!reason) {
      error.reason = language[this.props.defaultLanguage].reason_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { donationNumber, product, reason } = this.state;
      const user = await getStorage();

      this.setState({
        isSubmitting: true,
      });

      let method = "POST",
        requestBody = {
          product,
          donationNumber,
          status: reason?.status,
          reasonId: reason?._id,
        };

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory/manual-return-product`,
        data: requestBody,
      };

      await api(options);

      this.setState({
        isSubmitting: false,
        donationNumber: "",
        product: "",
        reason: null,
      });

      toastMessage(
        "success",
        language[this.props.defaultLanguage].add_data_success
      );
    } catch (error) {
      console.log(error);
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isDoneSearching: false,
    });
  };

  getReturnStatus = async (isLoading, search = {}) => {
    try {
      this.setState({ isLoading });
      const { data } = await fetchRejectionStatus("dropdown", {
        page: 0,
        limit: 50,
        ...search,
      });

      this.setState({
        isLoading: false,
      });

      return data;
    } catch (error) {
      toastMessage("error", error);
    }
  };

  render() {
    return (
      <>
        <Input
          placeholder={
            language[this.props.defaultLanguage].donation_number_placeholder
          }
          label={language[this.props.defaultLanguage].donation_number}
          value={this.state.donationNumber}
          onChange={(e) => this.onChangeText("donationNumber", e)}
          error={this.state.error.donationNumber}
        />

        <Input
          placeholder={language[this.props.defaultLanguage].product_placeholder}
          label={language[this.props.defaultLanguage].product}
          value={this.state.product}
          onChange={(e) => this.onChangeText("product", e)}
          error={this.state.error.product}
        />
        <Select
          loadOptions={(e) =>
            this.getReturnStatus(true, { q: e, type: "RETURN" })
          }
          placeholder={language[this.props.defaultLanguage].reason_placeholder}
          label={language[this.props.defaultLanguage].reason}
          value={this.state.reason}
          onChange={(e) => this.onChangeText("reason", e)}
          error={this.state.error.reason}
        />

        {this.props.handleCloseModal && (
          <Button
            className="btn-default"
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
          />
        )}
        <Button
          text={language[this.props.defaultLanguage].submit}
          isSubmitting={this.state.isSubmitting}
          onPress={this.onSubmit.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ReturnRequest);
