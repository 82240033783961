import React from "react";
import BulkRequestInfo from "../../../components/Requests/bulkRequestInfo";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";

class BulkRequestInformationScreen extends React.Component {
  state = {
    isRefreshing: false,
  };

  handleRefresh() {
    this.setState({ isRefreshing: !this.state.isRefreshing });
  }
  render() {
    return (
      <div className="card-dashboard-content">
        <div className="card-body">
          <BulkRequestInfo
            requestNo={this.props.routeParams.requestNo}
            isRefreshing={this.state.isRefreshing}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(
  withRouter(BulkRequestInformationScreen)
);
