import { getStorage } from "../../../../utils/storage";
import bloodDonationQuery from "../../../../utils/queries/bloodDonationQuery";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../../utils/handleAccessRoles";
import { fetchInventories } from "../../../../utils/queries/production/inventoryQuery";
import { DateFilter } from "../../../Filters";
import { ROLE_REQUEST } from "../../../../constants/ROLES";
import ViewInventory from "../../../inventory/ViewInventory";
import { connect } from "react-redux";
import language from "../../../../language";
import CardCount from "../../../CardCount/CardCount";
import React from "react";
import { Table } from "../../../Table";
import icons from "../../../../constants/icons";
import { Modal } from "../../../Modal";

class HistoricalData extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    suspendReason: "",
    counts: {
      transferred: 0,
      received: 0,
      returned: 0,
    },
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.fetchData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.fetchData(true);
    }
  }

  fetchData(isLoading, search = {}) {
    this.getData(isLoading, { ...search });
    this.getCountsData(isLoading, "transferred");
    this.getCountsData(isLoading, "returned");
    this.getCountsData(isLoading, "received");
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });
  }

  returnFilters = async (search = {}) => {
    const { page, limit } = this.state;
    const { centerRole, centerSiteRole, stock } = await defineRoleCenterSite();
    const { filters } = this.props;
    return {
      page,
      limit,
      donation: this.props.donationId,
      id: this.props.inventoryId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      center: centerRole || filters?.centers?.map((el) => el.value),
      centerSite: centerSiteRole || filters?.centerSite?.map((el) => el.value),
      ...search,
      stock,
      isLabeled: true,
    };
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const filters = await this.returnFilters(search);

      if (search?.donationNumber) {
        const donation = await bloodDonationQuery(defaultLanguage, {
          ...search,
        });

        if (donation?.length > 0) {
          filters.donation = donation[0]?._id || undefined;
        } else {
          this.setState({ isLoading: false });
          return;
        }
      }

      const { data, count } = await fetchInventories(defaultLanguage, filters);

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCountsData = async (isLoadingCounts, type) => {
    try {
      const { defaultLanguage } = this.props;
      const user = await getStorage();

      this.setState({ isLoadingCounts });

      const filters = await this.returnFilters();

      let params = {
        ...filters,
        isCount: true,
        status: type,
      };

      if (type === "received" || type === "returned") {
        params.receivedBy = user.id;
      }

      const { data } = await fetchInventories(defaultLanguage, params);

      let { counts } = this.state;

      counts[type] = data;

      this.setState({
        counts,
        isLoadingCounts: false,
      });
    } catch (error) {
      this.setState({
        isLoadingCounts: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donation.donationNumber",
        // type: "link",
        // onPress: (item) =>
        //   (window.location.href = `/dashboard/production/center/${item?.center?._id}/${item?.center?.name}/${item?.centerSite?._id}/${item?.centerSite?.name}`),
      },
      {
        title: language[this.props.defaultLanguage].code,
        key: "code",
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].volume,
        key: "volume",
      },
      {
        title: language[this.props.defaultLanguage].expired_date,
        key: "expiredDate",
        formatTime: "lll",
        isMoment: true,
      },

      {
        title: language[this.props.defaultLanguage].labelled_by,
        key: "verifiedBy.pin",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },

      {
        title: language[this.props.defaultLanguage].source,
        key: "sourceStock.name",
      },
      {
        title: language[this.props.defaultLanguage].current_stock,
        key: "stock.name",
      },

      {
        title: language[this.props.defaultLanguage].dispatched_date,
        key: "transferDate",
        formatTime: "lll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });
    await this.fetchData(true, {
      donationNumber: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };
  render() {
    return (
      <>
        <div className="row">
          {/* <div style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <DateFilter />
            </div>
          </div> */}
          <div className="col-md-4">
            <CardCount
              count={this.state.counts.transferred}
              title={language[this.props.defaultLanguage].pending}
              color="orange"
              icon="schedule"
              isLoading={this.state.isLoadingCounts}
            />
          </div>
          <div className="col-md-4">
            <CardCount
              count={this.state.counts.received}
              title={language[this.props.defaultLanguage].received}
              color="#078ECE"
              icon="print"
              isLoading={this.state.isLoadingCounts}
            />
          </div>
          <div className="col-md-4">
            <CardCount
              count={this.state.counts.transformed}
              title={language[this.props.defaultLanguage].returned}
              color="gray"
              icon="restart_alt"
              isLoading={this.state.isLoadingCounts}
            />
          </div>
        </div>

        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          addSearchButton
          search_text={this.state.search_text}
          searching={this.state.searching}
          handleSearch={(e) => this.onChangeText("search_text", e)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.fetchData(true),
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_REQUEST,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].view,
              icon: icons.eye_on,
              onPress: (item) =>
                this.handleShowModal("showModal", item.code, item),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <ViewInventory
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            donationId={
              this.props.donationId || this.state?.selectedData?.donation?._id
            }
            disabled={this.state.isDisabled}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(HistoricalData);
