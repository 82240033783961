import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import language from "../../language";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";
class MyProfile extends React.Component {
  state = {
    email: "",
    displayName: "",
    phoneNumber: "",
    error: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();
    this.setState({ ...user, centerRole, centerSiteRole });
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <Input
              placeholder={
                language[this.props.defaultLanguage].username_placeholder
              }
              label={language[this.props.defaultLanguage].username}
              required
              value={this.state.username}
              onChange={(e) => this.onChangeText("username", e)}
              error={this.state.error.username}
              leftIcon={icons.user}
              disabled
            />
            <Input
              placeholder="Enter email address"
              label="Email Address"
              required
              value={this.state.email}
              onChange={(e) => this.onChangeText("email", e)}
              leftIcon={icons.email}
              disabled
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MyProfile);
