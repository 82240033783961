import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import toastMessage from "../../../utils/toastMessage.js";
import api from "../../../utils/api";
import Input from "../../Input/Input.js";
import Button from "../../Button/Button.js";
import Modal from "../../Modal/Modal.js";
import { getStorage } from "../../../utils/storage.js";
import { fetchCurrentInventoriesPerProductType } from "../../../utils/queries/production/inventoryQuery.js";
import Products from "../requestForm/product/Products.js";
import icons from "../../../constants/icons.js";
import RespondRequest from "../requestForm/respondRequest.js";

class CurrentStock extends React.Component {
  state = {
    error: {},
    data: [],
    csvData: [],
    packages: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    this.getCurrentStock(true);
  };

  getCurrentStock = async (isLoading) => {
    try {
      this.setState({
        isLoading,
      });

      const { data } = await fetchCurrentInventoriesPerProductType(
        this.props.defaultLanguage,
        {
          productTypes: this.props.productType,
        }
      );

      this.setState({
        data: data
          .map((el) => {
            const diff = el.control.maxStock - el.currentStock;
            if (el.control.maxStock > el.currentStock) {
              return {
                ...el,
                diff,
              };
            }
          })
          .filter(Boolean),
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage("error", error);
    }
  };

  returnFilters() {
    const { requestId } = this.props;
    return {
      id: requestId,
    };
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onChangeText({ field, index, value }) {
    const { data } = this.state;

    data[index][field] = value.target.value;
    console.log(data);
    this.setState({ data });
  }

  onSubmit = async () => {
    this.setState({ isConfirming: true });

    let count = 0,
      countSuccess = 0,
      countFailed = 0,
      errorMessage = "";

    for (let el of this.state.data) {
      const { success, failed, error } = await this.handleSubmit(el);
      count += 1;

      if (success) {
        countSuccess += 1;
      }

      if (failed) {
        countFailed += 1;
      }

      errorMessage = error;
    }

    if (count === this.state.data.length) {
      this.setState({ isConfirming: false });
      this.handleCloseModal("showToConfirm");

      this.getCurrentStock(true);

      if (countSuccess > 0) {
        toastMessage(
          "success",
          countSuccess +
            " " +
            language[this.props.defaultLanguage].add_data_success
        );
      }

      if (countFailed > 0) {
        toastMessage("error", countFailed + " " + errorMessage);
      }
    }
  };

  handleSubmit = async (el) => {
    try {
      let requiredBody = {},
        method = "POST";

      requiredBody = {
        type: "bulk",
        request: this.props.request,
        productType: el.productType._id,
        numberOfPackages: el.diff,
        bloodGroup: el.bloodGroup,
        currentStock: el.currentStock,
        toBeRequested: el.diff,
      };

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/product-request`,
        data: requiredBody,
      };

      await api(options);

      this.setState({ isSubmitting: false });

      return {
        success: true,
        failed: false,
      };
    } catch (error) {
      this.setState({ isSubmitting: false });
      console.log(error);

      return {
        failed: true,
        success: false,
        error: error?.response?.data?.error,
      };
    }
  };

  render() {
    return (
      <>
        <div className="card" style={{ overflowY: "auto", maxHeight: "100vh" }}>
          <div className="card-header">
            <div className="d-flex gap-2">
              {this.props.status === "pending" &&
                this.state.user.stock === this.props.stock && (
                  <Button
                    text={language[this.props.defaultLanguage].request_product}
                    isSubmitting={this.state.isSubmitting}
                    onPress={() => this.handleShowModal("showToConfirm")}
                    icon={icons.add}
                  />
                )}
              {/* <Button
                text={
                  language[this.props.defaultLanguage].view_requested_products
                }
                isSubmitting={this.state.isSubmitting}
                onPress={() => this.handleShowModal("showRequestedProduct")}
                className="btn-transparent btn-bordered"
              /> */}

              {console.log({
                user: this.state.user.stock,
                props: this.props.bloodBank,
              })}

              {this.props.bloodBank?._id === this.state.user.stock && (
                <Button
                  text={language[this.props.defaultLanguage].add_shipment}
                  onPress={() => this.handleShowModal("showAddShipment")}
                  icon={icons.add}
                />
              )}

              {/* {this.props.status === "pending" &&
                this.state.user.stock === this.props.stock && (
                  <Button
                    text={language[this.props.defaultLanguage].request_product}
                    isSubmitting={this.state.isSubmitting}
                    onPress={() => this.handleShowModal("showToConfirm")}
                    icon={icons.add}
                  />
                )} */}
            </div>
          </div>
          <div className="card-body">
            <Products
              request={this.props.request}
              status={this.props.status}
              stock={this.props.stock}
              type={this.props.type}
            />
            {/* <div className="row">
              <div className="col-md-12">
                <Table
                  data={this.state.data}
                  isLoading={this.state.isLoading}
                  headers={[
                    {
                      title: language[this.props.defaultLanguage].product_type,
                      key: "productType.name",
                    },
                    {
                      title: language[this.props.defaultLanguage].blood_group,
                      key: "bloodGroup",
                      styles: {
                        textTransform: "uppercase",
                      },
                    },

                    {
                      title: language[this.props.defaultLanguage].current_stock,
                      key: "currentStock",
                    },
                    {
                      title:
                        language[this.props.defaultLanguage]
                          .stock_to_be_requested,
                      key: "diff",
                    },
                    {
                      title: language[this.props.defaultLanguage].min_stock,
                      key: "control.minStock",
                    },
                    {
                      title: language[this.props.defaultLanguage].max_stock,
                      key: "control.maxStock",
                    },
                  ]}
                />
              </div>
            </div> */}
          </div>
        </div>
        <Modal
          show={this.state.showToConfirm}
          title={language[this.props.defaultLanguage].confirm_request}
          handleClose={() => this.handleCloseModal("showToConfirm")}
          size="lg"
        >
          <div className="card-body">
            <div className="row">
              {this.state.data.map((el, i) => {
                return (
                  <div className="col-md-4">
                    <Input
                      label={el.productType.name + " (" + el.bloodGroup + ")"}
                      type="number"
                      value={el.diff}
                      max={el.control.maxStock}
                      min={el.control.minStock}
                      onChange={(e) =>
                        this.onChangeText({
                          field: "diff",
                          index: i,
                          value: e,
                        })
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-footer">
            <Button
              text={language[this.props.defaultLanguage].close}
              onPress={() => this.handleCloseModal("showToConfirm")}
              className="btn-default"
            />
            <Button
              text={language[this.props.defaultLanguage].confirm}
              onPress={() => this.onSubmit()}
              isSubmitting={this.state.isConfirming}
            />
          </div>
        </Modal>

        <Modal
          show={this.state.showAddShipment}
          title={language[this.props.defaultLanguage].shipment}
          handleClose={() => this.handleCloseModal("showAddShipment")}
          size="lg"
        >
          <div className="card-body">
            {this.props?.requestInfo?._id && (
              <RespondRequest
                requestInfo={this.props.requestInfo}
                requestId={this.props.requestInfo?._id}
                requestNo={this.props.requestInfo?.requestNo}
                source={this.props.requestInfo?.bloodBank?._id}
                bloodBank={this.props.requestInfo?.hospital?.bloodBank}
              />
            )}
          </div>
        </Modal>

        {/* <Modal
          show={this.state.showRequestedProduct}
          title={language[this.props.defaultLanguage].requested_products}
          handleClose={() => this.handleCloseModal("showRequestedProduct")}
          size="lg"
        >
          <div className="card-body">
            <Products
              request={this.props.request}
              status={this.props.status}
              stock={this.props.stock}
              type={this.props.type}
            />
          </div>
          <div className="modal-footer">
            <Button
              text={language[this.props.defaultLanguage].close}
              onPress={() => this.handleCloseModal("showRequestedProduct")}
              className="btn-default"
            />
          </div>
        </Modal> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CurrentStock);
