import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { getStorage } from "../../../utils/storage";
import { DashboardHeader } from "../../../components/Header";
import accessMenu from "../../../utils/accessMenu";
import DirectTransfer from "../../../components/Requests/shipping";
import Reception from "../../../components/Requests/shipping/reception";
import SearchShipmentQueue from "../../../components/Requests/shipping/Search";
import SearchRequest from "../../../components/Requests/SearchRequest";
import ShipmentReport from "../../../components/Requests/shipping/report";
import CheckboxQuestionnaire from "../../../components/Input/CheckboxQuestionnaire";

class ShipmentManagementScreen extends React.Component {
  state = {
    user: {},
    searchType: "requestNumber",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  handleCheck(type, value) {
    this.setState({ [type]: value });
  }

  render() {
    let tabOptions = [];
    let { canReadBloodRequestAccess, canCreateBloodRequestAccess } =
      accessMenu();

    if (canCreateBloodRequestAccess) {
      tabOptions.push(
        {
          title: language[this.props.defaultLanguage].search,
          data: (
            <>
              <CheckboxQuestionnaire
                answers={[
                  {
                    key: "searchType",
                    value: "requestNumber",
                    onPress: () =>
                      this.handleCheck("searchType", "requestNumber"),
                  },
                  {
                    key: "searchType",
                    value: "shipmentNumber",
                    onPress: () =>
                      this.handleCheck("searchType", "shipmentNumber"),
                  },
                ]}
                checked={this.state.searchType}
              />
              {this.state.searchType === "requestNumber" && <SearchRequest />}
              {this.state.searchType === "shipmentNumber" && (
                <SearchShipmentQueue />
              )}
            </>
          ),
        },
        {
          title: language[this.props.defaultLanguage].direct_transfer,
          data: <DirectTransfer />,
        },
        {
          title: language[this.props.defaultLanguage].historical_data,
          data: <ShipmentReport />,
        }

        // {
        //   title: language[this.props.defaultLanguage].reception,
        //   data: <Reception />,
        // }
      );
    }

    return (
      <div>
        <div className='card-dashboard-content'>
          <div className='card-body'>
            <DashboardHeader
              title={language[this.props.defaultLanguage].distribution}
            />
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ShipmentManagementScreen));
