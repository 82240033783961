import toastMessage from "../../toastMessage";
import formatSelectData from "../../formatSelectData";
import { getStorage } from "../../storage";
import language from "../../../language";
import { defineRole } from "../../handleAccessRoles";
import { ROLE_BLOOD_TEST } from "../../../constants/ROLES";
import api from "../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (lng = "english", params) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_BLOOD_TEST,
      operation: "read",
    });

    if (!canAccess) return;

    let filters = {
      ...params,
    };

    if (filters.startCollectionDate || filters.q) {
      delete filters.startDate;
      delete filters.endDate;
    }

    if (filters.endCollectionDate || filters.q) {
      delete filters.endDate;
    }

    if (filters.q) {
      delete filters.startDate;
      delete filters.endDate;
      delete filters.startCollectionDate;
      delete filters.endCollectionDate;
    }

    if (filters.donation || filters.donor) {
      delete filters.startDate;
      delete filters.endDate;
      delete filters.startCollectionDate;
      delete filters.endCollectionDate;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/bloodtest`,
      params: filters,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let data = queryRes.data;

    if (params.type === "dropdown") {
      return formatSelectData(queryRes.data, "description", "_id");
    }

    return data;
  } catch (error) {
    toastMessage("error", error);
  }
};

export default fetchData;

export const searchBloodTest = async (
  lng = "english",
  { page, limit, type, donorNumber }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_BLOOD_TEST,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/bloodtest/search`,
      params: {
        page: page,
        limit: limit,
        donorNumber,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "description", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deletebloodtest = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/bloodtest`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_test);
  } catch (error) {
    toastMessage("error", error);
  }
};

export const bloodTestStatistic = async (
  lng = "english",
  {
    page,
    limit,
    center,
    centerSite,
    serologyTested,
    bloodGroupingTested,
    type,
    startCollectionDate,
    endCollectionDate,
  }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_BLOOD_TEST,
      operation: "read",
    });

    if (!canAccess) return;
    const options = {
      method: "GET",
      url: `${API_URL}/bloodTest/bloodTestStatistics`,
      params: {
        page: page,
        limit: limit,
        center,
        centerSite,
        serologyTested,
        bloodGroupingTested,
        type,
        startCollectionDate,
        endCollectionDate,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let data = queryRes.data;

    return data;
  } catch (error) {
    toastMessage("error", error);
  }
};
