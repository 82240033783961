import moment from "moment";

export default (filters = {}) => {
  let formateFilters = {};

  if (filters.centers && filters.centers.length > 0) {
    formateFilters.center = [];
    filters.centers.forEach((el) => {
      formateFilters.center.push(el.value);
    });
  }

  if (filters.centerSites && filters.centerSites.length > 0) {
    formateFilters.centerSite = [];
    filters.centerSites.forEach((el) => {
      formateFilters.centerSite.push(el.value);
    });
  }

  if (filters.bloodGroups && filters.bloodGroups.length > 0) {
    formateFilters.bloodGroup = [];
    filters.bloodGroups.forEach((el) => {
      formateFilters.bloodGroup.push(el.value);
    });
  }

  if (filters.startDate) {
    formateFilters.startDate = moment(filters.startDate)
      .startOf("day")
      .format("YYYY-MM-DD HH:mm");
  }

  if (filters.endDate) {
    formateFilters.endDate = moment(filters.endDate)
      .endOf("day")
      .format("YYYY-MM-DD HH:mm");
  }

  if (filters.donation) {
    formateFilters.donation = filters.donation;
  }

  if (filters.bloodGroup) {
    formateFilters.bloodGroup = filters.bloodGroup;
  }

  if (filters.bloodGroups && Array.isArray(filters.bloodGroups)) {
    formateFilters.bloodGroup = filters.bloodGroups.map((el) => el.value);
  }

  if (filters.receivedBy) {
    formateFilters.receivedBy = filters.receivedBy;
  }

  if (filters.q) {
    formateFilters.q = filters.q;
  }

  if (filters.id) {
    formateFilters.id = filters.id;
  }

  if (filters.donor) {
    formateFilters.donor = filters.donor;
  }

  if (filters.donation) {
    formateFilters.donation = filters.donation;
  }

  if (filters.tested) {
    formateFilters.tested = filters.tested;
  }

  if (filters.q) {
    formateFilters.q = filters.q;
  }

  if (filters.category) {
    formateFilters.category = filters.category;
  }

  if (filters.status) {
    formateFilters.status = filters.status;
  }

  if (filters.productTypes && Array.isArray(filters.productTypes)) {
    formateFilters.productType = filters.productTypes.map((el) => el.value);
  }

  if (filters.page) {
    formateFilters.page = filters.page;
  }

  if (filters.limit) {
    formateFilters.limit = filters.limit;
  }

  if (filters.donationNumber) {
    formateFilters.donationNumber = filters.donationNumber;
  }

  if (filters.donation) {
    formateFilters.donation = filters.donation;
  }

  console.log(filters);

  if (filters["shipmentInfo.status"]) {
    formateFilters["shipmentInfo.status"] = filters["shipmentInfo.status"];
  }

  console.log("formateFilters", formateFilters);

  return formateFilters;
};
