import language from "../language";
import { bloodCenterApi } from "./APIS";
import {
  ROLE_ALERT,
  ROLE_ANALYTICS,
  ROLE_ASSESSMENT,
  ROLE_CENTER,
  ROLE_CLUB,
  ROLE_COLLECTIONS,
  ROLE_DISPATCH,
  ROLE_DONORS,
  ROLE_HOSPITAL,
  ROLE_JOB_POSITION,
  ROLE_MAP_INSIGHTS,
  ROLE_MOBILIZATION,
  ROLE_REPORTS,
  ROLE_SERVICE,
  ROLE_ACCOUNT,
  ROLE_VITAL_SIGNS,
  ROLE_BLOOD_TEST,
  ROLE_EQUIPMENT,
  ROLE_CENTER_SITE,
  ROLE_COLLECTION_TYPE,
  ROLE_PRODUCTION,
  ROLE_COMPONENT_SEPARATION,
  ROLE_COMPONENT_TRANSFORMATION,
  ROLE_LABELLING,
  ROLE_BLOOD_RECIPIENT,
  ROLE_HOEMOVIGILANCE,
  ROLE_BLOOD_USAGE,
  ROLE_REQUEST,
  ROLE_INVENTORY,
  ROLE_APPOINTMENT,
  ROLE_BLOOD_TEST_BLOOD_GROUPING,
  ROLE_BLOOD_TEST_SEROLOGY,
  ROLE_APPLICATION_CONFIGURATION,
  ROLE_MESSAGE_TEMPLATE,
  ROLE_PATIENT,
  ROLE_BLOOD_BANK,
  ROLE_CONTROL_PANEL,
  ROLE_LABELING,
  ROLE_PRODUCTION_PRODUCT_REJECTED,
  ROLE_PRODUCTION_RECEPTION,
  ROLE_PATIENT_LAB_RESULT,
  ROLE_PRODUCT_TYPES,
  ROLE_DISTRIBUTION,
  ROLE_SAMPLE_COLLECTED_RESULT,
} from "./ROLES";

export const menus = (lng) => {
  return [
    {
      label: language[lng].home,
      value: "Home",
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Read", value: "read", action: "_GET" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].services,
      value: ROLE_SERVICE,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].job_positions,
      value: ROLE_JOB_POSITION,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].access_roles,
      value: ROLE_MAP_INSIGHTS,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read", action: "_GET" }],
    },
    {
      label: language[lng].analytics,
      value: ROLE_ANALYTICS,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read", action: "_GET" }],
    },
    {
      label: language[lng].reports,
      value: ROLE_REPORTS,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read", action: "_GET" }],
    },
    {
      label: language[lng].donors,
      value: ROLE_DONORS,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
        _SEARCH: [],
        _BLOCK: [],
        _UNBLOCK: [],
        _READ_ON_ACTION: [],
        _DEMOGRAPHY: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        { label: "Search", value: "search", action: "_SEARCH" },
        {
          label: "Read Demography",
          value: "demography",
          action: "_demography",
        },
        { label: "Block", value: "block", action: "_BLOCK" },
        { label: "Unblock", value: "unblock", action: "_UNBLOCK" },
        {
          label: "Read on Action",
          value: "readOnAction",
          action: "_READ_ON_ACTION",
        },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].appointments,
      value: ROLE_APPOINTMENT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
        _APPROVE: [],
        _CANCEL: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        { label: "Approve", value: "approve", action: "_APPROVE" },
        { label: "Cancel", value: "cancel", action: "_CANCEL" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].vital_signs,
      value: ROLE_VITAL_SIGNS,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
        _DELETE: [],
        __READ_ON_ACTION: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        {
          label: "Read on Action",
          value: "readOnAction",
          action: "_READ_ON_ACTION",
        },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].blood_donation,
      value: ROLE_COLLECTIONS,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
        _SUSPEND: [],
        _BLOCK: [],
        _READ_BARCODE: [],
        _READ_BARCODE: [],
        _TRANSFER_SWIPE_DONATION: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
        { label: "Suspend Donation", value: "suspend", action: "_SUSPEND" },
        { label: "Block Donation", value: "block", action: "_BLOCK" },
        { label: "Read BarCode", value: "barCode", action: "_READ_BARCODE" },
        {
          label: "Transfer or Swipe Donation",
          value: "transferSwipeDonation",
          action: "_TRANSFER_SWIPE_DONATION",
        },
      ],
    },
    {
      label: language[lng].clubs,
      value: ROLE_CLUB,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].pre_assessment,
      value: ROLE_ASSESSMENT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].dispatch,
      value: ROLE_DISPATCH,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].mobilization,
      value: ROLE_MOBILIZATION,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].alerts,
      value: ROLE_ALERT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read", action: "_GET" }],
    },
    {
      label: language[lng].centers,
      value: ROLE_CENTER,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].services,
      value: ROLE_SERVICE,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].hospitals,
      value: ROLE_HOSPITAL,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].accounts,
      value: ROLE_ACCOUNT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].blood_test,
      value: ROLE_BLOOD_TEST,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _TEST_RESULT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Report",
          value: "report",
          action: "REPORT",
        },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].blood_test_blood_grouping,
      value: ROLE_BLOOD_TEST_BLOOD_GROUPING,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _CONFIRM: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        { label: "Confirmation", value: "confirm", action: "_CONFIRM" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].blood_test_serology,
      value: ROLE_BLOOD_TEST_SEROLOGY,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _CONFIRM: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        { label: "Confirmation", value: "confirm", action: "_CONFIRM" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },

    {
      label: language[lng].equipment,
      value: ROLE_EQUIPMENT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _READ_ACTION: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        {
          label: "Read on Action",
          value: "readOnAction",
          action: "_READ_ACTION",
        },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].sites,
      value: ROLE_CENTER_SITE,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].collection_type,
      value: ROLE_COLLECTION_TYPE,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _READ_ON_ACTION: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        {
          label: "Read on Action",
          value: "readOnAction",
          action: "_READ_ON_ACTION",
        },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].production,
      value: ROLE_PRODUCTION,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },

    {
      label: language[lng].inventory,
      value: ROLE_INVENTORY,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
      ],
    },

    {
      label: language[lng].production_component_separation,
      value: ROLE_COMPONENT_SEPARATION,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },

    {
      label: language[lng].production_component_transformation,
      value: ROLE_COMPONENT_TRANSFORMATION,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].blood_recipient,
      value: ROLE_BLOOD_RECIPIENT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].haemovigilance,
      value: ROLE_HOEMOVIGILANCE,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].blood_usage,
      value: ROLE_BLOOD_USAGE,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].blood_recipient,
      value: ROLE_BLOOD_RECIPIENT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },

    // {
    //   label: language[lng].request,
    //   value: ROLE_REQUEST,
    //   paths: {
    //     _POST: [],
    //     _GET: [],
    //     _PUT: [],
    //     _DISPATCH: [],
    //     _RECEPTION: [],
    //     _BLOOD_BANK_PREPARATION: [],
    //     _BLOOD_BANK_PREPARATION: [],
    //     _DISTRIBUTOR_RECEIVE: [],
    //     _HOSPITAL_RECEPTION: [],
    //   },
    //   operations: [
    //     { label: "Create", value: "create", action: "_POST" },
    //     { label: "Read", value: "read", action: "_GET" },
    //     { label: "Update", value: "update", action: "_PUT" },
    //     {
    //       label: "Delete",
    //       value: "delete",
    //       action: "_DELETE",
    //     },
    //     {
    //       label: "Blood bank or center receive and prepare blood request",
    //       value: "bloodBankPreparation",
    //       action: "_BLOOD_BANK_PREPARATION",
    //     },
    //     {
    //       label: "Distributor receive and pickup blood request/packages",
    //       value: "distributorReceive",
    //       action: "_DISTRIBUTOR_RECEIVE",
    //     },
    //     {
    //       label: "Hospital blood request reception",
    //       value: "hospitalReception",
    //       action: "_HOSPITAL_RECEPTION",
    //     },
    //   ],
    // },
    {
      label: language[lng].request,
      value: ROLE_REQUEST,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].distribution,
      value: ROLE_DISTRIBUTION,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _ADMIN: [],
      },
      operations: [
        {
          label: "Admin",
          value: "admin",
          action: "_ADMIN",
        },
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].message_template,
      value: ROLE_MESSAGE_TEMPLATE,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DISPATCH: [],
        _DELIVERED: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].configurations,
      value: ROLE_APPLICATION_CONFIGURATION,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DISPATCH: [],
        _DELIVERED: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].patient,
      value: ROLE_PATIENT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].patient_lab_results,
      value: ROLE_PATIENT_LAB_RESULT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].blood_banks,
      value: ROLE_BLOOD_BANK,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].component_labeling,
      value: ROLE_LABELING,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].control_panel,
      value: ROLE_CONTROL_PANEL,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        {
          label: "CRUD Equipment",
          value: "createEquipment",
          action: "_CreateEquipment",
        },
        {
          label: "CRUD Vital Signs",
          value: "createVitalSign",
          action: "_CreateVitalSign",
        },
        {
          label: "CRUD Blood Testing Questionnaire",
          value: "createBloodTestingQuestionnaire",
          action: "_CreateBloodTestingQuestionnaire",
        },
        {
          label: "CRUD Component Profiles",
          value: "createComponentProfile",
          action: "_CreateComponentProfile",
        },
        {
          label: "CRUD Measures",
          value: "createMeasures",
          action: "_createMeasures",
        },
        {
          label: "CRUD Access role",
          value: "createAccessRoles",
          action: "_createAccessRoles",
        },
        { label: "CRUD Vital Signs", value: "update", action: "_PUT" },
        { label: "GENERAL", value: "general", action: "_PUT" },
        {
          label: "Delete",
          value: "delete",
          action: "_DELETE",
        },
      ],
    },
    {
      label: language[lng].production_reception,
      value: ROLE_PRODUCTION_RECEPTION,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        { label: "Delete", value: "delete", action: "_DELETE" },
      ],
    },
    {
      label: language[lng].product_rejected,
      value: ROLE_PRODUCTION_PRODUCT_REJECTED,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        { label: "Delete", value: "delete", action: "_DELETE" },
      ],
    },
    {
      label: language[lng].product_type,
      value: ROLE_PRODUCT_TYPES,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        { label: "Delete", value: "delete", action: "_DELETE" },
      ],
    },
    {
      label: language[lng].inventory_controls,
      value: ROLE_PRODUCTION_PRODUCT_REJECTED,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        { label: "Delete", value: "delete", action: "_DELETE" },
      ],
    },
    {
      label: language[lng].sample_collect_result,
      value: ROLE_SAMPLE_COLLECTED_RESULT,
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _MISMATCH_BLOOD_GROUP: [],
      },
      operations: [
        { label: "Create", value: "create", action: "_POST" },
        { label: "Read", value: "read", action: "_GET" },
        { label: "Update", value: "update", action: "_PUT" },
        { label: "Delete", value: "delete", action: "_DELETE" },
        {
          label: "Mismatch blood group",
          value: "mismatchBloodGroup",
          action: "_MISMATCH_BLOOD_GROUP",
        },
      ],
    },
  ];
};

export const operationsList = [
  { label: "Create", value: "create" },
  { label: "Read", value: "read" },
  { label: "Update", value: "update" },
  {
    label: "Delete",
    value: "delete",
  },
];
