import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import StartApplicationScreen from "./Donor/StartApplicationScreen";
import PreAssessmentQuestionnaireScreen from "./Donor/PreAssessmentQuestionnaireScreen";
import DonorRegistrationScreen from "./Donor/DonorRegistrationScreen";
import NoInternet from "./NoInternet";

const BloodAssessment = () => {
  const routes = [
    {
      parentPath: "/",
      path: "start-application",
      component: <StartApplicationScreen />,
    },
    {
      parentPath: "/",
      path: "/questionnaire",
      component: <PreAssessmentQuestionnaireScreen />,
    },
    {
      parentPath: "/",
      path: "/questionnaire/:donorNumber",
      component: <PreAssessmentQuestionnaireScreen />,
    },
    {
      parentPath: "/",
      path: "/questionnaire/:donorNumber/:questionnaireId",
      component: <PreAssessmentQuestionnaireScreen />,
    },
    {
      parentPath: "/",
      path: "donor-registration",
      component: <DonorRegistrationScreen />,
    },
    {
      parentPath: "/",
      path: "/offline",
      component: <NoInternet />,
    },
  ];
  return (
    <>
      <Routes>
        {routes.map((route, index) => {
          return (
            <>
              <Route key={index} path={route.path} element={route.component} />
            </>
          );
        })}
      </Routes>
      <Outlet />
    </>
  );
};

export default BloodAssessment;
