import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import CheckboxQuestionnaire from "../../Input/CheckboxQuestionnaire";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewTransfer extends React.Component {
  state = {
    username: "",
    error: {},
    isSubmitting: false,
    donation: {},
    newDonor: "",
    user: {},
    actionTypes: [],
    type: "TRANSFER",
    newDonorNumber: "",
    donationNumber: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };

  validateSearchDonation = () => {
    let { donationNumber, error } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    this.setState({ error });
  };

  getDonationInformation = async () => {
    try {
      const { error, donationNumber } = this.state;

      await this.validateSearchDonation();

      if (Object.keys(error).length > 0) return;

      this.setState({
        isFetchingDonation: true,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        type: "dropdown",
        donationNumber,
      });

      let donation = {};

      if (data.length > 0) {
        donation = data.length > 0 ? data[0] : {};
      } else {
        toastMessage("error", language[this.props.defaultLanguage].no_data);
      }

      this.setState({
        isFetchingDonation: false,
        donation,
      });
    } catch (error) {
      this.setState({ isFetchingDonation: false });
    }
  };

  validateSearchSwipe = () => {
    let { toDonationNumber, error } = this.state;

    if (toDonationNumber === "") {
      error.toDonationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    this.setState({ error });
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;
    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  handleCheck(type, value) {
    this.setState({ [type]: value });
  }

  validateForm() {
    let { donationNumber, error, newDonorNumber, type } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (newDonorNumber === "") {
      error.newDonorNumber =
        language[this.props.defaultLanguage].new_donor_number_required;
    }

    if (type === "") {
      error.type = language[this.props.defaultLanguage].type_required;
    }
    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { donationNumber, newDonorNumber, type } = this.state,
        url = `${API_URL}/blooddonation/transfer-donation`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        donationNumber,
        newDonorNumber,
        type,
      };

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };
      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].transfer_donation_success
          );

          this.onReset();
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          toastMessage("error", error);
        });
    }
  };

  onReset() {
    this.setState({
      donationNumber: "",
      donation: {},
      newDonorNumber: "",
    });
  }

  render() {
    return (
      <>
        <>
          <div className="row">
            {/* <CheckboxQuestionnaire
              answers={[
                {
                  key: "type",
                  value: "TRANSFER",
                  label: language[this.props.defaultLanguage].transfer,
                  onPress: () => this.handleCheck("type", "TRANSFER"),
                },
                {
                  key: "type",
                  value: "SWIPE",
                  label: language[this.props.defaultLanguage].swipe,
                  onPress: () => this.handleCheck("type", "SWIPE"),
                },
              ]}
              checked={this.state.type}
            /> */}
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].enter_donation_number
                }
                label={language[this.props.defaultLanguage].donation_number}
                required
                value={this.state.donationNumber}
                onChange={(e) => this.onChangeText("donationNumber", e)}
                error={this.state.error.donationNumber}
              />
            </div>
            {this.state.donation._id && this.state.type === "TRANSFER" && (
              <div className="col-md-12">
                <Input
                  placeholder={
                    language[this.props.defaultLanguage]
                      .donor_number_placeholder
                  }
                  label={language[this.props.defaultLanguage].new_donor}
                  required
                  value={this.state.newDonorNumber}
                  onChange={(e) => this.onChangeText("newDonorNumber", e)}
                  error={this.state.error.newDonorNumber}
                />
              </div>
            )}

            {this.state.type === "SWIPE" && (
              <div className="col-md-12">
                <Input
                  placeholder={
                    language[this.props.defaultLanguage]
                      .donation_number_placeholder
                  }
                  label={language[this.props.defaultLanguage].swipe_to}
                  required
                  value={this.state.toDonationNumber}
                  onChange={(e) => this.onChangeText("toDonationNumber", e)}
                  error={this.state.error.toDonationNumber}
                />
              </div>
            )}
            <div className="d-flex gap-3">
              {this.state.donation._id ? (
                <>
                  <Button
                    text={language[this.props.defaultLanguage].reset}
                    onPress={this.onReset.bind(this)}
                    className="btn-default"
                  />
                  <Button
                    text={language[this.props.defaultLanguage].submit}
                    onPress={this.onSubmit.bind(this)}
                    isSubmitting={this.state.isSubmitting}
                    type="submit"
                  />
                </>
              ) : (
                <Button
                  text={language[this.props.defaultLanguage].search}
                  onPress={this.getDonationInformation.bind(this)}
                  isSubmitting={this.state.isFetchingDonation}
                  type="submit"
                />
              )}
            </div>
          </div>
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewTransfer);
