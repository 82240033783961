import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import { fetchProductTypes } from "../../../utils/queries/productsType";
import { fetchBloodBanks } from "../../../utils/queries/bloodBank";
import bloodGroups from "../../../constants/bloodGroups";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;
class NewControl extends React.Component {
  state = {
    minStock: "",
    maxStock: "",
    error: {},
    isSubmitting: false,
    description: "",
    available: availableOptions[0],
    bloodBanks: [],
    productTypes: [],
    bloodGroup: null,
  };

  componentDidMount = async () => {
    this.setState({ ...this.props });

    if (this.props._id && this.props._id !== "") {
      this.setState({
        minStock: this.props.minStock,
        maxStock: this.props.maxStock,
        available: this.props?.available
          ? {
              label: this.props?.available + "",
              value: this.props?.available + "",
            }
          : null,
        bloodBank: this.props?.bloodBank
          ? {
              label: this.props?.bloodBank.name,
              value: this.props?.bloodBank._id,
            }
          : null,
        productType: this.props?.productType
          ? {
              label: this.props?.productType.name,
              value: this.props?.productType._id,
            }
          : null,
        bloodGroup: this.props?.bloodGroup
          ? {
              label: this.props?.bloodGroup,
              value: this.props?.bloodGroup,
            }
          : null,
      });
    }

    console.log(this.props);
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    const value = e && e?.target ? e?.target?.value : e;

    this.setState({
      [field]: value,
      error,
    });
  }

  getBloodBank = async (q) => {
    try {
      this.setState({
        isFetchingBloodBanks: true,
      });

      const user = await getStorage();

      const data = await fetchBloodBanks(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        q: q !== "" ? q : undefined,
        _id: user.stock,
      });

      this.setState({
        bloodBanks: data,
        isFetchingBloodBanks: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingBloodBanks: false });

      return [];
    }
  };

  getProductTypes = async (search = {}) => {
    try {
      this.setState({
        isFetchingType: true,
      });

      const data = await fetchProductTypes(this.props.defaultLanguage, {
        inputType: "dropdown",
        ...search,
      });

      this.setState({
        productTypes: data,
        isFetchingCenterSite: false,
      });

      return data;
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingType: false });

      return [];
    }
  };

  validateForm() {
    let { bloodBanks, error, minStock, maxStock, productType, bloodGroup } =
      this.state;

    if (!productType) {
      error.productType =
        language[this.props.defaultLanguage].product_type_required;
    }
    if (!bloodGroup) {
      error.bloodGroup = "Blood Group is required";
    }
    if (!bloodBanks) {
      error.bloodBanks = language[this.props.defaultLanguage].hospital_required;
    }
    if (minStock === "") {
      error.minStock = language[this.props.defaultLanguage].min_stock_required;
    }
    if (maxStock === "") {
      error.maxStock = language[this.props.defaultLanguage].max_stock_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          _id,
          productType,
          bloodBank,
          available,
          minStock,
          maxStock,
          bloodGroup,
        } = this.state,
        url = `${API_URL}/inventory-controls`,
        method = "POST",
        user = await getStorage();

      const bloodGroupSelected = bloodGroup?.value || "";

      let requestBody = {
        productType: productType?.value,
        bloodBank: bloodBank?.value,
        bloodGroup: bloodGroupSelected,
        minStock,
        maxStock,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? language[this.props.defaultLanguage].update_data_success
              : language[this.props.defaultLanguage].add_data_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getBloodBank(e)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].blood_bank}
                required
                value={this.state.bloodBank}
                onChange={(e) => this.onChangeText("bloodBank", e)}
                error={this.state.error.bloodBank}
              />
            </div>
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getProductTypes({ q: e })}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].product_type}
                required
                value={this.state.productType}
                onChange={(e) => this.onChangeText("productType", e)}
                error={this.state.error.productType}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={[
                  {
                    label: "A+",
                    value: "A+",
                  },
                  {
                    label: "A-",
                    value: "A-",
                  },
                  {
                    label: "B+",
                    value: "B+",
                  },
                  {
                    label: "B-",
                    value: "B-",
                  },
                  {
                    label: "AB+",
                    value: "AB+",
                  },
                  {
                    label: "AB-",
                    value: "AB-",
                  },
                  {
                    label: "O+",
                    value: "O+",
                  },
                  {
                    label: "O-",
                    value: "O-",
                  },
                ]}
                placeholder={language[this.props.defaultLanguage].select}
                label={"Blood Group"}
                required
                value={this.state.bloodGroup}
                onChange={(e) => this.onChangeText("bloodGroup", e)}
                error={this.state.error.bloodGroup}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={language[this.props.defaultLanguage].min}
                label={language[this.props.defaultLanguage].min}
                value={this.state.minStock}
                onChange={(e) => this.onChangeText("minStock", e)}
                error={this.state.error.minStock}
                type="number"
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={language[this.props.defaultLanguage].max}
                label={language[this.props.defaultLanguage].max}
                value={this.state.maxStock}
                onChange={(e) => this.onChangeText("maxStock", e)}
                error={this.state.error.maxStock}
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewControl);
