import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import hospitalQuery from "../../../utils/queries/hospitalQuery";
import accountQuery from "../../../utils/queries/accountQuery";
import icons from "../../../constants/icons";
import { getStorage } from "../../../utils/storage";
import { fetchPatients } from "../../../utils/queries/patient";
import moment from "moment";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewPatient extends React.Component {
  state = {
    requesterName: "",
    requesterPosition: "",
    error: {},
    isSubmitting: false,
    hospitals: [],
    result: "",
    accounts: [],
    date: moment().format("YYYY-MM-DD HH:mm"),
    patients: [],
  };

  componentDidMount = async () => {
    this.getHospitals(true);
    this.getAccounts(true);
    this.getPatients(true);

    if (this.props._id) {
      this.setState({
        ...this.props,
        date: moment(this.props.date).format("YYYY-MM-DD HH:mm"),
        hospital: this.props.hospital
          ? {
              label: this.props.hospital.name,
              value: this.props.hospital._id,
            }
          : null,
        patient: this.props.patient
          ? {
              label:
                this.props.patient.firstName +
                " " +
                this.props.patient.lastName,
              value: this.props.patient._id,
            }
          : null,
        addedBy: this.props.addedBy
          ? {
              label: this.props.addedBy.name,
              value: this.props.addedBy._id,
            }
          : null,
        exam: this.props.exam
          ? {
              label: this.props.exam,
              value: this.props.exam,
            }
          : null,
      });
    }
  };

  getHospitals = async (isFetchingHospitals, search = {}) => {
    try {
      this.setState({
        isFetchingHospitals,
      });

      console.log(search);

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 0,
        limit: 50,
        ...search,
      });

      this.setState({
        hospitals: data,
        isFetchingHospitals: false,
        page: 1,
        limit: 50,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingHospitals: false });
    }
  };

  getPatients = async (isFetchingPatient, search = {}) => {
    try {
      this.setState({
        isFetchingPatient,
      });

      const data = await fetchPatients(this.props.defaultLanguage, {
        type: "dropdown",
        page: 0,
        limit: 50,
        ...search,
      });

      this.setState({
        patients: data,
        isFetchingPatient: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingPatient: false });
    }
  };

  getAccounts = async (isFetchingAccount, search = {}) => {
    try {
      this.setState({
        isFetchingAccount,
      });

      const data = await accountQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 0,
        limit: 50,
        ...search,
      });

      this.setState({
        patients: data,
        isFetchingPatient: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingPatient: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { hospital, result, exam, error, patient } = this.state;

    if (!hospital || hospital === "") {
      error.hospital = language[this.props.defaultLanguage].hospital_required;
    }

    if (!patient || patient === "") {
      error.patient = language[this.props.defaultLanguage].patient_required;
    }

    if (!exam) {
      error.exam = language[this.props.defaultLanguage].exam_required;
    }

    if (result === "") {
      error.result = language[this.props.defaultLanguage].result_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { hospital, patient, exam, result } = this.state,
        url = `${API_URL}/patient-lab-result`,
        { _id } = this.props,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        patient: patient.value,
        hospital: hospital.value,
        exam: exam.value,
        result,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getHospitals(true, { q: e })}
                leftIcon={icons.hospital}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].hospital}
                required
                value={this.state.hospital}
                onChange={(e) => this.onChangeText("hospital", e)}
                error={this.state.error.hospital}
                isLoading={this.state.isFetchingHospitals}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getPatients(true, { q: e })}
                leftIcon={icons.user}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].patient}
                required
                value={this.state.patient}
                onChange={(e) => this.onChangeText("patient", e)}
                error={this.state.error.patient}
                isLoading={this.state.isFetchingPatient}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select
                options={[
                  {
                    label: "ABO/RH",
                    value: "ABO/RH",
                  },
                  {
                    label: "HCT",
                    value: "HCT",
                  },
                  {
                    label: "Hgb",
                    value: "Hgb",
                  },
                  {
                    label: "PLT Count",
                    value: "PLT Count",
                  },
                ]}
                leftIcon={icons.exam}
                placeholder={
                  language[this.props.defaultLanguage].exam_placeholder
                }
                label={language[this.props.defaultLanguage].exam}
                required
                value={this.state.exam}
                onChange={(e) => this.onChangeText("exam", e)}
                error={this.state.error.exam}
                textarea
              />
            </div>
            <div className="col-md-12">
              <Input
                leftIcon={icons.result}
                placeholder={
                  language[this.props.defaultLanguage].result_placeholder
                }
                label={language[this.props.defaultLanguage].result}
                required
                value={this.state.result}
                onChange={(e) => this.onChangeText("result", e)}
                error={this.state.error.result}
                textarea
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewPatient);
