import React from "react";
import { connect } from "react-redux";
import language from "../../language/index.js";
import { Table } from "../Table/index.js";
import { CSVLink } from "react-csv";
import bloodUsageQuery, {
  deleteBloodUsage,
} from "../../utils/queries/bloodUsageQuery.js";
import exportPDF from "../../utils/exportPDF.js";
import handleSearch from "../../utils/handleSearch.js";
import NewUsage from "./NewUsage.js";
import { DeleteModal, Modal } from "../Modal/index.js";
import { defineRole } from "../../utils/handleAccessRoles.js";
import { getStorage } from "../../utils/storage.js";
import RecipientInfo from "../BloodRecipient/RecipientInfo.js";
import { ROLE_BLOOD_USAGE } from "../../constants/ROLES.js";

let copyData = [];

class BloodUsage extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await bloodUsageQuery(defaultLanguage, {
        page,
        limit,
        ...search,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].product,
        key: "inventory.code",
      },
      {
        title: language[this.props.defaultLanguage].donor,
        key: "inventory.donation.donor.donorNumber",
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "inventory.donation.donationNumber",
      },
      {
        title: language[this.props.defaultLanguage].patient_id,
        key: "patient.patientId",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].hospital,
        key: "hospital.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].doctor,
        key: "doctor.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].reaction,
        key: "reaction.label",
      },
      {
        title: language[this.props.defaultLanguage].usage_date,
        key: "usageDate",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      const { data } = await bloodUsageQuery(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await bloodUsageQuery(defaultLanguage, {
      page,
      limit,
    });

    this.setState({
      isLoading: false,
    });

    exportPDF("Blood Usage", headers, data);
  };

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteBloodUsage(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_BLOOD_USAGE,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].edit,
              onPress: (item) =>
                this.handleShowModal(
                  "showModal",
                  language[this.props.defaultLanguage].edit,
                  item
                ),
            },
            // {
            //   canAccess: defineRole({
            //     roles: this.state.user.accessRole,
            //     menu: "BloodUsage",
            //     operation: "delete",
            //   }),
            //   name: language[this.props.defaultLanguage].delete_,
            //   onPress: this.handleShowModal.bind(
            //     this,
            //     "showDeleteModal",
            //     language[this.props.defaultLanguage].delete_account
            //   ),
            // },
            ,
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewUsage
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showRecipientInfoModal"
          )}
          show={this.state.showRecipientInfoModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <div className="card-body">
            <RecipientInfo id={this.state?.selectedData?._id} />
          </div>
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Blood Usage" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodUsage);
