import React from "react";
import { connect } from "react-redux";
import { Input } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import {
  generateRandomNumber,
  generateRandomString,
} from "../../../utils/random";
import hospitalQuery from "../../../utils/queries/hospitalQuery";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class BulkRequest extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    donors: [],
    distributors: [],
    hospitals: [],
    numberOfPackages: "",
    isActive: availableOptions[0],
    description: "",
    approvals: [],
    packages: [],
    centers: [],
    centerSites: [],
    bloodBanks: [],
    requestNo: "",
  };

  componentDidMount = async () => {
    this.setState({
      requestNo:
        generateRandomString(4).toUpperCase() +
        "-" +
        generateRandomNumber(0, 1000000),
    });

    await this.setState({ ...this.props });

    if (this.props._id) {
      this.setState({
        bloodBank:
          this.props.bloodBank && this.props.bloodBank._id
            ? {
                label: this.props.bloodBank?.center?.name,
                value: this.props.bloodBank._id,
              }
            : null,
        distributor:
          this.props.distributor && this.props.distributor._id
            ? {
                label: this.props.distributor.name,
                value: this.props.distributor._id,
              }
            : null,
        hospital:
          this.props.hospital && this.props.hospital._id
            ? {
                label: this.props.hospital.name,
                value: this.props.hospital._id,
              }
            : null,
        packages: this.props.packages || [],
        approvals: this.props.approvals || [],
        isActive: {
          label: this.props.isActive + "",
          value: this.props.isActive + "",
        },
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  onSavePackages(packages) {
    this.setState({
      packages,
    });
  }

  validateForm() {
    let { error, requestNo } = this.state;

    if (requestNo === "") {
      error.requestNo =
        language[this.props.defaultLanguage].request_number_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    let user = await getStorage();

    this.setState({
      isSubmitting: true,
    });

    if (Object.keys(this.state.error).length === 0) {
      const hospitalInfo = await hospitalQuery(this.props.defaultLanguage, {
        id: user.hospital,
      });

      if (!hospitalInfo || hospitalInfo?.length === 0) {
        this.setState({
          isSubmitting: false,
        });
        return toastMessage("error", "Missing hospital information");
      }

      if (!hospitalInfo?.[0]?.allowedBloodBank) {
        this.setState({
          isSubmitting: false,
        });
        return toastMessage(
          "error",
          "There is no allowed blood bank to request from setup, please consult your administrator"
        );
      }

      let { _id, description, requestNo } = this.state,
        url = `${API_URL}/bloodrequest`,
        method = "POST";

      let requestedBody = {
        requestNo,
        hospital: user.hospital,
        bloodBank: hospitalInfo[0].allowedBloodBank._id,
        account: user.id,
        type: "bulk",
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData && this.props.getData(true);
          this.props.handleCloseModal && this.props.handleCloseModal();

          this.onReset();

          window.location.href = `/dashboard/request/bulk/${requestNo}`;
        })
        .catch((error) => {
          toastMessage("error", error);
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  onReset() {
    this.setState({
      isSubmitting: false,
      distributors: [],
      hospitals: [],
      numberOfPackages: "",
      isActive: availableOptions[0],
      description: "",
      approvals: [],
      packages: [],
      centers: [],
      centerSites: [],
      bloodBank: null,
      packages: [],
      requestNo:
        generateRandomString(4).toUpperCase() +
        "-" +
        generateRandomNumber(0, 1000000),
    });
  }

  render() {
    console.log(this.state.hospital);
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .request_number_placeholder
                }
                label={language[this.props.defaultLanguage].request_number}
                required
                value={this.state.requestNo}
                onChange={(e) => this.onChangeText("requestNo", e)}
                error={this.state.error.requestNo}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={"Next"}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BulkRequest);
