import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import availableOptions from "../../constants/availableOptions";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;
class NewCollectionType extends React.Component {
  state = {
    name: "",
    error: {},
    isSubmitting: false,
    description: "",
    available: availableOptions[0],
    collection_type_number: "",
  };

  componentDidMount = async () => {
    this.setState({ ...this.props });

    if (this.props._id && this.props._id !== "") {
      this.setState({
        name: this.props.name,
        description: this.props.description,
        available: {
          label: this.props?.available + "",
          value: this.props?.available + "",
        },
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });
  }

  validateForm() {
    let { name, error, collectionTypeNumber } = this.state;

    if (name === "") {
      error.name = language[this.props.defaultLanguage].name_required;
    }

    if (collectionTypeNumber === "") {
      error.collectionTypeNumber =
        language[this.props.defaultLanguage].collection_type_number_required;
    }

    console.log(error);

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { _id, name, description, available, collectionTypeNumber } =
          this.state,
        url = `${API_URL}/collectionType`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        collectionTypeNumber,
        name,
        description,
        available: available.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? language[this.props.defaultLanguage].update_data_success
              : language[this.props.defaultLanguage].add_data_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                leftIcon={icons.name}
                placeholder={
                  language[this.props.defaultLanguage]
                    .collection_type_number_placeholder
                }
                label={
                  language[this.props.defaultLanguage].collection_type_number
                }
                required
                value={this.state.collectionTypeNumber}
                onChange={(e) => this.onChangeText("collectionTypeNumber", e)}
                error={this.state.error.collectionTypeNumber}
              />
            </div>
            <div className="col-md-12">
              <Input
                leftIcon={icons.name}
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-12">
              <Input
                leftIcon={icons.description}
                placeholder={
                  language[this.props.defaultLanguage].description_placeholder
                }
                label={language[this.props.defaultLanguage].description}
                required
                value={this.state.description}
                onChange={(e) => this.onChangeText("description", e)}
                error={this.state.error.description}
              />
            </div>
            <div className="col-md-12">
              <Select
                leftIcon={icons.status}
                options={availableOptions}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].available}
                required
                value={this.state.available}
                onChange={(e) => this.onChangeText("available", e)}
                error={this.state.error.available}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewCollectionType);
