import React, { Component } from "react";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import { addProductType } from "../../utils/queries/productsType";
import { connect } from "react-redux";
import language from "../../language";

class AddProductType extends Component {
  state = {
    code: "",
    name: "",
    description: "",
    error: {},
  };

  componentDidMount() {
    if (this.props._id) {
      this.setState({
        ...this.props,
        allowedTestRequired: this.props.allowedTestRequired
          ? this.props.allowedTestRequired.map((el) => {
              return {
                label: el,
                value: el,
              };
            })
          : null,
        preTransfusionParameters: this.props.preTransfusionParameters
          ? this.props.preTransfusionParameters.map((el) => {
              return {
                label: el,
                value: el,
              };
            })
          : null,
      });
    }
  }

  onChangeText = (field, e) => {
    this.setState({
      [field]: e.target ? e.target.value : e,
    });

    let { error } = this.state;
    delete error[field];
    this.setState({ error });
  };

  onSubmit = async () => {
    let {
      code,
      name,
      description,
      allowedTestRequired,
      preTransfusionParameters,
    } = this.state;
    let { _id } = this.props;

    let error = {};
    if (code === "") {
      error.code = "Product Code is required";
    }
    if (name === "") {
      error.name = "Product Name is required";
    }
    if (description === "") {
      error.description = "Product Description is required";
    }

    if (!preTransfusionParameters) {
      error.preTransfusionParameters = "preTransfusionParameters is required";
    }

    if (!allowedTestRequired) {
      error.allowedTestRequired = "allowedTestRequired is required";
    }

    if (Object.keys(error).length === 0) {
      try {
        this.setState({ isSubmitting: true });

        let dataToSubmit = {
          code,
          name,
          description,
          allowedTestRequired: allowedTestRequired.map((el) => el.value),
          preTransfusionParameters: preTransfusionParameters.map(
            (el) => el.value
          ),
        };

        if (_id) {
          dataToSubmit.id = _id;
        }

        await addProductType(dataToSubmit);
        this.setState({ isSubmitting: false });
        this.handleCloseModal();
        await this.props.getData();
      } catch (error) {
        this.setState({ isSubmitting: false });
        console.log(error);
      }
    }

    this.setState({ error });
  };

  handleCloseModal = () => {
    this.props.handleCloseModal("showAddTypeModal");
  };

  render() {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <Input
              placeholder={
                language[this.props.defaultLanguage].code_placeholder
              }
              label={language[this.props.defaultLanguage].code}
              required
              value={this.state.code}
              onChange={(e) => this.onChangeText("code", e)}
              error={this.state.error.code}
            />
          </div>
          <div className="col-md-12">
            <Input
              placeholder={
                language[this.props.defaultLanguage].name_placeholder
              }
              label={language[this.props.defaultLanguage].name}
              required
              value={this.state.name}
              onChange={(e) => this.onChangeText("name", e)}
              error={this.state.error.code}
            />
          </div>
          <div className="col-md-12">
            {/* Description */}
            <Input
              placeholder={
                language[this.props.defaultLanguage].description_placeholder
              }
              label={language[this.props.defaultLanguage].description}
              required
              value={this.state.description}
              onChange={(e) => this.onChangeText("description", e)}
              error={this.state.error.description}
              textarea
            />
          </div>
          <div className="col-md-12">
            <Select
              placeholder={language[this.props.defaultLanguage].select}
              label={
                language[this.props.defaultLanguage].pre_transfusion_parameters
              }
              required
              value={this.state.preTransfusionParameters}
              onChange={(e) => this.onChangeText("preTransfusionParameters", e)}
              error={this.state.error.preTransfusionParameters}
              isCreatable
              isMulti
            />
          </div>
          <div className="col-md-12">
            <Select
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].test_required}
              required
              value={this.state.allowedTestRequired}
              onChange={(e) => this.onChangeText("allowedTestRequired", e)}
              error={this.state.error.allowedTestRequired}
              isCreatable
              isMulti
            />
          </div>
          {/* spacer */}
          <div className="col-md-12">
            <div className="spacer"></div>
          </div>
          <div className="modal-footer">
            <Button
              text={"Cancel"}
              onPress={this.handleCloseModal}
              className="btn-default"
            />
            <Button
              text={language[this.props.defaultLanguage].save}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AddProductType);
