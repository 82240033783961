import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { Breadcrumb } from "../../../components/Breadcrumb";
import BloodTesting from "../../../components/BloodTesting/BloodTesting";
import BloodCollection from "../../../components/BloodCollection/BloodCollection";
import { Inventories } from "../../../components/inventory";
import Separations from "../../../components/Production/ComponentSeparation/Separations";
import { Transformations } from "../../../components/Production/ComponentTransformation";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";
import {
  ROLE_BLOOD_TEST,
  ROLE_COLLECTIONS,
  ROLE_COMPONENT_SEPARATION,
  ROLE_COMPONENT_TRANSFORMATION,
  ROLE_INVENTORY,
} from "../../../constants/ROLES";

class DonationScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };

  render() {
    let tabOptions = [];

    const canAccessCollection = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_COLLECTIONS,
      operation: "read",
    });

    const canBloodTest = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_BLOOD_TEST,
      operation: "read",
    });

    const canAccessInventory = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_INVENTORY,
      operation: "read",
    });

    const canAccessSeparation = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_COMPONENT_SEPARATION,
      operation: "read",
    });

    const canAccessTransformation = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_COMPONENT_TRANSFORMATION,
      operation: "read",
    });

    if (canBloodTest) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].blood_testing,
        data: <BloodTesting {...this.props.routeParams} isSearch />,
      });
    }

    if (canAccessCollection) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].collections,
        data: <BloodCollection {...this.props.routeParams} />,
      });
    }

    if (canAccessInventory) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].inventory,
        data: <Inventories {...this.props.routeParams} />,
      });
    }

    if (canAccessSeparation) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].component_separation,
        data: <Separations {...this.props.routeParams} />,
      });
    }

    if (canAccessTransformation) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].component_transformation,
        data: <Transformations {...this.props.routeParams} />,
      });
    }

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: language[this.props.defaultLanguage].donors,
                  url: "/dashboard/donors",
                },
                {
                  name: language[this.props.defaultLanguage].collections,
                  url: `/dashboard/donors/${this.props.routeParams.donorId}`,
                },
                {
                  name: this.props.routeParams.donationNumber,
                  active: true,
                },
              ]}
            />
            {/* <DonorProfile donorId={this.props.routeParams.donorId} /> */}
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(DonationScreen));
