import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import language from "../../language";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (lng = "english", params = {}) => {
  try {
    const user = await getStorage();

    if (!params.q || params.q === "") {
      delete params.q;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/blooddistributor`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (params.type === "dropdown") {
      return queryRes.data.data.map((el) => {
        return {
          ...el,
          label: el.name + " " + el.contactNumber,
          value: el._id,
        };
      });
    }

    return !params.page && !params.limit ? queryRes.data : { data, count };
  } catch (error) {
    console.log(error);
    toastMessage("error", language[lng].unable_fetch_distributor);
  }
};

export default fetchData;

export const deleteBloodDistributor = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/blooddistributor`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_distributor);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_distributor);
  }
};
