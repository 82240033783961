import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input/index.js";
import language from "../../language/index.js";
import { Button } from "../Button/index.js";
import api from "../../utils/api/index.js";
import toastMessage from "../../utils/toastMessage.js";
import { fetchShippingInQueueData } from "../../utils/queries/request/shipping-queue.js";
import { getStorage } from "../../utils/storage.js";
import Loading from "../Loader/Loading.js";
import Table from "../Table/Table.js";

class ReceptionRequestBulk extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    product: "",
    donationNumber: "",
    requestNo: "",
    shipmentNumber: "",
    data: [],
  };

  getShipmentInfo = async () => {
    const { shipmentNumber } = this.state;

    this.setState({ isLoading: true });

    const data = await fetchShippingInQueueData(this.props.defaultLanguage, {
      shipmentNumber,
    });

    this.setState({ isLoading: false });

    if (data.length === 0)
      return toastMessage("error", "Shipment number does not exist");

    this.setState({ data });
  };

  renderHeaderTable = () => {
    const headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donation.donationNumber",
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "product",
      },
      {
        title: language[this.props.defaultLanguage].stock,
        key: "to.name",
      },

      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
    ];

    return headers;
  };

  validateForm() {
    let { error, shipmentNumber } = this.state;

    if (shipmentNumber === "") {
      error.shipmentNumber =
        language[this.props.defaultLanguage].shipment_number_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { shipmentNumber, data } = this.state;
      const user = await getStorage();

      this.setState({
        isSubmitting: true,
      });

      if (data.length === 0) {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error", "Shipment number does not exist");
        return;
      }

      if (user.stock && data[0].to?._id !== user.stock) {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error", "You are not allowed to receive this product");
        return;
      }

      let method = "POST",
        requestBody = {
          inventory: data[0].inventory._id,
          shipmentNumber,
          to: data[0].to?._id,
          from: data[0].from?._id,
          type: "bulk",
        };

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/shipping-queue/reception`,
        data: requestBody,
      };

      await api(options);

      await this.getShipmentInfo();

      this.setState({
        isSubmitting: false,
        shipmentNumber: "",
      });

      toastMessage(
        "success",
        language[this.props.defaultLanguage].add_data_success
      );
    } catch (error) {
      console.log(error);
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isDoneSearching: false,
    });
  };

  render() {
    console.log(this.state.data);
    return (
      <>
        <Input
          placeholder={
            language[this.props.defaultLanguage].shipment_number_placeholder
          }
          label={language[this.props.defaultLanguage].shipment_number}
          value={this.state.shipmentNumber}
          onChange={(e) => this.onChangeText("shipmentNumber", e)}
          error={this.state.error.shipmentNumber}
        />

        {this.props.handleCloseModal && (
          <Button
            className="btn-default"
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
          />
        )}
        {this.state.data.length === 0 && (
          <Button
            text={language[this.props.defaultLanguage].search}
            isSubmitting={this.state.isLoading}
            onPress={this.getShipmentInfo.bind(this)}
          />
        )}
        {this.state.data.length > 0 && (
          <Table
            headers={this.renderHeaderTable()}
            data={this.state.data}
            showAdd={
              this.state.data.filter((item) => item.status === "received")
                .length === 0
            }
            addButtonText="Confirm Reception of Selected Products"
            handleAddPressed={this.onSubmit.bind(this)}
            addButtonLoading={this.state.isSubmitting}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ReceptionRequestBulk);
