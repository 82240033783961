import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import { fetchComponentsSeparationProfiles } from "../../../utils/queries/production/profiles/separation";
import { fetchInventories } from "../../../utils/queries/inventories/center";
import Separations from "../ComponentSeparation/Separations";
import { CardHeader } from "../../Header";
import NewLabel from "./NewLabel";
import { Modal } from "../../Modal";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewTransformation extends React.Component {
  state = {
    username: "",
    error: {},
    isSubmitting: false,
    donation: {},
    isLoading: true,
    available: availableOptions[0],
    profiles: [],
    bagStatuses: [],
    volume: "",
    donationNumber: "",
    donations: [],
    componentCode: "",
    totalVolume: 0,
    components: [],
    inventory: {},
  };

  getDonation = async (searchDonation) => {
    try {
      let { error, profile } = this.state;

      if (this.state.donationNumber === "") {
        error.donationNumber =
          language[this.props.defaultLanguage].donation_number_required;

        this.setState({ error });

        return;
      }
      this.setState({
        searchDonation,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: this.state.donationNumber?.replace(/\s+/g, ""),
        // "blockInfo.blocked": false,
      });

      let donation = {};

      if (data.length > 0) {
        donation = data[0];

        if (donation.blockInfo.blocked) {
          error.donationNumber =
            "Donation has been blocked REASON:" +
            donation.blockInfo.blockReason;

          return this.setState({
            error,
            searchDonation: false,
          });
        }

        if (profile?.baseComponent?._id && donation?._id) {
          console.log("baseComponent = ", profile.baseComponent);

          await this.getProduct(donation._id, profile.baseComponent.code);
        } else {
          toastMessage("error", "Base component are missing");
        }

        this.setState({ donation });
      } else {
        toastMessage("error", language[this.props.defaultLanguage].no_data);
      }
      this.setState({ searchDonation: false });
    } catch (error) {
      this.setState({ searchDonation: false, components: [] });
    }
  };

  getComponentProfiles = async (isFetchingProfile, code) => {
    try {
      this.setState({
        isFetchingProfile,
      });

      const data = await fetchComponentsSeparationProfiles(
        this.props.defaultLanguage,
        {
          type: "transformation",
          inputType: "dropdown",
          code,
        }
      );

      this.setState({
        isFetchingProfile: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingProfile: false });
    }
  };

  getProduct = async (donation, componentCodes) => {
    let { profile } = this.state;
    try {
      const data = await fetchInventories(this.props.defaultLanguage, {
        donation,
        code: componentCodes,
        isLabeled: true,
        isTestResult: true,
      });

      let components = [];

      if (data.length > 0) {
        if (profile?.components) {
          components = profile?.components?.map((el) => {
            return {
              ...el,
              label: el.code + " " + el.name,
              value: el._id,
            };
          });
        }
      }

      this.setState({
        components,
      });

      if (components.length === 0) {
        toastMessage(
          "error",
          language[this.props.defaultLanguage]
            .no_component_available_for_transformation
        );
      }
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        searchDonation: false,
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];
    delete error["errorMessage"];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "profile") {
      this.setState({ components: [] });
    }
  };

  validateForm() {
    let { profile, error, donationNumber } = this.state;

    if (!profile) {
      error.profile =
        language[this.props.defaultLanguage].component_profile_required;
    }

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      const { donation } = this.state;
      this.setState({
        isSubmitting: true,
      });
      await this.handleTransformedData(donation);

      this.setState({
        isSubmitting: false,
        profile: null,
        donationNumber: "",
      });
    }
  };

  handleTransformedData = async (donation) => {
    let { _id } = this.props;
    let { profile, available, components } = this.state,
      url = `${API_URL}/componentTransformation`,
      method = "POST",
      user = await getStorage();

    this.setState({ isSubmitting: true });
    try {
      let requestedBody = {
        donation: donation._id,
        components,
        available: available.value,
        profile: profile._id,
        addedBy: user.id,
        center: donation.center._id,
        centerSite: donation.centerSite._id,
        status: "pending",
        baseComponent: profile?.baseComponent?._id,
      };

      if (method === "PUT") {
        requestedBody.modifiedBy = user.id;
      } else {
        requestedBody.addedBy = user.id;
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;

        delete requestedBody.addedBy;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      const data = await axios(options);

      this.setState({
        isSubmitting: false,
        profile: null,
        component: null,
        donationNumber: "",
        components: [],
      });

      toastMessage(
        "success",
        `${
          language[this.props.defaultLanguage][
            method === "PUT" ? "update_data_success" : "add_data_success"
          ]
        }`
      );

      this.handleShowModal("showModal", profile.code, data.data);

      this.setState({ isSubmitting: true, isRefreshing: true });
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };

  handleCheckBox(type) {
    this.setState({
      [type]: !this.state[type],
    });
  }

  handleCheck(field, value) {
    let { error } = this.state;
    delete error[field];
    this.setState({
      [field]: value,
      error,
    });
  }

  handleShowModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleUpdateInventory = async () => {
    try {
      const { productInfo } = this.state;

      if (productInfo._id) {
        const body = {
          id: productInfo._id,
          status: "transformed",
        };

        const user = await getStorage();

        const options = {
          url: `${API_URL}/inventory`,
          method: "PUT",
          data: body,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };

        return axios(options);
      }
    } catch (error) {
      toastMessage("error", error);
    }
  };

  handleChangeComponent = (idex, field, e) => {
    let { components = [] } = this.state;

    components[idex][field] = e && e?.target ? e?.target?.value : e;
    console.log(components);
    this.setState({ components });
  };

  onReset() {
    this.setState({
      donation: {},
      profile: null,
      component: null,
      donationNumber: "",
      components: [],
      baseComponent: null,
    });
  }

  render() {
    return (
      <>
        <div className="card mb-3">
          <div className="card-body">
            <form>
              <Select
                loadOptions={(v) => this.getComponentProfiles(true, v)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].component_profiles}
                required
                value={this.state.profile}
                onChange={(e) => this.onChangeText("profile", e)}
                error={this.state.error.profile}
                isLoading={this.state.isFetchingProfile}
              />
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .donation_number_placeholder
                }
                label={language[this.props.defaultLanguage].donation_number}
                required
                value={this.state.donationNumber}
                error={this.state.error.donationNumber}
                onChange={(e) => this.onChangeText("donationNumber", e)}
              />
              {this.state?.profile?.baseComponent &&
                this.state?.profile?.baseComponent?._id && (
                  <Select
                    label={language[this.props.defaultLanguage].base_component}
                    required
                    value={{
                      label:
                        this.state.profile.baseComponent.code +
                        " " +
                        this.state.profile.baseComponent.name,
                      value: this.state.profile.baseComponent._id,
                    }}
                    error={this.state.error.baseComponent}
                    onChange={(e) => this.onChangeText("baseComponent", e)}
                    disabled
                  />
                )}
              {this.state?.donation?._id &&
                this.state?.components?.map((component, index) => {
                  return (
                    <div key={index} className="row">
                      <div className="col-md-6">
                        <Input
                          label={
                            language[this.props.defaultLanguage].component_name
                          }
                          required
                          value={component.code + " - " + component.name}
                          onChange={(e) =>
                            this.onChangeText(
                              `${component.code}_name_${index}`,
                              e
                            )
                          }
                          error={
                            this.state.error[`${component.code}_name_${index}`]
                          }
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={language[this.props.defaultLanguage].volume}
                          required
                          value={component.volume}
                          type="number"
                          onChange={(e) =>
                            this.handleChangeComponent(index, "volume", e)
                          }
                          min={component.volume}
                          max={component.maxVolume}
                        />
                      </div>
                    </div>
                  );
                })}
              {this.state?.donation?._id ? (
                <div className="d-flex gap-2">
                  <Button
                    text={language[this.props.defaultLanguage].reset}
                    onPress={(e) => {
                      e.preventDefault();
                      this.onReset();
                    }}
                    className="btn-default"
                  />
                  {this.state.components?.length > 0 ? (
                    <Button
                      text={language[this.props.defaultLanguage].submit}
                      onPress={(e) => {
                        e.preventDefault();
                        this.onSubmit();
                      }}
                      isSubmitting={this.state.isSubmitting}
                    />
                  ) : (
                    <Button
                      text={language[this.props.defaultLanguage].search}
                      onPress={(e) => {
                        e.preventDefault();
                        this.getDonation(true);
                      }}
                      isSubmitting={this.state.searchDonation}
                    />
                  )}
                </div>
              ) : (
                <Button
                  text={language[this.props.defaultLanguage].search}
                  onPress={(e) => {
                    e.preventDefault();
                    this.getDonation(true);
                  }}
                  isSubmitting={this.state.searchDonation}
                />
              )}
            </form>
          </div>

          {this.state?.donation?._id && this.state?.components?.length > 0 && (
            <div className="card" style={{ marginTop: "1rem" }}>
              <CardHeader
                title={
                  language[this.props.defaultLanguage].component_transformation
                }
              />
              <div className="card-body">
                <Separations
                  donationId={this.state?.donation?._id}
                  isRefreshing={this.state.isRefreshing}
                  type="transformation"
                  componentProfile={this.state?.profile?.value}
                  // component={this.state?.component?.value}
                />
              </div>
            </div>
          )}

          <Modal
            handleClose={this.handleCloseModal.bind(this, "showModal")}
            show={this.state.showModal}
            showHeaderBottomBorder={false}
          >
            <NewLabel
              handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
              {...this.state.donation}
              donationId={this.state?.donation?._id}
            />
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewTransformation);
