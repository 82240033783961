import React from "react";
import { connect } from "react-redux";
import bloodCenterQuery from "../../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import { Input, Select } from "../../Input";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import icons from "../../../constants/icons";
import language from "../../../language";
import { nationalities } from "../../../constants/documentIDNumberTypes";
import { Button } from "../../Button";
import bloodGroups from "../../../constants/bloodGroups";

class BloodTestFilter extends React.Component {
  state = {
    error: {},
  };

  componentDidMount() {
    this.getCenters();
  }

  getCenters = async (search = {}) => {
    try {
      this.setState({
        isFetchingCenter: true,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        ...search,
        page: 1,
        limit: 50,
      });

      this.setSelectedCenter(data);

      let center = null;
      const { centerRole } = await defineRoleCenterSite();
      if (!this.props._id && centerRole) {
        data.forEach((el) => {
          if (centerRole.includes(el._id)) {
            center = el;
          }
        });

        this.setState({ center }, () =>
          this.getCenterSites({ center: center?._id || undefined })
        );
      }

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });

      return [];
    }
  };

  getCenterSites = async (search = {}) => {
    try {
      this.setState({
        isFetchingCenterSite: true,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        ...search,
      });

      this.setSelectedCenterSites(data);

      const { centerSiteRole } = await defineRoleCenterSite();
      let centerSite = null;

      if (!this.props._id && centerSiteRole) {
        data.forEach((el) => {
          if (centerSiteRole.includes(el._id)) {
            centerSite = el;
          }
        });

        this.setState({ centerSite });
      }

      this.setState({
        centerSites: data,
        isFetchingCenterSite: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenterSite: false });

      return [];
    }
  };

  setSelectedCenter(centers) {
    const { center } = this.props;
    if (center) {
      const selectedCenter = centers.find(
        (el) => el.value === (center._id || center)
      );

      if (selectedCenter?._id) {
        this.setState(
          {
            center: {
              label: selectedCenter.name,
              value: selectedCenter._id,
            },
          },
          () => {
            this.getCenterSites({ center: selectedCenter._id });
          }
        );
      }
    }
  }

  setSelectedCenterSites(centerSites) {
    const { centerSite } = this.props;
    if (centerSite) {
      const selectedData = centerSites.find(
        (el) => el._id === (centerSite?._id || centerSite)
      );

      if (selectedData?._id) {
        this.setState({
          centerSite: {
            label: selectedData.name,
            value: selectedData._id,
          },
        });
      }
    }
  }

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "center") {
      this.getCenterSites({ center: inputValue.value });
    }
  };

  onSubmit() {
    const { bloodGroup, center, centerSite, donorNumber, status } = this.state;

    let params = {
      center: center?.value || undefined,
      centerSite: centerSite?.value || undefined,
      bloodGroup: bloodGroup?.value || undefined,
      donorNumber,
      status:
        status?.value === "pending" || status?.value === "completed"
          ? status?.value
          : undefined,
    };

    if (status?.value === "bloodGroupingTested") {
      params.bloodGroupingTested = true;
    }

    if (status?.value === "serologyTested") {
      params.serologyTested = true;
    }

    console.log("params", params);

    this.props.fetchData && this.props.fetchData(true, params);

    this.props.handleCloseModal && this.props.handleCloseModal({});
  }

  handleReset() {
    this.props.fetchData && this.props.fetchData({});
    this.props.handleCloseModal && this.props.handleCloseModal({});
  }

  render() {
    return (
      <>
        <div className="col-md-12">
          <Input
            leftIcon={icons.donors}
            placeholder={language[this.props.defaultLanguage].donor_number}
            label={
              language[this.props.defaultLanguage].donor_number_placeholder
            }
            value={this.state.donorNumber}
            onChange={(e) => this.onChangeText("donorNumber", e)}
            error={this.state.error.donorNumber}
          />
        </div>
        <div className="col-md-6">
          <Select
            loadOptions={(e) => this.getCenters({ q: e })}
            leftIcon={icons.center}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].center}
            value={this.state.center}
            onChange={(e) => this.onChangeText("center", e)}
            error={this.state.error.center}
            isLoading={this.state.isFetchingCenter}
          />
        </div>
        <div className="col-md-6">
          <Select
            loadOptions={(e) => this.getCenterSites({ q: e })}
            leftIcon={icons.site}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].center_site}
            value={this.state.centerSite}
            onChange={(e) => this.onChangeText("centerSite", e)}
            error={this.state.error.centerSite}
            isLoading={this.state.isFetchingCenterSite}
          />
        </div>
        <div className="col-md-6">
          <Select
            options={bloodGroups}
            leftIcon={icons.bloodGroup}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].blood_group}
            value={this.state.bloodGroup}
            onChange={(e) => this.onChangeText("bloodGroup", e)}
            error={this.state.error.bloodGroup}
          />
        </div>

        <div className="col-md-6">
          <Select
            options={[
              {
                label: "Pending",
                value: "pending",
              },
              {
                label: "BloodGrouping - Tested",
                value: "bloodGroupingTested",
              },
              {
                label: "Serology - Tested",
                value: "serologyTested",
              },
              {
                label: "Completed",
                value: "completed",
              },
            ]}
            leftIcon={icons.status}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].status}
            value={this.state.bloodGroup}
            onChange={(e) => this.onChangeText("status", e)}
            error={this.state.error.status}
          />
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].reset}
            onPress={this.handleReset.bind(this)}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].apply}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};
export default connect(mapStateToProps)(BloodTestFilter);
