export const ROLE_SERVICE = "ROLE_SERVICE";
export const ROLE_ACCOUNT = "ROLE_ACCOUNT";
export const ROLE_MAP_INSIGHTS = "ROLE_MAP_INSIGHTS";
export const ROLE_HOME = "ROLE_HOME";
export const ROLE_ANALYTICS = "ROLE_ANALYTICS";
export const ROLE_REPORTS = "ROLE_REPORTS";
export const ROLE_DONORS = "ROLE_DONORS";
export const ROLE_COLLECTIONS = "ROLE_COLLECTIONS";
export const ROLE_BLOOD_TEST = "ROLE_BLOOD_TEST";
export const ROLE_PRODUCTION = "ROLE_PRODUCTION";
export const ROLE_INVENTORY = "ROLE_INVENTORY";
export const ROLE_COMPONENT_SEPARATION = "ROLE_COMPONENT_SEPARATION";
export const ROLE_COMPONENT_TRANSFORMATION = "ROLE_COMPONENT_TRANSFORMATION";
export const ROLE_REQUEST = "ROLE_REQUEST";
export const ROLE_HOEMOVIGILANCE = "ROLE_HOEMOVIGILANCE";
export const ROLE_BLOOD_RECIPIENT = "ROLE_BLOOD_RECIPIENT";
export const ROLE_BLOOD_USAGE = "ROLE_BLOOD_USAGE";
export const ROLE_MOBILIZATION = "ROLE_MOBILIZATION";
export const ROLE_ALERT = "ROLE_ALERT";
export const ROLE_CENTER = "ROLE_CENTER";
export const ROLE_CENTER_SITE = "ROLE_CENTER_SITE";
export const ROLE_HOSPITAL = "ROLE_HOSPITAL";
export const ROLE_FEEDBACK = "ROLE_FEEDBACK";
export const ROLE_CLUB = "ROLE_CLUB";
export const ROLE_CONTROL_PANEL = "ROLE_CONTROL_PANEL";
export const ROLE_JOB_POSITION = "ROLE_JOB_POSITION";
export const ROLE_VITAL_SIGNS = "ROLE_VITAL_SIGNS";
export const ROLE_ASSESSMENT = "ROLE_ASSESSMENT";
export const ROLE_DISPATCH = "ROLE_DISPATCH";
export const ROLE_EQUIPMENT = "ROLE_EQUIPMENT";
export const ROLE_COLLECTION_TYPE = "ROLE_COLLECTION_TYPE";
export const ROLE_LABELLING = "ROLE_LABELLING";
export const ROLE_APPOINTMENT = "ROLE_APPOINTMENT";
export const ROLE_DISTRIBUTORS = "ROLE_DISTRIBUTORS";
export const ROLE_VITAL_SIGN_MEASURE = "VITAL_SIGN_MEASURE";
export const ROLE_BLOOD_TEST_BLOOD_GROUPING = "ROLE_BLOOD_TEST_BLOOD_GROUPING";
export const ROLE_BLOOD_TEST_SEROLOGY = "ROLE_BLOOD_TEST_SEROLOGY";
export const ROLE_BLOOD_TEST_QUESTIONNAIRE = "ROLE_BLOOD_TEST_QUESTIONNAIRE";
export const ROLE_MESSAGE_TEMPLATE = "ROLE_MESSAGE_TEMPLATE";
export const ROLE_APPLICATION_CONFIGURATION = "ROLE_APPLICATION_CONFIGURATION";
export const ROLE_BLOOD_TEST_CONFIRMATION = "ROLE_BLOOD_TEST_CONFIRMATION";
export const ROLE_LABELING = "ROLE_LABELING";
export const ROLE_PATIENT = "ROLE_PATIENT";
export const ROLE_BLOOD_BANK = "ROLE_BLOOD_BANK";
export const ROLE_EXCEPTIONAL_CASES = "ROLE_EXCEPTIONAL_CASES";
export const ROLE_DISTRIBUTION = "ROLE_DISTRIBUTION";
export const ROLE_PRODUCTION_PRODUCT_REJECTED =
  "ROLE_PRODUCTION_PRODUCT_REJECTED";
export const ROLE_PRODUCTION_RECEPTION = "ROLE_PRODUCTION_RECEPTION";
export const ROLE_PATIENT_LAB_RESULT = "ROLE_PATIENT_LAB_RESULT";
export const ROLE_PRODUCT_TYPES = "ROLE_PRODUCT_TYPES";
export const ROLE_INVENTORY_CONTROLS = "ROLE_INVENTORY_CONTROLS";
export const ROLE_SAMPLE_COLLECTED_RESULT = "ROLE_SAMPLE_COLLECTED_RESULT";
