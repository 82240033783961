import React from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Table } from "../../../Table";
import {
  deleteInventory,
  fetchInventories,
} from "../../../../utils/queries/production/inventoryQuery";
import bloodDonationQuery from "../../../../utils/queries/bloodDonationQuery";
import { DeleteModal, Modal } from "../../../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../../utils/handleAccessRoles";
import { getStorage } from "../../../../utils/storage";
import icons from "../../../../constants/icons";
import ViewInventory from "../../../inventory/ViewInventory";
import exportPDF from "../../../../utils/exportPDF";
import CardCount from "../../../CardCount/CardCount";
import { CentersFilter, DateFilter } from "../../../Filters";
import { CSVLink } from "react-csv";
import { ROLE_INVENTORY } from "../../../../constants/ROLES";
import { fetchShippingInQueueData } from "../../../../utils/queries/request/shipping-queue";
import moment from "moment";
import { Loading } from "../../../Loader";
import ShippingInQueue from "../ShippingInQueue";
import SourceInventoryFilters from "../../../Filters/SourceInventoryFilters";
import DestinationInventoryFilters from "../../../Filters/DestinationInventoryFilters";

let updatedFilters = {};

class ShipmentReport extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    counts: {
      received: 0,
      returned: 0,
      transferred: 0,
    },
    isDisabled: true,
    filters: {
      startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
      endDate: moment().endOf("d").format("YYYY-MM-DD HH:mm"),
    },
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.fetchData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.fetchData(true);
    }
  }

  fetchData(isLoading, search = {}) {
    updatedFilters = {
      ...this.state.filters,
      ...search,
    };

    this.setState({ filters: updatedFilters });
    this.getData(isLoading, { ...updatedFilters });
    // this.getCountsData(isLoading, "transferred");
    // this.getCountsData(isLoading, "received");
    // this.getCountsData(isLoading, "returned");
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });
  }

  returnFilters = async (search = {}) => {
    const { page, limit } = this.state;
    const user = await getStorage();

    return {
      page,
      limit,
      donation: this.props.donationId,
      startDate: search.startDate,
      endDate: search.endDate,
      to: search?.to?.map((el) => el.value),
      from: search?.from?.map((el) => el.value),
      queryType: "groupByShipmentNumber",
      sortField: "_id",
      sortOrder: -1,
      stock: user.stock,
    };
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const filters = await this.returnFilters(search);

      if (filters.from) {
        filters.to = [filters.stock];
        delete filters.stock;
      } else if (filters.to) {
        filters.from = [filters.stock];
        delete filters.stock;
      }

      const { data, count } = await fetchShippingInQueueData(
        defaultLanguage,
        filters
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCountsData = async (isLoadingCounts, type) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingCounts });

      const filters = await this.returnFilters(this.state.filters);

      let params = {
        ...filters,
        isCount: true,
        status: type,
      };

      if (type === "requested") {
        params.status = ["requested", "pickup", "delivered"];
      }

      const { data } = await fetchShippingInQueueData(defaultLanguage, {
        ...params,
      });

      let { counts } = this.state;

      counts[type] = data;

      this.setState({
        counts,
        isLoadingCounts: false,
      });
    } catch (error) {
      this.setState({
        isLoadingCounts: false,
      });
    }
  };

  getByShipmentNumber = async (isLoadingShipmentDetails, shipmentNumber) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingShipmentDetails });

      const { data } = await fetchShippingInQueueData(defaultLanguage, {
        shipmentNumber,
        page: 1,
        limit: 100,
      });

      this.setState({
        shipmentInfo: data,
        isLoadingShipmentDetails: false,
      });
    } catch (error) {
      this.setState({
        isLoadingShipmentDetails: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.fetchData(true, this.state.filters);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });

    if (modal === "viewShipment") {
      this.getByShipmentNumber(true, selectedData.shipmentNumber);
    }
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].shipment_number,
        key: "shipmentNumber",
        type: "link",
        onPress: (item) =>
          this.handleShowModal("viewShipment", item.shipmentNumber, item),
      },
      {
        title: language[this.props.defaultLanguage].items,
        key: "totalItems",
      },
      {
        title: language[this.props.defaultLanguage].source,
        key: "source",
      },
      {
        title: language[this.props.defaultLanguage].destination,
        key: "destination",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].shipped_by,
        key: "addedBy",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        formatTime: "lll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteInventory(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const filters = await this.returnFilters(this.state.filters);

      const { data } = await fetchShippingInQueueData(defaultLanguage, filters);

      this.setState({
        isLoading: false,
        csvData: data.map((el) => {
          return {
            ...el,
            center: el?.center?.name,
            centerSite: el?.centerSite?.name,
          };
        }),
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { defaultLanguage } = this.props;

    const filters = await this.returnFilters(this.state.filters);

    const { data } = await fetchShippingInQueueData(defaultLanguage, filters);

    this.setState({
      isLoading: false,
    });

    exportPDF("Shipment", this.returnTableHeaders(), data);
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });
    await this.fetchData(true, {
      shipmentNumber: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  render() {
    return (
      <>
        <div className="row">
          <div style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <SourceInventoryFilters
                handleApplyFilters={(isLoading, search) =>
                  this.fetchData(isLoading, search)
                }
              />
              <DestinationInventoryFilters
                handleApplyFilters={(isLoading, search) =>
                  this.fetchData(isLoading, search)
                }
              />
              <DateFilter
                handleApplyFilters={(isLoading, search) =>
                  this.fetchData(isLoading, search)
                }
              />
            </div>
          </div>
          {/* <div className="col-5th ">
            <CardCount
              count={this.state.counts.transferred}
              title={language[this.props.defaultLanguage].pending}
              color="orange"
              icon="schedule"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href = "/dashboard/shipment/byStatus/pending")
              }
            />
          </div>
          <div className="col-5th ">
            <CardCount
              count={this.state.counts.received}
              title={language[this.props.defaultLanguage].received}
              color="#078ECE"
              icon="print"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href = "/dashboard/shipment/byStatus/pending")
              }
            />
          </div>
          <div className="col-5th ">
            <CardCount
              count={this.state.counts.returned}
              title={language[this.props.defaultLanguage].returned}
              color="gray"
              icon="restart_alt"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href = "/dashboard/shipment/byStatus/returned")
              }
            />
          </div>
          <div className="col-5th ">
            <CardCount
              count={this.state.counts.rejected}
              title={language[this.props.defaultLanguage].rejected}
              color="red"
              icon="deleet"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href = "/dashboard/shipment/byStatus/rejected")
              }
            />
          </div> */}
        </div>

        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          addSearchButton
          search_text={this.state.search_text}
          searching={this.state.searching}
          handleSearch={(e) => this.onChangeText("search_text", e)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.code, item)
          }
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.fetchData(true),
            },
            {
              type: "export",
              title: language[this.props.defaultLanguage].export_,
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_INVENTORY,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].view,
              icon: icons.eye_on,
              onPress: (item) =>
                this.handleShowModal("showModal", item.code, item),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "viewShipment")}
          show={this.state.viewShipment}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          {this.state.isLoadingShipmentDetails ? (
            <center>
              <Loading />
            </center>
          ) : (
            <ShippingInQueue
              shipmentNumber={this.state.selectedData.shipmentNumber}
            />
          )}
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData._id}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Shipment" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ShipmentReport);
