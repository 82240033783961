import React, { useState, useEffect } from "react";
import "./styles.css";
import {
  fetchRequestedProducts,
  fetchBloodRequest,
} from "../../../utils/queries/request-product";
import toastMessage from "../../../utils/toastMessage";
import icons from "../../../constants/icons";
import { useParams } from "react-router-dom";
import { Input, Select } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { getStorage } from "../../../utils/storage";
import api from "../../../utils/api";
import language from "../../../language";
import ModalContainer from "../../../components/Modal/Modal";
import ProvideBloodRequested from "./ProvideBloodRequested";

function SampleCollectionScreen() {
  const [requestedProduct, setRequestedProduct] = useState([]);
  const [requestedBloodRequest, setRequestedBloodRequest] = useState({});
  const [result, setResult] = useState("");
  const [selectedTest, setSelectedTest] = useState("");
  const { requestId, requestNo } = useParams();
  const { isLoading, setIsLoading } = useState(false);
  const [showRequestInfoModal, setShowRequestInfoModal] = useState(false);

  // Fetch requested products
  const fetchRequestedProductsData = async () => {
    try {
      const transformedData = [];
      const response = await fetchRequestedProducts("english", {
        page: 1,
        limit: 500,
        id: "",
        q: "",
        request: requestId,
      });

      response.data.forEach((item) => {
        transformedData.push({
          productType: item.productType.name,
          bloodGroup: item.bloodGroup,
          numberOfPackages: item.numberOfPackages,
          status: item?.request?.status,
          action: item._id,
          sampleCollectedBy: item.sampleCollectedBy,
          sampleCollectedDate: item.sampleCollectedDate,
          allowedTests: item.productType.allowedTestRequired,
          productTypeId: item.productType._id,
        });
      });

      console.log(response.data);

      setRequestedProduct(transformedData);
    } catch (error) {
      toastMessage("error", error.message || "Failed to fetch data");
    }
  };

  // Fetch blood request details
  const fetchRequestedBloodRequest = async () => {
    try {
      const response = await fetchBloodRequest("english", {
        page: 1,
        limit: 500,
        id: "",
        q: "",
        requestNo: requestNo,
      });

      setRequestedBloodRequest(response.data[0]);
      console.log(response.data[0]);
    } catch (error) {
      toastMessage("error", error.message || "Failed to fetch data");
    }
  };

  // Convert lab tests to dropdown options
  const convertLabTest = (data) => {
    return (data || []).map((el) => ({
      label: el,
      value: el,
    }));
  };

  const handleChangeText = (e) => {
    setResult(e.target.value);
  };

  const constonHandleSelectTest = (e) => {
    setSelectedTest(e.target.value);
  };

  useEffect(() => {
    fetchRequestedProductsData();
    fetchRequestedBloodRequest();
  }, []);

  const resetForm = () => {
    setSelectedTest(null);
    setResult("");
  };

  const handleSubmit = async () => {
    try {
      if (!selectedTest.value) {
        toastMessage("error", "Please select a lab test");
        return;
      }

      if (!result) {
        toastMessage("error", "Please enter the result");
        return;
      }

      // setIsLoading(true);
      const requestData = {
        patient: requestedBloodRequest?.patient?._id,
        request: requestId,
        productType: requestedProduct[0]?.productTypeId,
        exam: selectedTest?.value,
        result: result,
      };

      console.log(requestData);
      const response = await collectSampleRequest("english", requestData);

      toastMessage(
        "success",
        response.message || "Data submitted successfully"
      );

      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      toastMessage("error", error.message || "Failed to submit data");

      // resetForm();
    }
  };

  const collectSampleRequest = async (lng = "english", data) => {
    try {
      const user = await getStorage();
      const API_URL = process.env.REACT_APP_BBMS_BASE_API;

      const options = {
        method: "POST",
        url: `${API_URL}/sample-collected-result`,
        data: data,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await api(options);

      toastMessage("success", "Data submitted successfully");
    } catch (error) {
      toastMessage("error", error);
    }
  };

  const handleCloseModal = () => {
    setShowRequestInfoModal(false);
  };

  const handleShowModal = () => {
    setShowRequestInfoModal(true);
  };

  return (
    <div className='main-collection'>
      <div className='header'>
        <h2>Requested Sample (Nominative Request)</h2>
        <div>
          <Button
            text={"Proceed"}
            onPress={() => handleShowModal()}
            isSubmitting={false}
          />
        </div>
      </div>

      <div className='main-layout'>
        <div className='left-layout'>
          <div className='main-information'>
            {/* Patient Information */}
            <div className='patient-info'>
              <h3>Patient Information</h3>
              <div className='sample-req'>
                Patient Name:
                <span>
                  {`${requestedBloodRequest?.patient?.firstName} ${requestedBloodRequest?.patient?.lastName}`}
                </span>
              </div>
              <div className='sample-req'>
                National ID:{" "}
                <span>{requestedBloodRequest?.patient?.patientId}</span>
              </div>
            </div>

            {/* Request Information */}
            <div className='sample-requested'>
              <h3>Collected Information</h3>
              <div className='sample-req'>
                Request No: <span>{requestedBloodRequest?.requestNo}</span>
              </div>
              <div className='sample-req'>
                Sample Collected By:{" "}
                <span>{requestedProduct[0]?.sampleCollectedBy}</span>
              </div>
              <div className='sample-req'>
                Sample Collected Date:{" "}
                <span>{requestedProduct[0]?.sampleCollectedDate}</span>
              </div>
              <div className='sample-req'>
                History Pregnancy:{" "}
                <span>{requestedBloodRequest?.historyPregnancy}</span>
              </div>
              <div className='sample-req'>
                Urgent:{" "}
                <span>{requestedBloodRequest?.isUrgent?.toString()}</span>
              </div>
              <div className='sample-req'>
                Request Type: <span>{requestedBloodRequest?.requestType}</span>
              </div>
              <div className='sample-req'>
                Previously Transfused:{" "}
                <span>{requestedBloodRequest?.previouslyTransfused}</span>
              </div>
            </div>

            {/* Requested Products Table */}
            <div className='data-table'>
              <h3>Requested Products</h3>
              <table>
                <thead>
                  <tr>
                    <th>Product Type</th>
                    <th>Blood Group</th>
                    <th>Number of Packages</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {requestedProduct.map((item, index) => (
                    <tr key={index}>
                      <td>{item.productType}</td>
                      <td>{item.bloodGroup}</td>
                      <td>{item.numberOfPackages}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='right-layout'>
          <div className='main-information'>
            <h3>Add Results</h3>
            <Select
              options={convertLabTest(requestedProduct[0]?.allowedTests || [])}
              placeholder={"Choose Lab Test"}
              label={"Lab Test"}
              required
              value={selectedTest}
              onChange={(value) => setSelectedTest(value)}
            />
            {selectedTest.value?.length > 0 && (
              <>
                <Input
                  placeholder={"Enter the result"}
                  label={"Result"}
                  value={result}
                  onChange={(e) => handleChangeText(e)}
                  leftIcon={icons.request}
                />
                <div className='submit-button'>
                  <Button
                    text={"Submit"}
                    onPress={() => handleSubmit()}
                    isSubmitting={false}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ModalContainer
        title={"Request Information"}
        show={showRequestInfoModal}
        handleClose={() => handleCloseModal()}
        size='lg'
      >
        <ProvideBloodRequested
          requestedProduct={requestedProduct}
          requestedBloodRequest={requestedBloodRequest}
        />
      </ModalContainer>
    </div>
  );
}

export default SampleCollectionScreen;
