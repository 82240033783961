import "./App.css";
import "./app/common/styles/scrollbar.styles.css";
import "./app/common/styles/auth.form.styles.css";
import "./app/common/styles/media.queries/general.media.queries.css";
import "./app/common/styles/border.styles.css";
import "./app/common/styles/card.styles.css";
import "./app/common/styles/dropdown.styles.css";
import "./app/common/styles/icons.styles.css";
import "./app/common/styles/label.styles.css";
import "./app/common/styles/margin.styles.css";
import "./app/common/styles/modals.styles.css";
import "./app/common/styles/accrodion.styles.css";
import "./app/common/styles/text.styles.css";
import "./app/common/styles/printing.styles.css";
import "./app/common/styles/ul.styles.css";
import "./app/common/styles/badge.styles.css";
import "./app/common/styles/alerts.styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AdminRoutes, CommonRoutes } from "./app/routes";
import UserRoutes from "./app/routes/user.routes";
import { useEffect } from "react";
import { clearStorage, getStorage } from "./app/utils/storage";
import { jwtDecode } from "jwt-decode";
import { Toaster } from "react-hot-toast";
import InactivityTracker from "./app/components/InactivityTracker";

function App() {
  useEffect(() => {
    const checkAuth = async () => {
      const user = await getStorage();

      if (!user) return;
      if (!user.token) return;

      const decodedToken = jwtDecode(user.token);
      const currentTime = Date.now() / 1000;

      // Check if token is expired
      if (
        decodedToken &&
        decodedToken?.exp &&
        decodedToken?.exp < currentTime
      ) {
        clearStorage();
        window.location.href =
          user.accountType === "donor" ? "/login" : "/admin-login";
      }
    };

    checkAuth();
  }, []);
  return (
    <div className="App">
      <InactivityTracker />
      <CommonRoutes />
      <AdminRoutes />
      <UserRoutes />
      <Toaster />
    </div>
  );
}

export default App;
